<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                            <b-tr v-if="$store.state.Auth.activeRoleId === 1">
                              <b-th>{{ $t('globalTrans.organization') }}</b-th>
                              <b-td>{{ ($i18n.locale=='bn') ? trainingTitleData.org_bn : trainingTitleData.org_en }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('elearning_config.training_type') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? trainingTitleData.training_type_bn : trainingTitleData.training_type }}</b-td>
                                <b-th>{{ $t('elearning_config.training_category') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? trainingTitleData.training_category_bn : trainingTitleData.training_category }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('elearning_config.training_title_en') }}</b-th>
                                <b-td>{{ trainingTitleData.training_title_en }}</b-td>
                                <b-th>{{ $t('elearning_config.training_title_bn') }}</b-th>
                                <b-td>{{ trainingTitleData.training_title_bn }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('elearning_config.remarks_en') }}</b-th>
                                <b-td>{{ trainingTitleData.remarks }}</b-td>
                                <b-th>{{ $t('elearning_config.remarks_bn') }}</b-th>
                                <b-td>{{ trainingTitleData.remarks_bn }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('elearning_config.person_type') }}</b-th>
                                <b-td>
                                  <slot v-if="trainingTitleData.training_status === 1">
                                    <slot v-if="parseInt($store.state.Auth.authUser.org_id) === 3">
                                      {{ $t('globalTrans.officials_and_employees') }}
                                    </slot>
                                    <slot v-if="parseInt($store.state.Auth.authUser.org_id) === 4">
                                      {{ $t('globalTrans.scientists_officials_and_employees') }}
                                    </slot>
                                    <slot v-else>
                                      {{ $t('warehouseFarmer.officer') }}
                                    </slot>
                                  </slot>
                                  <slot v-else>{{ $t('globalTrans.other') }}</slot>
                                </b-td>
                                <b-th></b-th>
                                <b-td></b-td>
                            </b-tr>
                    </b-table-simple>
                    <b-overlay>
                    <b-overlay v-if="trainingTitleData.material_details.length > 1">
                      <template>
                        <div style="font-size:14px; background-color: #66CC99; padding:6px" class="mt-4">
                            <h5 class="text-white text-center"> {{ $t('elearning_config.training_mat') }}</h5>
                        </div>
                      </template>
                      <b-table-simple bordered>
                          <b-tr>
                              <b-th>{{ $t('globalTrans.name') }}</b-th>
                              <b-th>{{ $t('globalTrans.quantity') }}</b-th>
                          </b-tr>
                            <b-tr v-for="(info, index) in trainingTitleData.material_details" :key="index" class="mt-3">
                                <b-td>{{ ($i18n.locale=='bn') ? info.name_bn : info.name}}</b-td>
                                <b-td>{{ $n(info.quantity) }}</b-td>
                            </b-tr>
                      </b-table-simple>
                    </b-overlay>
                  </b-overlay>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.trainingTitleData = this.item
  },
  data () {
    return {
      trainingTitleData: [],
      slOffset: 1
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
