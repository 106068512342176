import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import ReportHeading from '@/Utils/report-head-lic-o'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      {
        columns: reportHeadData.reportHeadColumn
      },
      { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
      { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
      { text: reportHeadData.address, style: 'address', alignment: 'center' },
      { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
      // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      pdfContent.push({ text: '', style: 'fertilizer' })
      const search = vm.search
      const totalInfo = vm.totalInfo
      const allRowsHead = [
        [
          { text: vm.$t('user_role.organization') + ' : ' + (search.org_id ? vm.getOrganizationName(search.org_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('org_pro.service_namel') + ' : ' + (search.service_id ? vm.getServiceName(search.service_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
        ],
        [
          { text: vm.$t('globalTrans.from') + ' : ' + (search.year ? vm.$n(search.year, { useGrouping: false }) : vm.$t('globalTrans.all')) + ' ' + (search.month ? ',' + vm.getMonthName(search.month) : ''), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('globalTrans.to_projonto') + ' : ' + (search.year_to ? vm.$n(search.year_to, { useGrouping: false }) : vm.$t('globalTrans.all')) + ' ' + (search.month_to ? ',' + vm.getMonthName(search.month_to) : ''), alignment: 'left', style: 'th', bold: true }
        ],
        [
          { text: vm.$t('globalTrans.type') + ' : ' + (search.application_type ? vm.getApplicationTypeName(search.application_type) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('org_pro.service_namel') + ' : ' + (search.paid_type ? vm.getPaymentTypeName(search.paid_type) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
        ]
      ]
      pdfContent.push({
        table: {
          widths: ['50%', '50%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })
      pdfContent.push({ text: '', style: 'fertilizer' })
      const allRows = []
      let vatTax = ''
      if (search.org_id === 2) {
        vatTax = vm.$t('li_step.with_vat')
      }
      const tableHead = [
        { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('globalTrans.year'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('globalTrans.month'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('li_step.total_application'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('li_step.online_application'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('li_step.offline_application'), style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('li_step.online_payment') + ' ' + vatTax, style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('li_step.offline_payment') + ' ' + vatTax, style: 'th', alignment: 'center', bold: true },
        { text: vm.$t('globalTrans.total'), style: 'th', alignment: 'center', bold: true }
      ]
      allRows.push(tableHead)
      if (vm.listData.length) {
        vm.listData.forEach((yearItem, yearItemIndex) => {
          yearItem.month_info.forEach((monthItem, monthItemIndex) => {
            const rowItem = [
              {
                text: vm.$n(monthItem.serial_no, { useGrouping: false }), alignment: 'center', style: 'td'
              }
            ]
            if (monthItemIndex === 0) {
              rowItem.push({
                text: vm.$n(yearItem.year, { useGrouping: false }), alignment: 'center', style: 'td', rowSpan: yearItem.month_info.length
              })
            } else {
              rowItem.push({})
            }
            rowItem.push(
              {
                text: vm.getMonthName(monthItem.month), alignment: 'center', style: 'td'
              },
              {
                text: vm.$n(monthItem.total_application), alignment: 'right', style: 'td'
              },
              {
                text: vm.$n(monthItem.online_application), alignment: 'right', style: 'td'
              },
              {
                text: vm.$n(monthItem.offline_application), alignment: 'right', style: 'td'
              },
              {
                text: vm.$n(monthItem.online_payment), alignment: 'right', style: 'td'
              },
              {
                text: vm.$n(monthItem.offline_payment), alignment: 'right', style: 'td'
              },
              {
                text: vm.$n(monthItem.total_payment), alignment: 'right', style: 'td'
              }
            )
            allRows.push(rowItem)
          })
        })
        const tableFooter = [
          { text: vm.$t('globalTrans.total'), style: 'th', alignment: 'right', bold: true, colSpan: 3 },
          {},
          {},
          { text: vm.$n(totalInfo.total_application), style: 'th', alignment: 'right', bold: true },
          { text: vm.$n(totalInfo.total_online_application), style: 'th', alignment: 'right', bold: true },
          { text: vm.$n(totalInfo.total_offline_application), style: 'th', alignment: 'right', bold: true },
          { text: vm.$n(totalInfo.total_online_payment), style: 'th', alignment: 'right', bold: true },
          { text: vm.$n(totalInfo.total_offline_payment), style: 'th', alignment: 'right', bold: true },
          { text: vm.$n(totalInfo.total_payment), style: 'th', alignment: 'right', bold: true }
        ]
        allRows.push(tableFooter)
      } else {
        allRows.push([
          { text: vm.$t('globalTrans.noDataFound'), style: 'th', alignment: 'center', bold: true, colSpan: 9 },
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {}
        ])
      }
      pdfContent.push({
        table: {
          headerRows: 1,
          // widths: ['6%', '14%', '14%', '13%', '12%', '12%', '9%', '10%', '10%'],
          widths: '*',
          body: allRows
        }
      })
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 10 : 9,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 10 : 9,
          margin: [3, 3, 3, 3]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 12,
          margin: [0, 0, 0, 4]
        },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 0]
        },
        org: {
          fontSize: 13,
          bold: true,
          margin: [0, -25, 10, 5]
        },
        hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
        },
        address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
