import Store from '@/store'

export const getOrgByUserPriviledge = () => {
  const authUser = Store.state.Auth.authUser
  const activeRoleId = Store.state.Auth.activeRoleId
  if (authUser === null) {
    return 0
  }
  if (activeRoleId === 1 || authUser.org_id === 1) {
    return 0
  }
  if (authUser.is_org_admin === 1) {
    return authUser.org_id
  }
  return authUser.org_id
}
export const getOfficeByUserPriviledge = () => {
  const authUser = Store.state.Auth.authUser
  const activeRoleId = Store.state.Auth.activeRoleId
  const officeObj = {
    officeId: 0,
    officeTypeId: 0
  }
  if (authUser === null) {
    return officeObj
  }
  if (activeRoleId === 1 || authUser.org_id === 1) {
    return officeObj
  }
  if (authUser.is_org_admin === 1) {
    return officeObj
  }
  officeObj.officeId = authUser.office_id
  officeObj.officeTypeId = authUser.office_type_id
  return officeObj
}
export const orgFilteredList = (orgCompList, componentId) => {
  const authUser = Store.state.Auth.authUser
  const activeRoleId = Store.state.Auth.activeRoleId
  if (activeRoleId === 1 || authUser.org_id === 1) {
    return orgCompList.filter(item => item.component_id === componentId)
  }
  if (componentId === 10 && authUser.org_id === 12) {
    return orgCompList.filter(item => item.component_id === componentId)
  }
  if (componentId === 10 && authUser.org_id === 4) {
    return orgCompList.filter(item => item.component_id === componentId)
  }
  return orgCompList.filter(item => (item.component_id === componentId) && (item.org_id === authUser.org_id))
}
export const setFilters = () => {
  const authUser = Store.state.Auth.authUser
  const activeRoleId = Store.state.Auth.activeRoleId
  const filters = {
    hasFilterOrg: 0,
    hasFilterOffice: 0,
    hasFilterCreatedBy: 0
  }
  if (authUser === null) {
    return filters
  }
  if (activeRoleId === 1 || authUser.org_id === 1) {
    return filters
  }
  if (authUser.is_org_admin === 1) {
    filters.hasFilterOrg = authUser.org_id
    return filters
  }
  filters.hasFilterOffice = authUser.office_id
  filters.hasFilterCreatedBy = authUser.user_id
  return filters
}
export const getCottonMarketUserInfo = () => {
  const authUser = Store.state.Auth.authUser
  const activeRoleId = Store.state.Auth.activeRoleId
  const unitAssignList = Store.state.agriMarketing.commonObj.unitAssignList
  let regionList = Store.state.agriMarketing.commonObj.regionList
  let zoneList = Store.state.agriMarketing.commonObj.zoneList
  let unitList = Store.state.agriMarketing.commonObj.unitList
  let districtList = Store.state.commonObj.districtList
  let upazilaList = Store.state.commonObj.upazilaList
  if (Store.state.commonObj.officeTypeList.length && unitAssignList !== undefined) {
    const userOfficeTypeObj = Store.state.commonObj.officeTypeList.find(item => item.value === parseInt(authUser.office_type_id))
    if (!(activeRoleId === 1 || authUser.is_org_admin || userOfficeTypeObj.text_en.toLowerCase() === 'head office')) {
      const unitAssignListItem = unitAssignList.find(item => item.user_id === authUser.user_id)
      if (unitAssignListItem !== undefined) {
        const regionIdArr = unitAssignListItem.region_ids ? JSON.parse(unitAssignListItem.region_ids) : []
        regionList = regionList.filter(item => regionIdArr.includes(parseInt(item.value)))
        const zoneIdArr = unitAssignListItem.zone_ids ? JSON.parse(unitAssignListItem.zone_ids) : []
        if (zoneIdArr.length) {
          zoneList = zoneList.filter(item => zoneIdArr.includes(parseInt(item.value)))
        } else {
          zoneList = zoneList.filter(item => regionIdArr.includes(parseInt(item.region_id)))
        }
        const districtIdArr = unitAssignListItem.district_ids ? JSON.parse(unitAssignListItem.district_ids) : []
        if (!districtIdArr.length) {
          zoneList.forEach(item => {
            const idArr = JSON.parse(item.district_ids)
            districtIdArr.push(...idArr)
          })
        }
        districtList = districtList.filter(item => districtIdArr.includes(parseInt(item.value)))
        const unitIdArr = unitAssignListItem.unit_ids ? JSON.parse(unitAssignListItem.unit_ids) : []
        if (unitIdArr.length) {
          unitList = unitList.filter(item => unitIdArr.includes(parseInt(item.value)))
          const upazilaIdArr = unitList.map(item => parseInt(item.upazilla_id))
          upazilaList = upazilaList.filter(item => upazilaIdArr.includes(parseInt(item.value)))
        } else {
          unitList = unitList.filter(item => regionIdArr.includes(parseInt(item.region_id)))
          upazilaList = upazilaList.filter(item => districtIdArr.includes(parseInt(item.district_id)))
        }
      } else {
        regionList = []
        zoneList = []
        unitList = []
        districtList = []
        upazilaList = []
      }
    }
  }
  const userData = {
    regionList: regionList,
    zoneList: zoneList,
    unitList: unitList,
    districtList: districtList,
    upazilaList: upazilaList
  }
  return userData
}
export const getUnitAssignedParams = () => {
  const authUser = Store.state.Auth.authUser
  const activeRoleId = Store.state.Auth.activeRoleId
  const userOfficeTypeObj = Store.state.commonObj.officeTypeList.find(item => item.value === parseInt(authUser.office_type_id))
  const params = {}
  if (!(activeRoleId === 1 || authUser.is_org_admin || (userOfficeTypeObj !== undefined && userOfficeTypeObj.text_en.toLowerCase() === 'head office'))) {
    const unitAssignListItem = Store.state.agriMarketing.commonObj.unitAssignList.find(item => item.user_id === authUser.user_id)
    params.region_ids = unitAssignListItem !== undefined ? unitAssignListItem.region_ids : JSON.stringify([])
    params.zone_ids = unitAssignListItem !== undefined ? unitAssignListItem.zone_ids : JSON.stringify([])
    params.district_ids = unitAssignListItem !== undefined ? unitAssignListItem.district_ids : JSON.stringify([])
    params.unit_ids = unitAssignListItem !== undefined ? unitAssignListItem.unit_ids : JSON.stringify([])
  }
  return params
}
