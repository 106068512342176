<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('elearning_tim.training_certificate')}}</h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="certificate-list" :class="'mr-2 btn btn-success text-light'">{{ $t('elearning_tim.training_certificate') }} {{ $t('globalTrans.list') }}</router-link>
            <b-button type="button" variant="primary" class="mr-2" @click="pdfDownloadExport">{{ $t('globalTrans.download') }}</b-button>
            <b-button type="button" variant="primary" class="mr-2" @click="pdfExport">{{ $t('globalTrans.print') }}</b-button>
            <!-- <a href="javascript:" class="btn-add" @click="pdfExport">
              {{ $t('globalTrans.print') }}
            </a> -->
          </template>
          <template v-slot:body>
            <div style="">
              <b-col lg="12" sm="12" >
                  <div class="tail-wrapper landscapee" id="printMe1" style="margin: auto;position: relative; height: 605px !important; width:960px">
                    <img src="../../../../../assets/images/nata-certificate-ban.jpg" alt="" class="" style="position: absolute;width:100%">
                      <b-col lg="12" sm="12">
                          <div class="removeborder">
                              <table class="" width="100%">
                                <tr>
                                  <!-- <th style="font-size: 18px;font-weight: 100;text-align: right;padding-left: 50px;padding-right: 50px;padding-top: 230px;margin-top: 0px;">{{ $t('elearning_tim.sl') + ': ' + certificate_no }}</th> -->
                                  <th style="font-size: 18px;font-weight: 100;text-align: right;padding-left: 50px;padding-right: 50px;padding-top: 230px;margin-top: 0px;">
                                    <div style="height: 25px;"></div>
                                  </th>
                                </tr>
                                  <tr class="removeborder middletext" style="text-align:center; line-height: 1.5;">
                                    <th colspan="3" class="movetop removeborder text-center" style="font-size: 16px;font-weight: 100;text-align: left;padding-left: 20px;padding-right: 20px;padding-top: 10px;padding-bottom: 25px!important;margin-top: 0px;"><b style="font-weight: 600;font-size: 26px;text-transform: capitalize;">{{$i18n.locale === 'bn' ? name_bn : name}}</b><br>
                                    <p style="font-size: 16px;">{{designation ? ($i18n.locale === 'bn' ? designation_bn : designation) + $t('elearning_tim.of') : ''}} {{ work_space }}<slot v-if="$i18n.locale === 'en'"><br></slot>
                                      {{$t('elearning_tim.main_one')}}<br>
                                      <slot v-if="$i18n.locale === 'en'">{{$t('elearning_tim.main_five')}}</slot> <b style="font-weight: 600;font-style: italic;font-weight: 600;font-style: italic;font-size: 19px;"><span class="italicfont">“{{$i18n.locale === 'bn' ? trainingTitle_bn : trainingTitle}}”</span></b> <slot v-if="$i18n.locale === 'bn'">{{$t('elearning_tim.main_five')}}</slot><br> {{$t('elearning_tim.held_from')}}
                                        {{ $i18n.locale === 'en' ? date : date_bn }}<slot v-if="$i18n.locale === 'en'">.</slot><slot v-if="$i18n.locale === 'bn'"> খ্রি. সময়ে সফলভাবে সম্পন্ন করেন ।</slot>
                                    </p></th>
                                  </tr>
                                  <tr class="removeborder" v-if="signatureData">
                                    <td align="center" colspan="3" class="padding-bottom-signature">
                                      <div style="float:left;" :style="signaturePercentage(signatureData)" v-for="(detail, index) in signatureData" :key="index">
                                        <table style="width:100%">
                                          <tr class="removeborder">
                                            <td class="removeborder" style="text-align:center!important;padding-bottom: 0px!important; padding-left: 30px;"><span class="removeborder"><img  :src="trainingElearningServiceBaseUrl+'storage/uploads/' + detail.signature" class="img-fluid mb-2" alt="signature" style="padding-bottom: 0px!important;;height:45px!important"></span></td>
                                          </tr>
                                          <tr>
                                            <td class="removeborder" style="text-align:center!important;padding-top: 0px!important;padding-bottom: 0px!important; padding-left: 30px;"><span class="removeborder" style=""><b style="font-weight: 600;font-size: 16px;text-transform: capitalize;">{{ $i18n.locale === 'bn' ? detail.name_bn : detail.name }}</b></span></td>
                                          </tr>
                                          <tr>
                                            <td class="removeborder" style="text-align:center!important;padding-top: 0px!important; padding-left: 30px;"><span class="removeborder" style="">{{ $i18n.locale === 'bn' ? detail.designation_bn : detail.designation_en }}</span></td>
                                          </tr>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                              </table>
                          </div>
                      </b-col>
                  </div>
              </b-col>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import VueHtmlToPaper from 'vue-html-to-paper'
import Vue from 'vue'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { certificateDetails, certificateIndividualShow, certificateSignatureView } from '../../api/routes'
const options = {
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=no'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    `${trainingElearningServiceBaseUrl}/printBn.css`
  ],
  autoClose: true
}
Vue.use(VueHtmlToPaper, options)
export default {
    components: {
    },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        name_bn: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      date: '',
      date_bn: '',
      certificate_no: '',
      // loading: false,
      signatureData: [],
      recive: [],
      orglistReport: [],
      cirtificateViewData: '',
      logo: '',
      backlogo: '',
      org_name_data: '',
      org_name_data_bn: '',
      org_name: '',
      org_name_bn: '',
      work_space: '',
      orgaddress: '',
      orgaddress_bn: '',
      office: '',
      office_bn: '',
      org: '',
      org_bn: '',
      name: '',
      designation: '',
      designation_bn: '',
      trainingTitle: '',
      trainingTitle_bn: '',
      load: false,
      officeTypeList: [],
      honorariumSheet: [],
      materials: [],
      items: [],
      data: [],
      certificate: null,
      certificateData: [],
      certificateMain: [],
      dataList: [],
      trainees: [],
      trainers: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      releaseDescription: [],
      releasedate: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
    created () {
      this.getSignatureData()
      const objData = {
        id: this.$route.query.id
      }
      RestApi.getData(trainingElearningServiceBaseUrl, certificateIndividualShow, objData).then(response => {
          if (response.success) {
            this.cirtificateViewData = response.data
            const startDate = response.data.training_start_date.split('-')
            const endDate = response.data.training_end_date.split('-')
            let startMonthId = ''
            let endMonthId = ''
            startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
            endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
            if (parseInt(startDate[0]) === parseInt(endDate[0])) {
              this.date = startDate[2] + ' ' + startMonthId.text_en + ' ' + 'to' + ' ' + endDate[2] + ' ' + endMonthId.text_en + ' ' + startDate[0]
              this.date_bn = this.convertToBanglaNumbers(startDate[2]) + ' ' + startMonthId.text_bn + ' ' + 'থেকে' + ' ' + this.convertToBanglaNumbers(endDate[2]) + ' ' + endMonthId.text_bn + ' ' + this.convertToBanglaNumbers(startDate[0], { useGrouping: false })
            } else {
              this.date = startDate[2] + ' ' + startMonthId.text_en + ' ' + startDate[0] + ' to' + ' ' + endDate[2] + ' ' + endMonthId.text_en + ' ' + endDate[0]
              this.date_bn = this.convertToBanglaNumbers(startDate[2]) + ' ' + startMonthId.text_bn + ' ' + this.convertToBanglaNumbers(startDate[0], { useGrouping: false }) + ' থেকে' + ' ' + this.convertToBanglaNumbers(endDate[2]) + ' ' + endMonthId.text_bn + ' ' + this.convertToBanglaNumbers(endDate[0], { useGrouping: false })
            }
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(response.data.org_id))
            if (typeof orgObj !== 'undefined') {
              this.org_name_data = orgObj.text_en
              this.org_name_data_bn = orgObj.text_bn
            } else {
              this.org_name_data = ''
              this.org_name_data_bn = ''
            }
            if (parseInt(response.data.profession_type) === 1) {
              const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(response.data.orgid))
              if (typeof orgObj !== 'undefined') {
                this.org_name = orgObj.text_en
                this.org_name_bn = orgObj.text_bn
              } else {
                this.org_name = ''
                this.org_name_bn = ''
              }
            } else {
              this.org_name = response.data.other_office_name
              this.org_name_bn = response.data.other_office_name_bn
            }
            if (parseInt(response.data.profession_type) === 1 && response.data.not_here_office === 0) {
              this.work_space = this.getOfficeName(response.data.officeId) + ', ' + this.getOrgName(response.data.orgid)
            } else if (parseInt(response.data.profession_type) === 1 && response.data.not_here_office === 1) {
              this.work_space = ((this.$i18n.locale === 'bn' ? response.data.other_office_name_bn : response.data.other_office_name))
            } else {
              this.work_space = this.$i18n.locale === 'bn' ? response.data.other_office_name_bn : response.data.other_office_name
            }
            const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(response.data.officeId))
            if (typeof officeObj !== 'undefined') {
              this.office = officeObj.text_en
              this.office_bn = officeObj.text_bn
            } else {
              this.office = ''
              this.office_bn = ''
            }
            if (response.data.designation_id && parseInt(response.data.profession_type) === 1) {
              const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === response.data.designation_id)
              if (typeof desigObj !== 'undefined') {
                this.designation = desigObj.text_en
                this.designation_bn = desigObj.text_bn
              } else {
                this.designation = ''
                this.designation_bn = ''
              }
            } else {
              this.designation = response.data.designation_en
              this.designation_bn = response.data.designation_bn
            }
            if (response.data.training_title_en) {
              this.trainingTitle = response.data.training_title_en
            } else {
              this.trainingTitle = ''
            }
            if (response.data.training_title_bn) {
              this.trainingTitle_bn = response.data.training_title_bn
            } else {
              this.trainingTitle_bn = ''
            }
            if (response.data.certificate_no) {
              this.certificate_no = response.data.certificate_no
            } else {
              this.certificate_no = ''
            }
            if (response.data.name) {
              this.name = response.data.name
            } else {
              this.name = ''
            }
            if (response.data.name_bn) {
              this.name_bn = response.data.name_bn
            } else {
              this.name_bn = ''
            }
            const headingObj = this.$store.state.TrainingElearning.commonObj.trainingReportHeadList.find(doc => doc.org_id === parseInt(response.data.org_id))
            if (typeof headingObj !== 'undefined') {
              this.org = headingObj.project_name
              this.orgaddress = headingObj.project_name
              this.logo = headingObj.right_logo
              this.backlogo = headingObj.background_logo
              this.org_bn = headingObj.project_name_bn
              this.orgaddress_bn = headingObj.project_name_bn
              this.logo = headingObj.right_logo
              this.backlogo = headingObj.background_logo
            } else {
              this.org = ''
              this.org_bn = ''
              this.orgaddress = ''
              this.orgaddress_bn = ''
              this.logo = ''
              this.backlogo = ''
            }
          }
      })
      this.getGenerateNo(this.$route.query.circular_memo_no)
      if (this.$route.query.circilarMemoNo) {
          this.getCircularMemoNo(this.$route.query.circilarMemoNo)
      }
    },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  watch: {
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    getOrgName (id) {
      const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getOfficeName (id) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    convertToBanglaNumbers (input) {
      function toBanglaNumber (match) {
        const numberMap = {
          0: '০',
          1: '১',
          2: '২',
          3: '৩',
          4: '৪',
          5: '৫',
          6: '৬',
          7: '৭',
          8: '৮',
          9: '৯'
        }
        return match.split('').map(char => numberMap[char] || char).join('')
      }
      return input.replace(/[0-9]/g, toBanglaNumber)
    },
    banglaEngReturn () {
      if (this.$i18n.locale === 'bn') {
        return 'top: 54%'
      } else {
        return 'top: 50%;'
      }
    },
    signaturePercentage (signature) {
      const lengthData = signature.length
      let percntage = '100%'
      if (parseInt(lengthData) === 2) {
        percntage = '50%'
      } else if (parseInt(lengthData) === 3) {
        percntage = '33.33%;'
      }
      return 'width:' + percntage
    },
    paddingPercentage () {
      if (this.$i18n.locale === 'bn') {
        return 'padding-top: 70px;'
      } else {
        return 'padding-top: 55px;'
      }
    },
    async getSignatureData () {
      this.loading = true
        const circularMemoNo = {
            circular_memo_no: this.$route.query.circular_memo_no
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, certificateSignatureView, circularMemoNo)
        if (!result.success) {
          this.signatureData = []
          this.loading = false
        } else {
          this.signatureData = result.data
          this.loading = false
        }
    },
    async getGenerateNo (circularMemoNo) {
      if (circularMemoNo) {
        const circularMemoNoData = {
          circular_memo_no: circularMemoNo
        }
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'tim/certificate/show-details', circularMemoNoData)
        if (!result.success) {
          this.certificateMain = []
        } else {
            this.certificateMain = result.data
        }
        this.load = false
      }
    },
    async getCircularMemoNo (circularMemoNo) {
      if (circularMemoNo) {
          const circularMemoNo = {
            circular_memo_no: this.$route.query.circilarMemoNo
          }
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, certificateDetails, circularMemoNo)
        if (!result.success) {
          this.honorariumSheet = []
        } else {
            this.honorariumSheet = result.data[0]
            this.releaseDescription = result.dataMain
            this.releasedate = result.datapublication
            this.getCustomDataZeroList(result.data[0])
            this.dataList = this.getCustomDataList(result.data)
            // const trainees = result.data.filter(trainee => trainee.payment_type === 'Trainee')
            // this.trainees = this.getCustomDataList(trainees)
            // this.materials = result.matherial
        }
        this.load = false
      }
    },
    async pdfExport () {
      const sendData = {
        date: this.date,
        date_bn: this.date_bn,
        designation: this.designation,
        designation_bn: this.designation_bn,
        org_name: this.org_name,
        org_name_bn: this.org_name_bn,
        org_name_data: this.org_name_data,
        org_name_data_bn: this.org_name_data_bn,
        orgaddress: this.orgaddress,
        orgaddress_bn: this.orgaddress_bn,
        work_space: this.work_space,
        backlogo: this.backlogo,
        logo: this.logo
      }
      const params = Object.assign({ request_type: 'pdf', local: 'en', org_id: this.orgId, id: this.$route.query.id }, sendData)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, certificateIndividualShow, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async pdfDownloadExport () {
      const sendData = {
        date: this.date,
        date_bn: this.date_bn,
        designation: this.designation,
        designation_bn: this.designation_bn,
        org_name: this.org_name,
        org_name_bn: this.org_name_bn,
        org_name_data: this.org_name_data,
        org_name_data_bn: this.org_name_data_bn,
        orgaddress: this.orgaddress,
        orgaddress_bn: this.orgaddress_bn,
        work_space: this.work_space,
        backlogo: this.backlogo,
        logo: this.logo
      }

      const params = Object.assign({ request_type: 'pdf', local: 'en', org_id: this.orgId, id: this.$route.query.id }, sendData)

      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      try {
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, certificateIndividualShow, params)

        // Create a Blob and object URL
        var blob = new Blob([result], {
          type: 'application/pdf'
        })

        var url = window.URL.createObjectURL(blob)

        // Create a temporary link element to trigger the download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'your_pdf_filename.pdf' // Set the desired filename

        // Trigger the download and cleanup
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        // Handle any errors here
      } finally {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
    getCustomDataList (data) {
        const listData = data.map(item => {
            // const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.org_id))
            // const orgData = {}
            // if (typeof orgObj !== 'undefined') {
            //   orgData.org_name = orgObj.text_en
            //   orgData.org_name_bn = orgObj.text_bn
            // } else {
            //   orgData.org_name = ''
            //   orgData.org_name_bn = ''
            // }
            const officeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office_name = officeObj.text_en
              officeData.office_name_bn = officeObj.text_bn
            } else {
              officeData.office_name = ''
              officeData.office_name_bn = ''
            }
          return Object.assign({}, item, officeData)
        })
        return listData
    },
    printID () {
            this.$htmlToPaper('printMe1')
    },
    getCustomDataZeroList (item) {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            this.honorariumSheet.org = orgObj.text_en
            this.honorariumSheet.org_bn = orgObj.text_bn
          } else {
            this.honorariumSheet.org = ''
            this.honorariumSheet.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            this.honorariumSheet.office = officeObj.text_en
            this.honorariumSheet.office_bn = officeObj.text_bn
          } else {
            this.honorariumSheet.office = ''
            this.honorariumSheet.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            this.honorariumSheet.fiscal_year = fiscalYearObj.text_en
            this.honorariumSheet.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.honorariumSheet.fiscal_year = ''
            this.honorariumSheet.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            this.honorariumSheet.training_title = trainingTitleObj.text_en
            this.honorariumSheet.training_title_bn = trainingTitleObj.text_bn
          } else {
            this.honorariumSheet.training_title = ''
            this.honorariumSheet.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            this.honorariumSheet.office_type = OfficeTypeListObj.text_en
            this.honorariumSheet.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            this.honorariumSheet.office_type = ''
            this.honorariumSheet.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            this.honorariumSheet.training_type = trainingTypeObj.text_en
            this.honorariumSheet.training_type_bn = trainingTypeObj.text_bn
          } else {
            this.honorariumSheet.training_type = ''
            this.honorariumSheet.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            this.honorariumSheet.training_category = trainingCategoryObj.text_en
            this.honorariumSheet.training_category_bn = trainingCategoryObj.text_bn
          } else {
            this.honorariumSheet.training_category = ''
            this.honorariumSheet.training_category_bn = ''
          }
      }
  }
}
</script>
<style>
  @import url(//db.onlinewebfonts.com/c/46aebfd06fd610b5813d2d877dc23398?family=Tilda+Script);
  @import url(//db.onlinewebfonts.com/c/09400bc4b89c1605f1ccd16c0c305a8c?family=Lateef);
  @import url(//db.onlinewebfonts.com/c/6d36b202b654e70a446767b843911eea?family=Sexything);
  .hidden_header {
    display: none
  }
  .card-border {
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
  .table {
    border-collapse: collapse;
    background-color: white;
    width: 100%;
  }
  .tabletwo {
    border-collapse: collapse;
    width: 100%;
  }
  td, th {
    font-family: arial, sans-serif;
    text-align: left;
    padding: 8px;
  }
  .certi {
    font-family: 'Tilda Script'!important;
    font-size: 38px;
    font-weight: 100;
    color: #823E97;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .thisis {
    font-family: 'Lateef'!important;
    letter-spacing: 1px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .italicfont {
    font-family: 'Sexy thing'!important;
  }
  .marginforleft {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .removeborder {
      padding-top: 0px;
      padding-bottom: 0px;
  }
  .padding-bottom-signature {
    padding-top: 0px !important;
  }
</style>
