<template>
    <b-container fluid>
        <b-overlay :show="unitLoad">
            <div>
              <!-- <pre>{{ datas }}</pre> -->
                <!-- <template v-if="attachmentShow"> -->
                    <b-row v-if="datas">
                        <b-col md="12" class="table-responsive" v-if="isLoad">
                            <b-overlay :show="loading">
                                <table class="table" border="1">
                                    <tr>
                                        <th style="width: 30%;" class="text-center">{{ $t('globalTrans.name') }}</th>
                                        <th style="width: 10%">{{ $t('globalTrans.attachment') }}</th>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_1') }} </td>
                                        <td>
                                              <a target="_blank" v-if="getFile(badcStaticData[1].image_73) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].image_73)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_2') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].rent_agree_5997) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].rent_agree_5997)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_3') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].nid_79) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].nid_79)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_4') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].updated_tr_5857) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].updated_tr_5857)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_5') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].tin_certif_1997) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].tin_certif_1997)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_6') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].vat_regist_7591) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].vat_regist_7591)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_7') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].updated_in_80) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].updated_in_80)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_8') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].updated_ir_8033) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].updated_ir_8033)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_9') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].updated_bf_3787) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].updated_bf_3787)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_10') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].uptodate_b_2747) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].uptodate_b_2747)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_11') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].undertakin_8030) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].undertakin_8030)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_12') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].address_an_7107) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].address_an_7107)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_13') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].authorizat_7806) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].authorizat_7806)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_14') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].paclobutra_7492) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].paclobutra_7492)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_15') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].approved_s_9860) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].approved_s_9860)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_19') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1]['packet_prepared_in_light_of_approved_sample_label_(renewal)']) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1]['packet_prepared_in_light_of_approved_sample_label_(renewal)'])"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_16') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1]._year_perf_2280) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1]._year_perf_2280)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_17') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].old_regist_6668) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].old_regist_6668)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('li_step.dae_18') }} </td>
                                        <td>
                                            <a target="_blank" v-if="getFile(badcStaticData[1].others_9133) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].others_9133)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                        </td>
                                    </tr>
                                </table>
                            </b-overlay>
                        </b-col>
                    </b-row>
                <!-- </template> -->
            </div>
        </b-overlay>
    </b-container>
</template>

<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
// import Receipt from '@/modules/license-registration-service/license-step/pages/steps/Receipt'
// import ReceiptOffline from '@/modules/license-registration-service/license-step/pages/steps/ReceiptOffline'

export default {
  props: ['tabData', 'badcStaticData', 'history', 'users', 'stepData', 'pDatas', 'officeData'],
//   components: {
//     Receipt, ReceiptOffline
//   },
  data () {
    return {
        unitLoad: false,
        isLoad: false,
        datas: {},
        generalInfoShow: true,
        attachmentShow: false,
        labelDesignShow: false,
        reportLoading: false,
        paymentRecipt: false,
        reportList: [],
        lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
        importData: [
            { id: 1, file: 'image_73', yes_no: 1, remarks: null },
            { id: 2, file: 'rent_agree_5997', yes_no: 1, remarks: null },
            { id: 3, file: 'nid_79', yes_no: 0, remarks: null },
            { id: 4, file: 'updated_tr_5857', yes_no: 0, remarks: null },
            { id: 5, file: 'tin_certif_1997', yes_no: 0, remarks: null },
            { id: 6, file: 'vat_regist_7591', yes_no: 0, remarks: null },
            { id: 7, file: 'updated_in_80', yes_no: 0, remarks: null },
            { id: 8, file: 'updated_ir_8033', yes_no: 0, remarks: null },
            { id: 9, file: 'updated_bf_3787', yes_no: 0, remarks: null },
            { id: 10, file: 'uptodate_b_2747', yes_no: 0, remarks: null },
            { id: 11, file: 'undertakin_8030', yes_no: 0, remarks: null },
            { id: 12, file: 'address_an_7107', yes_no: 0, remarks: null },
            { id: 13, file: 'authorizat_7806', yes_no: 0, remarks: null },
            { id: 14, file: 'paclobutra_7492', yes_no: 0, remarks: null },
            { id: 15, file: 'approved_s_9860', yes_no: 0, remarks: null },
            { id: 16, file: '_year_perf_2280', yes_no: 0, remarks: null },
            { id: 17, file: 'old_regist_6668', yes_no: 0, remarks: null }
        ],
        daeList: []
    }
  },
  created () {
    this.daeList = this.import
    this.loadData()
  },
  computed: {
    local: function () {
        return this.$i18n.locale
    },
    ingridentList: function () {
      return this.$store.state.licenseRegistration.commonObj.activeIngredientList.filter(item => item.status === 1)
    },
    labList: function () {
       return this.$store.state.licenseRegistration.commonObj.labList.filter(item => item.status === 1)
    }
  },
  methods: {
    getCountryManufactureName (id) {
        const obj = this.$store.state.licenseRegistration.commonObj.countryList.find(el => el.value === parseInt(id))
        if (typeof obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
    },
    getCompanyName (id) {
        const obj = this.$store.state.licenseRegistration.commonObj.foreignEnlishmentList.find(el => el.value === parseInt(id))
        if (typeof obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
    },
    checkFileFunction (field) {
      return field
    },
    getFile (value) {
      if (value === '') {
         return false
      }
      if (value) {
        const fileData = JSON.parse(value)
        if (fileData[0]) {
          return fileData[0]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    loadData () {
        const search = {
            service_id: this.badcStaticData[0].service_id,
            application_id: this.badcStaticData[0].application_id
        }
        const params = Object.assign({}, search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(licenseRegistrationServiceBaseUrl, '/step/dae-attachment/search', params).then(response => {
            if (response.success) {
                this.datas = response.data
                this.isLoad = true
            } else {
                this.isLoad = true
            }
        })
    },
    tabChanged () {
        this.attachmentShow = false
        this.labelDesignShow = false
        this.generalInfoShow = false
    },
    tabChange (val) {
        if (val === 0) {
            this.attachmentShow = false
            this.labelDesignShow = false
            this.generalInfoShow = true
        }
        if (val === 1) {
            this.labelDesignShow = false
            this.generalInfoShow = false
            this.attachmentShow = true
        }
        if (val === 2) {
            this.generalInfoShow = false
            this.attachmentShow = false
            this.labelDesignShow = true
        }
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    async getreportData () {
        this.generalInfoShow = false
        this.attachmentShow = false
        this.labelDesignShow = false
        this.reportLoading = true
        const applicationID = parseInt(this.badcStaticData[0].application_id)
        await RestApi.getData(licenseRegistrationServiceBaseUrl, `${'/configuration/dae-lab-assign/lab-assign'}/${applicationID}`).then(response => {
            if (response.success) {
                if (response.data.length > 0) {
                    this.reportList = response.data[0].lab_details
                }
            }
            this.reportLoading = false
        })
    },
    getLabName (labId) {
      const lab = this.labList.find(item => item.value === labId)
      if (this.$i18n.locale === 'en') {
        return lab.text_en
      } else {
        return lab.text_bn
      }
    },
    getIngredientName (ingredientId) {
      const ingredient = this.ingridentList.find(item => item.value === ingredientId)
      if (this.$i18n.locale === 'en') {
        return ingredient.text_en
      } else {
        return ingredient.text_bn
      }
    },
    getUserName (userId) {
      const user = this.users.find(user => user.value === userId)
      return user !== undefined ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
    },
    async printLicense () {
      const params = {
            id: 9,
            service_id: parseInt(this.badcStaticData[0].service_id),
            step_id: parseInt(this.stepData.step_id),
            applicationID: parseInt(this.badcStaticData[0].application_id),
            org_id: parseInt(this.badcStaticData[0].org_id),
            type: 'pdf',
            local: this.$i18n.locale
        }
      this.unitLoad = true

      try {
        const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, '/step/step-from-data', params)
        var blob = new Blob([result], { type: 'application/pdf' })
        var url = window.URL.createObjectURL(blob)

        // Create an anchor element for direct download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'importer-license.pdf' // Set the desired filename for the download

        // Trigger a click event on the anchor element to initiate the download
        document.body.appendChild(a)
        a.click()

        // Clean up the anchor element and URL object
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)

        this.unitLoad = false
      } catch (error) {
        this.unitLoad = false
      }
    }
  }
}

</script>
