<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_config.training_title')}}</h4>
            </template>
            <template v-slot:body>
              <b-row>
              <b-col v-if="$store.state.Auth.activeRoleId === 1" lg="6" md="6" sm="12" xs="12">
                <!-- <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('globalTrans.organization')"
                  label-for="training_type_id"
                >
                <b-form-select
                    plain
                    v-model="search.org_id"
                    :options="organizationtList"
                    id="org_id"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group> -->
                <b-form-group
                  class="row"
                  label-cols-sm="5"
                  label-for="org_id"
                  >
                  <template v-slot:label>
                    {{ $t('globalTrans.organization')}}
                  </template>
                  <v-select name="org_id"
                    v-model="search.org_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= organizationtList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
              </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="training_type_id"
                  >
                  <template v-slot:label>
                    {{ $t('elearning_config.training_type')}}
                  </template>
                  <v-select name="training_type_id"
                    v-model="search.training_type_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= trainingTypeList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="training_category_id"
                  >
                  <template v-slot:label>
                    {{ $t('elearning_config.training_category')}}
                  </template>
                  <v-select name="training_category_id"
                    v-model="search.training_category_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= trainingCategoryList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
                  </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <!-- <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('elearning_config.training_title')"
                    label-for="email"
                >
                <b-form-select
                    plain
                    v-model="search.training_title_id"
                    :options="trainingTitleList"
                    id="training_title_id"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group> -->
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="training_title_id"
                  >
                  <template v-slot:label>
                    {{ $t('elearning_config.training_title')}}
                  </template>
                  <v-select name="training_title_id"
                    v-model="search.training_title_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= trainingTitleList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                    <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_config.training_title_list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(crop_name)="data">
                                        <span class="capitalize">{{ data.item.crop_name }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.training_status === 1">
                                              <slot v-if="parseInt($store.state.Auth.authUser.org_id) === 3">
                                                {{ $t('globalTrans.officials_and_employees') }}
                                              </slot>
                                              <slot v-if="parseInt($store.state.Auth.authUser.org_id) === 4">
                                                {{ $t('globalTrans.scientists_officials_and_employees') }}
                                              </slot>
                                              <slot v-else>
                                                {{ $t('warehouseFarmer.officer') }}
                                              </slot>
                                            </span>
                                            <span class="badge badge-info" v-else>{{$t('globalTrans.other')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Training Title Update" v-b-modal.modal-4 @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Change Status" v-if="data.item.status === 1" @click="remove(data.item)">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status" v-if="data.item.status === 2" @click="remove(data.item)">
                                                <i class="fa fa-toggle-off"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('elearning_config.training_title_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :item="item" :key="item"></Details>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingTitleList, trainingTitleToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details
    },
    data () {
        return {
            search: {
                training_title_id: '',
                training_type_id: '',
                training_category_id: '',
                org_id: ''
            },
            trainingTitleList: [],
            trainingTypeList: [],
            trainingCategoryList: [],
            item: '',
            statusList: [
                { value: 1, text: 'Other', text_en: 'Other', text_bn: 'অন্যান্য' },
                { value: 2, text: 'Officer', text_en: 'Officer', text_bn: 'অফিসার' }
            ],
            myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
        }
    },
    computed: {
        trainingTitle: function () {
          return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        },
        organizationtList: function () {
            return this.$store.state.commonObj.organizationProfileList
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_config.training_title') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.training_title') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.organization'), class: 'text-center' },
                { label: this.$t('elearning_config.training_type'), class: 'text-center' },
                { label: this.$t('elearning_config.training_category'), class: 'text-center' },
                { label: this.$t('elearning_config.training_title'), class: 'text-center' },
                { label: this.$t('globalTrans.remarks'), class: 'text-center' },
                { label: this.$t('elearning_config.person_type'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'org_bn' },
                { key: 'training_type_bn' },
                { key: 'training_category_bn' },
                { key: 'training_title_bn' },
                { key: 'remarks_bn' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'org_en' },
                { key: 'training_type' },
                { key: 'training_category' },
                { key: 'training_title_en' },
                { key: 'remarks' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        if (this.$store.state.Auth.activeRoleId !== 1) {
            this.search.org_id = this.$store.state.Auth.authUser.org_id
        }
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
        'search.crop_type_id': function (newValue){
            this.cropLists(newValue)
        },
     'search.org_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_type_id = ''
          this.trainingTypeList = this.getTypeList(newValue)
        } else {
          this.trainingTypeList = []
        }
      },
      'search.training_type_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_category_id = ''
          this.trainingCategoryList = this.getCategoryList(newValue)
        } else {
          this.trainingCategoryList = []
        }
      },
      'search.training_category_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_title_id = ''
          this.trainingTitleList = this.getTrainingTitleList(newValue)
        } else {
          this.trainingTitleList = []
        }
      }
    },
    methods: {
        getTypeList (orgId) {
            if (this.search.org_id) {
            const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
            if (orgId) {
                return type.filter(item => item.org_id === parseInt(orgId))
            }
            return type
            }
        },
        getCategoryList (typeId) {
            if (this.search.training_type_id) {
            const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
            if (typeId) {
                return trainingCategoryList.filter(item => item.training_type_id === typeId)
            }
            return trainingCategoryList
            }
        },
        getTrainingTitleList (categoryId) {
            if (this.search.training_category_id) {
            const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
            if (categoryId) {
                return trainingTitleList.filter(item => item.training_category_id === categoryId)
            }
            return trainingTitleList
            }
        },
        details (item) {
            this.item = item
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(trainingElearningServiceBaseUrl, trainingTitleToggleStatus, item, 'training_e_learning', 'trainingTitleList')
        },
        getTitleList(orgId) {
            const type = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
            if (orgId) {
                return type.filter(item => item.org_id === parseInt(orgId))
            }
            return type
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, trainingTitleList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList(data) {
            const listData = data.map(item => {
            const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
            const statusObj = this.statusList.find(statusItem => statusItem.value === parseInt(item.training_status))
            const trainingTypeData = {}
            if(typeof trainingTypeObj !== 'undefined') {
              trainingTypeData.training_type = trainingTypeObj.text_en
              trainingTypeData.training_type_bn = trainingTypeObj.text_bn
            } else {
              trainingTypeData.training_type = ''
              trainingTypeData.training_type_bn = ''
            }
            if(typeof statusObj !== 'undefined') {
              trainingTypeData.status_name = statusObj.text
              trainingTypeData.status_name_bn = statusObj.text_bn
            } else {
              trainingTypeData.status_name = ''
              trainingTypeData.status_name_bn = ''
            }
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(law => law.value === parseInt(item.org_id))
                const orgData = {}
                if (typeof orgObj !== 'undefined') {
                    orgData.org_en = orgObj.text_en
                    orgData.org_bn = orgObj.text_bn
                } else {
                    orgData.org_en = ''
                    orgData.org_bn = ''
                }
            const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
            const trainingCategoryData = {}
            if(typeof trainingCategoryObj !== 'undefined') {
              trainingCategoryData.training_category = trainingCategoryObj.text_en
              trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
            } else {
              trainingCategoryData.training_category = ''
              trainingCategoryData.training_category_bn = ''
            }

            return Object.assign({}, item, trainingTypeData, trainingCategoryData, orgData)
          })
          return listData
        }
    }
}
</script>
