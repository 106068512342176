const routes = [{
    path: '/license-registration-service/license-step/',
    name: 'license-registration_service.license-step',
    component: () =>
        import('@/layouts/LicenseRegistrationLayout.vue'),
    meta: { auth: true },
    children: [
        {
            path: 'steps/:id',
            name: 'steps',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/steps/List.vue')
        },
        {
            path: 'archive/:id',
            name: 'Archive',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/archive/List.vue')
        },
        {
            path: 'reports/:id',
            name: 'reports',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/report/List.vue')
        },
        {
            path: 'dam-reports',
            name: 'dam-reports',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../pages/dam-report/List.vue')
        },
        {
            path: 'dae-import-report',
            name: 'dae_import_report',
            props: true,
            meta: {
                auth: true,
                service_id: 5,
                name: 'Editable'
            },
            component: () =>
                import('../pages/dae-report/List.vue')
        },
        {
            path: 'dae-production-report',
            name: 'dae_production_report',
            props: true,
            meta: {
                auth: true,
                service_id: 6,
                name: 'Editable'
            },
            component: () => import('../pages/dae-report/List.vue')
        },
        {
            path: 'dae-distributor-report',
            name: 'dae_distributor_report',
            props: true,
            meta: {
                auth: true,
                service_id: 23,
                name: 'Editable'
            },
            component: () => import('../pages/dae-report/List.vue')
        },
        {
            path: 'payment-history-report',
            name: 'payment-history-report',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/payment-history-report/List.vue')
        },
        {
            path: 'import-export-report',
            name: 'import-export-report',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/import-export-report/List.vue')
        },
        {
            path: 'registration-fee-vat-report',
            name: 'registration_fee_vat_report',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/registration-fee-vat-report/List.vue')
        },
        {
            path: 'seed-dealer-renewal-appointment-report',
            name: 'seed_dealer_renewal_appointment_report',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/seed-dealer-renewal-appointment-proposed-report/List.vue')
        },
        {
            path: 'acceptance-and-disposal',
            name: 'acceptance_and_disposal',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/report/acceptance-and-disposal/List.vue')
        },
        {
            path: 'dae-annual-performance-report',
            name: 'dae_annual_performance_report',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/report/dae/annual-performance-report/List.vue')
        },
        {
            path: 'dae-document-issue-report',
            name: 'dae_document_issue_report',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/report/dae/document-issue-report/List.vue')
        },
        {
            path: 'security-collection-report',
            name: 'security_collection_report',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () => import('../pages/security-collection-report/List.vue')
        },
        {
            path: 'approve-reject',
            name: 'approve_reject',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../components/common/approveReject.vue')
        },
        {
            path: 'license-forfeited',
            name: 'license_forfeited',
            props: true,
            meta: {
                auth: true,
                name: 'Editable'
            },
            component: () =>
                import('../components/common/licenseForfeited.vue')
        },
        {
            path: 'forward',
            name: 'forward',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../components/common/Forward.vue')
        },
        {
            path: 'approve-issue',
            name: 'approve_issue',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/ApproveIssueFromDetails.vue')
        },
        {
            path: 'preview',
            name: 'preview',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/DocumentPreviewfromDetails.vue')
        },
        {
            path: 'application-form',
            name: 'application_form',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/AppForm.vue')
        },
        {
            path: 'action-form',
            name: 'action_form',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/action-component/ActionMain.vue')
        },
        {
            path: 'document-view',
            name: 'document_view',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/DocumentView.vue')
        },
        {
            path: 'app-detail',
            name: 'app_detail',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/DetailsMain.vue')
        },
        {
            path: 'edit-form',
            name: 'edit_form',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/AppEdit.vue')
        },
        {
            path: 'transfer',
            name: 'transfer',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/Transfer.vue')
        },
        {
            path: 'transfer-list',
            name: 'transfer_list',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/TransferList.vue')
        },
        {
            path: 'cancel-list',
            name: 'cancel_list',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/CancelList.vue')
        },
        {
            path: 'withdraw-list',
            name: 'withdraw_list',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/WithdrawList.vue')
        },
        {
            path: 'challan-no',
            name: 'challan-no',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/challan-no/List.vue')
        },
        {
            path: 'dae-attachment',
            name: 'dae-attachment',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/dae-attachment/List.vue')
        },
        {
            path: 'numerical-report',
            name: 'numerical_report',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/NumericalReport.vue')
        },
        {
            path: 'payment-list',
            name: 'payment_list',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/payment-report/PaymentList.vue')
        },
        {
            path: 'challan-report',
            name: 'challan_report',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/challan-report/index.vue')
        },
        {
            path: 'expiration-active-passed-report',
            name: 'expiration_active_passed_report',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/report/expiration-passed-report/List.vue')
        },
        {
            path: 'application-entry',
            name: 'application_entry',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/application-entry/List.vue')
        },
        {
            path: 'application-form-entry',
            name: 'application_form_entry',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/application-entry/Form.vue')
        },
        {
            path: 'lab-assign/:id',
            name: 'lab_assign',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/static/lab-assign/index.vue')
        },
        {
            path: 'lab-assign-entry',
            name: 'lab_assign_entry',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/static/lab-assign/Form.vue')
        },
        {
            path: 'boro-recommendation/:id',
            name: 'boro_recommendation',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/static/boro-recommendation/index.vue')
        },
        {
            path: 'nothi-recommendation/:id',
            name: 'nothi_recommendation',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/static/nothi-recommendation/index.vue')
        },
        {
            path: 'all-applications/:id',
            name: 'all_applications',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/steps/static/all-applications/index.vue')
        },
        {
            path: 'circular-list',
            name: 'circular_list',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/circular/List.vue')
        },
        {
            path: 'change-certificate-request',
            name: 'change_certificate_request',
            props: true,
            meta: { auth: true, name: 'Editable' },
            component: () => import('../pages/change-certificate-request/List.vue')
        }
    ]
}]

export default routes
