<template>
<div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ $t('portal.all_service') }}</h2>
      <img src="../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('portal.all_service') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="menu_bar_wrapper">
      <b-container>
        <b-row class="menu_bar" style="padding: 0 !important">
          <b-col sm="8" class="menubar_col">
            <div class="menu_left">
              <ul>
                <li><router-link :to="`/portal/services?customer_type_id=` + getId('customerTypeList')" >{{ $t('portal.service_for_whom') }}</router-link></li>
                <li><router-link :to="`/portal/services?service_category_id=` + getId('categoryList')" >{{ $t('portal.what_kind_of_service') }}</router-link></li>
                <li><router-link :to="`/portal/services?org_id=` + getId('orgList')" >{{ $t('portal.which_org_service') }}</router-link></li>
                <li>
                  <router-link class="active" to="/portal/all-services" >
                    <span v-if="this.$route.query.status">
                      <span v-if="this.$route.query.status === 'active'">{{ $t('portal.active_service') }}  ({{ $n(serviceList.length) }})</span>
                      <span v-else>{{ $t('portal.upcoming_service') }}  ({{ $n(serviceList.length) }})</span>
                    </span>
                    <span v-else>{{ $t('portal.all_service') }}  ({{ $n(serviceList.length) }})</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col sm="4" class="menubar_col">
            <div class="menu_right">
              <div class="position-relative">
                <b-form-input
                  size="sm"
                  @keypress.enter="$event.preventDefault()"
                  v-model="service_name"
                  class="mr-sm-2 portal_searchbar"
                  :placeholder="$t('portal.find_service')"
                ></b-form-input>
                <img
                  src="../../../assets/images/search-bar.png"
                  class="search_icon"
                  alt=""
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="all_service_wrapper">
              <h5 class="service_title">{{ $t('portal.all_service_list') }}</h5>
              <div v-if="loading">
                <b-row>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                  <b-col sm="12" class="mt-1" lg="6" md="6">
                      <b-skeleton height="60px" animation="wave" width="100%"></b-skeleton>
                  </b-col>
                </b-row>
              </div>
              <div v-else>
                <b-row>
                  <b-col sm="6" v-for="(service, index) in serviceList" :key="index">
                    <div class="service_item">
                      <b-card class="service_item_card">
                        <router-link :to="`/portal/service-details?service_type=allService&service_id=${service.id}`">
                          <div class="item_all table-responsive">
                            <table class="table table-sm mb-0">
                              <tbody>
                                <tr>
                                  <td style="width: 50%">
                                    <div class="service_name">{{ checkBn ? service.name_bn : service.name }}</div>
                                    <small v-if="service.status===1" class="counter_label">{{ $t('portal.active_service') }}</small>
                                    <small v-else class="counter_label">{{ $t('portal.upcoming_service') }}</small>
                                  </td>
                                  <!-- <td>
                                    <div style="margin-bottom: -6px;"><span class="counter">{{ $n(service.applied_service) }} </span> {{ $t('portal.person') }}</div>
                                    <small class="counter_label">{{ $t('portal.applied_for_service') }}</small>
                                  </td>
                                  <td>
                                    <div style="margin-bottom: -6px;"><span class="counter">{{ $n(service.received_service) }}</span> {{ $t('portal.person') }}</div>
                                    <small class="counter_label">{{ $t('portal.got_the_service') }}</small>
                                  </td> -->
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </router-link>
                      </b-card>
                    </div>
                  </b-col>
                  <b-col v-if="!serviceList.length">
                    <div class="service_item text-center pt-5">
                      <h5>{{ $t('globalTrans.noDataFound') }}</h5>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import RestApi, { commonServiceBaseUrl } from '../../../config/api_config'
import Common from '@/mixins/portal/common'
export default {
  mixins: [Common],
  created () {
    this.loadData()
  },
  data () {
    return {
      service_name: '',
      loading: false,
      parentId: '',
      serviceList: [],
      allServiceList: []
    }
  },
  computed: {
    totalService () {
      return this.$store.state.Portal.serviceData.total_services
    }
  },
  watch: {
    service_name: function (newVal, oldVal) {
      if (newVal) {
        const service = this.allServiceList.filter(el => {
          if (el.name.toLowerCase().includes(newVal.toLowerCase()) || el.name_bn.toLowerCase().includes(newVal.toLowerCase())) {
            return el
          }
        })
        this.serviceList = service
      } else {
        this.serviceList = [...this.allServiceList]
      }
    }
  },
  methods: {
    getId (comp) {
      if (comp === 'customerTypeList') {
        const firstCus = this.$store.state.Portal.customerTypeList.slice(0, 1).shift()
        return firstCus.id
      } else if (comp === 'categoryList') {
        const firstCat = this.$store.state.Portal.categoryList.slice(0, 1).shift()
        return firstCat.id
      } else {
        const firstOrg = this.$store.state.Portal.orgList.slice(0, 1).shift()
        return firstOrg.id
      }
    },
    async loadData () {
      this.loading = true
      let params = {}
      if (this.$route.query.status) {
        params = { status: this.$route.query.status }
      }
      const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/service-list', params)
      this.loading = false
      if (result.success) {
        this.serviceList = result.data
        this.allServiceList = result.data
      }
    },
    // setComp (qRoute) {
    //   if (qRoute) {
    //     if (this.$route.query.customer_type_id) {
    //       this.comp = 'customerTypeList'
    //       this.parentId = this.$route.query.customer_type_id
    //     } else if (this.$route.query.service_category_id) {
    //       this.comp = 'categoryList'
    //       this.parentId = this.$route.query.service_category_id
    //     } else {
    //       this.comp = 'orgList'
    //       this.parentId = this.$route.query.org_id
    //     }
    //   }
    // },
    truncate (str, n) {
      return (str.length > n) ? str.substr(0, n - 1) + '' : str
    },
    setSearch (comp) {
      const search = {
          org_id: 0,
          customer_type_id: 0,
          service_category_id: 0
        }
      if (comp === 'customerTypeList') {
        return Object.assign(search, { customer_type_id: 1 })
      } else if (comp === 'categoryList') {
        return Object.assign(search, { service_category_id: 1 })
      } else {
        return Object.assign(search, { org_id: 1 })
      }
    }
  }
}
</script>
