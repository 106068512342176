<template>
  <b-container>
    <b-row style="
        display: flex;
        flex-wrap: wrap;
        margin-right: -9px;
        margin-left: -12px;"
    >
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h5 class="text-light"><i class="ri-edit-line"></i> {{ $t('elearning_iabm.trainer_registration_information') }}</h5>
          </template>
          <template v-slot:headerAction>
            <router-link :class="'btn btn-success text-light'" :to="{ path: 'trainer-registration'}" size="sm">
              {{ $t('elearning_iabm.trainer_registration') }} {{ $t('globalTrans.list') }}
            </router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="isLoading">
              <b-container class="bv-example-row pl-4 pr-4">
                <ValidationObserver ref="search"  v-slot="{ handleSubmit, reset }">
                  <b-form id="search" @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                    <b-row v-if="!($route.query.id)">
                      <b-col lg="5" sm="12">
                        <ValidationProvider name="Mobile" vid='mobile' rules="min:11|max:11">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="mobile"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('globalUserData.mobile_no')}}
                            </template>
                            <b-form-input
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              id="mobile"
                              v-model="search.mobile"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="5" sm="12">
                        <ValidationProvider name="Email" vid='email' rules="email|">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="mobile"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('globalUserData.email')}}
                            </template>
                            <b-form-input
                              id="email"
                              v-model="search.email"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="2" sm="12" class="text-left">
                        <b-button type="submit" variant="primary" class="sm">{{ $t('globalTrans.search')}}</b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
                <b-overlay v-if="already_applied" :show="isLoadingData">
                  <h5 class="m-auto text-center w-50 text-danger px-3 py-3 rounded my-5">
                    <span>{{$t('elearning_iabm.already_registered') + ' ' }}...</span>
                  </h5>
                </b-overlay>
                <ValidationObserver v-else ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-row>
                      <b-col>
                          <p><b>{{ $t('elearning_iabm.registration_note_2')}} (<span class="text-danger">*</span>) {{ $t('elearning_iabm.registration_note_3')}}</b></p>
                      </b-col>
                  </b-row>
                  <b-form id="form" @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <b-row>
                      <b-overlay :show="isLoadingData">
                        <fieldset class="p-2 w-100">
                          <legend class="px-2 w-50 shadow-sm">{{$t('globalTrans.personal_information')}}</legend>
                          <b-row class="p-3">
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Name" vid='name' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('user.name')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="name"
                                      v-model="formData.name"
                                      :disabled="checkDisable('name')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Name (bn)" vid='name_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('user.name_bn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="name_bn"
                                      v-model="formData.name_bn"
                                      :disabled="checkDisable('name_bn')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="nonMandatory">
                              <ValidationProvider name="Father Name (En)" vid='name_of_father_husband' rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name_of_father_husband"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('externalUserIrrigation.father_name')}} {{$t('globalTrans.enn')}}
                                  </template>
                                  <b-form-input
                                      id="name_of_father_husband"
                                      v-model="formData.name_of_father_husband"
                                      :disabled="checkDisable('genname_of_father_husbandder')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="nonMandatory">
                              <ValidationProvider name="Father Name (Bn)" vid='name_of_father_husband_bn' rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name_of_father_husband_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('externalUserIrrigation.father_name')}}
                                  </template>
                                  <b-form-input
                                      id="name_of_father_husband_bn"
                                      v-model="formData.name_of_father_husband_bn"
                                      :disabled="checkDisable('name_of_father_husband_bn')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="nonMandatory">
                              <ValidationProvider name="Mother Name (En)" vid='mother_name' rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="mother_name"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('externalUserIrrigation.mother_name')}} {{$t('globalTrans.enn')}}
                                  </template>
                                  <b-form-input
                                    id="mother_name"
                                    v-model="formData.mother_name"
                                    :disabled="checkDisable('mother_name')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="nonMandatory">
                              <ValidationProvider name="Mother Name (Bn)" vid='mother_name_bn' rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="mother_name_bn"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('externalUserIrrigation.mother_name')}}
                                  </template>
                                  <b-form-input
                                    id="mother_name_bn"
                                    v-model="formData.mother_name_bn"
                                    :disabled="checkDisable('mother_name_bn')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="nonMandatory">
                              <ValidationProvider name="NID" vid='nid' :rules="'max:'+(invalidNid ? 0 : (10|13|17))+'|min:10|numeric'">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="nid"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalUserIrrigation.nid')}}
                                  </template>
                                  <b-form-input
                                      id="nid"
                                      type="number"
                                      v-model="formData.nid"
                                      @input="nidRules($event)"
                                      :disabled="checkDisable('nid')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="nonMandatory">
                              <ValidationProvider name="NID Attach" vid="nid_attachment" rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  :label="$t('elearning_iabm.nid_attach')"
                                  label-for="nid_attachment"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{$t('elearning_iabm.nid_attach')}}
                                  </template>
                                  <b-form-file
                                    id="nid_attachment"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    v-model="formData.nid_file"
                                    :disabled="checkDisable('nid_attachment')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-file>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                                  <a v-if="formData.nid_attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/iab/personal_info/nid/' + formData.nid_attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                  <p class="text-danger mt-2" style="font-size: 10px;">{{$t('elearning_iabm.training_file_validation_format')}}</p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="nonMandatory">
                              <ValidationProvider name="Date Of Birth" vid="date_of_birth" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="date_of_birth"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('externalUserIrrigation.date_of_birth')}}
                                  </template>
                                  <b-form-input
                                      class="fromDate"
                                      v-model="formData.date_of_birth"
                                      :disabled="checkDisable('date_of_birth')"
                                      :placeholder="$t('globalTrans.select_date')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Gender" vid="gender" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="gender"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('globalTrans.gender')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.gender"
                                      :disabled="checkDisable('gender')"
                                      :options="genderList"
                                      id="gender"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Mobile" vid='mobile' rules="required|min:11|max:11">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="mobile"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('globalTrans.mobile')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    id="mobile"
                                    v-model="formData.mobile"
                                    :disabled="checkDisable('mobile')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Email" vid='email' rules="required|email|min:3">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="email"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.email')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="email"
                                      v-model="formData.email"
                                      :disabled="checkDisable('email')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="nonMandatory">
                              <ValidationProvider name="Marital Status" vid="marital_status" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="marital_status"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.mat_status')}}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.marital_status"
                                      :disabled="checkDisable('marital_status')"
                                      :options="maritalList"
                                      id="marital_status"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="nonMandatory">
                              <ValidationProvider name="Blood Group" vid="blood_group" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="blood_group"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.blood_group')}}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.blood_group"
                                      :disabled="checkDisable('blood_group')"
                                      :options="bloodGroupList"
                                      id="blood_group"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="nonMandatory">
                              <ValidationProvider name="Religion" vid="religion" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="religion"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.religion')}}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.religion"
                                      :disabled="checkDisable('religion')"
                                      :options="religionList"
                                      id="religion"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="nonMandatory">
                              <ValidationProvider name="Photo" vid="image" rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  :label="$t('globalTrans.photo')"
                                  label-for="image"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('globalTrans.photo')}}
                                    </template>
                                    <b-form-file
                                      id="image"
                                      accept="image/*"
                                      v-model="formData.image_file"
                                      :disabled="checkDisable('image')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-file>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                    <a v-if="formData.image" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/' + formData.image" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                    <p class="text-danger mt-2" style="font-size: 10px;">{{$t('elearning_iabm.training_image_validation_format')}}</p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="nonMandatory">
                              <ValidationProvider name="Signature" vid="signature" rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  :label="$t('globalTrans.signature')"
                                  label-for="signature"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('globalTrans.signature')}}
                                    </template>
                                    <b-form-file
                                      id="signature"
                                      accept="image/*"
                                      v-model="formData.signature_file"
                                      :disabled="checkDisable('signature')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-file>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                    <a v-if="formData.signature" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/' + formData.signature" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                    <p class="text-danger mt-2" style="font-size: 10px;">{{$t('elearning_iabm.training_image_validation_format')}}</p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </fieldset>
                        <fieldset class="p-2 w-100" v-show="nonMandatory">
                          <legend class="px-2 w-50 shadow-sm">{{$t('elearning_iabm.emergency_contact_information')}}</legend>
                          <b-row  class="p-3">
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Emergency Contact Name (En)" vid='emergency_name' rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="emergency_name"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.emergency_contact_name')}} {{ $t('globalTrans.enn')}}
                                  </template>
                                  <b-form-input
                                    id="emergency_name"
                                    v-model="formData.emergency_name"
                                    :disabled="checkDisable('emergency_name')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Emergency Contact Name (Bn)" vid='emergency_name_bn' rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="emergency_name_bn"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.emergency_contact_name')}} {{ $t('globalTrans.bnn')}}
                                  </template>
                                  <b-form-input
                                    id="emergency_name_bn"
                                    v-model="formData.emergency_name_bn"
                                    :disabled="checkDisable('emergency_name_bn')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Relation (En)" vid='emergency_relation' rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="emergency_relation"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.relation')}} {{ $t('globalTrans.enn')}}
                                  </template>
                                  <b-form-input
                                    id="emergency_relation"
                                    v-model="formData.emergency_relation"
                                    :disabled="checkDisable('emergency_relation')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Relation (Bn)" vid='emergency_relation_bn' rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="emergency_relation_bn"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.relation')}} {{ $t('globalTrans.bnn')}}
                                  </template>
                                  <b-form-input
                                    id="emergency_relation_bn"
                                    v-model="formData.emergency_relation_bn"
                                    :disabled="checkDisable('emergency_relation_bn')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Emergency Contact" vid='emergency_contact' rules="min:11|max:11">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="emergency_contact"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.emergency_contact')}}
                                  </template>
                                  <b-form-input
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    id="emergency_contact"
                                    v-model="formData.emergency_contact"
                                    :disabled="checkDisable('emergency_contact')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </fieldset>
                        <fieldset class="p-2 w-100" v-show="nonMandatory">
                          <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.present_address')}}</legend>
                          <b-row class="pl-3">
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Area Type" vid="pre_area_type_id" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="area_type_id "
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('org_pro.area_type')}}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.address.pre_area_type_id "
                                      :disabled="checkDisable('pre_area_type_id', 'address')"
                                      id="area_type_id"
                                      :options="getAreaTypeList"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <b-row class="pl-3 pr-3">
                            <b-col lg="6" sm="12" v-show="ItemShow">
                              <ValidationProvider name="Division" vid="pre_division_id" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="pre_division_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro_division.division')}}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.address.pre_division_id"
                                      :disabled="checkDisable('pre_division_id', 'address')"
                                      :options="divisionList"
                                      id="pre_division_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="ItemShow">
                              <ValidationProvider name="District" vid="pre_district_id" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="pre_district_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro_district.district')}}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.address.pre_district_id"
                                      :disabled="checkDisable('pre_district_id', 'address')"
                                      :options="districtList"
                                      id="pre_district_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="PauroshobaItemShow || UnionItemShow">
                              <ValidationProvider name="Upazila" vid="upazila_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="upazila_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro_upazilla.upazilla')}}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.address.pre_upazilla_id"
                                      :disabled="checkDisable('pre_upazilla_id', 'address')"
                                      :options="upazilaList"
                                      id="upazila_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="UnionItemShow">
                              <ValidationProvider name="Union" vid="union_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="union_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro_union.union')}}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.address.pre_union_id"
                                      :disabled="checkDisable('pre_union_id', 'address')"
                                      :options="unionList"
                                      id="union_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="CityCorpItemShow">
                              <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="city_corporation_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro.city_corporation') }}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.address.pre_city_corporation_id"
                                      :disabled="checkDisable('pre_city_corporation_id', 'address')"
                                      :options="cityCorporationList"
                                      id="city_corporation_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="PauroshobaItemShow">
                              <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="pauroshoba_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro.pauroshoba') }}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.address.pre_pauroshoba_id"
                                      :disabled="checkDisable('pre_pauroshoba_id', 'address')"
                                      :options="pauroshobaList"
                                      id="pauroshoba_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="ItemShow">
                              <ValidationProvider name="Ward" vid="ward_id" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="ward_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro.ward') }}
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.address.pre_ward_id"
                                      :disabled="checkDisable('pre_ward_id', 'address')"
                                      :options="wardList"
                                      id="ward_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </fieldset>
                        <fieldset class="p-2 w-100" v-show="nonMandatory">
                          <legend class="px-2 w-50 shadow-sm">
                              <b-form-checkbox
                                  id="checkbox-1"
                                  v-model="formData.address.same_as_pre_address"
                                  :disabled="sameCheckDisable('same_as_pre_address', 'address')"
                                  name="checkbox-1"
                                  value="1"
                                  unchecked-value="0"
                                  class="checkboxes"
                              >
                                  {{ $t('externalTraining.parmanent_address') }} {{ '(' + $t('externalTraining.same_as') + ')' }}
                              </b-form-checkbox>
                          </legend>
                          <b-row class="pl-3 pr-3">
                              <b-col lg="6" sm="12">
                                  <ValidationProvider name="Area Type" vid="area_type_id" rules="">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="area_type_id "
                                          slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                              {{$t('org_pro.area_type')}}
                                          </template>
                                          <b-form-select
                                          plain
                                          v-model="formData.address.per_area_type_id "
                                          :disabled="sameCheckDisable('per_area_type_id', 'address')"
                                          id="area_type_id"
                                          :options="getAreaTypeList"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                          </b-row>
                          <b-row class="pl-3 pr-3">
                              <b-col lg="6" sm="12" v-show="ItemShow1">
                                  <ValidationProvider name="Division" vid="division_id" rules="">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="division_id"
                                          slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                          {{ $t('org_pro_division.division')}}
                                          </template>
                                          <b-form-select
                                          plain
                                          v-model="formData.address.per_division_id"
                                          :options="divisionList"
                                          id="division_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :disabled="sameCheckDisable('per_division_id', 'address')"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="12" v-show="ItemShow1">
                                  <ValidationProvider name="District" vid="district_id" rules="">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="per_district_id"
                                          slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                          {{ $t('org_pro_district.district')}}
                                          </template>
                                          <b-form-select
                                          plain
                                          v-model="formData.address.per_district_id"
                                          :options="districtList1"
                                          id="per_district_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :disabled="sameCheckDisable('per_district_id', 'address')"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="12" v-show="PauroshobaItemShow1 || UnionItemShow1">
                                  <ValidationProvider name="Upazila" vid="upazila_id">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="upazila_id"
                                          slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                          {{ $t('org_pro_upazilla.upazilla')}}
                                          </template>
                                          <b-form-select
                                          plain
                                          v-model="formData.address.per_upazilla_id"
                                          :options="upazilaList1"
                                          id="upazila_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :disabled="sameCheckDisable('per_upazilla_id', 'address')"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="12" v-show="UnionItemShow1">
                                  <ValidationProvider name="Union" vid="union_id">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="union_id"
                                          slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                          {{ $t('org_pro_union.union')}}
                                          </template>
                                          <b-form-select
                                          plain
                                          v-model="formData.address.per_union_id"
                                          :options="unionList1"
                                          id="union_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :disabled="sameCheckDisable('per_union_id', 'address')"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="12" v-show="CityCorpItemShow1">
                                  <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="city_corporation_id"
                                          slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                              {{ $t('org_pro.city_corporation') }}
                                          </template>
                                          <b-form-select
                                          plain
                                          v-model="formData.address.per_city_corporation_id"
                                          :options="cityCorporationList1"
                                          id="city_corporation_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :disabled="sameCheckDisable('per_city_corporation_id', 'address')"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="12" v-show="PauroshobaItemShow1">
                                  <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="pauroshoba_id"
                                          slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                              {{ $t('org_pro.pauroshoba') }}
                                          </template>
                                          <b-form-select
                                          plain
                                          v-model="formData.address.per_pauroshoba_id"
                                          :options="pauroshobaList1"
                                          id="pauroshoba_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :disabled="sameCheckDisable('per_pauroshoba_id', 'address')"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="12" v-show="ItemShow1">
                                  <ValidationProvider name="Ward" vid="ward_id" rules="">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="ward_id"
                                          slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                              {{ $t('org_pro.ward') }}
                                          </template>
                                          <b-form-select
                                          plain
                                          v-model="formData.address.per_ward_id"
                                          :options="wardList1"
                                          id="ward_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :disabled="sameCheckDisable('per_ward_id', 'address')"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                          </b-row>
                        </fieldset>
                        <fieldset class="p-2 w-100" v-show="nonMandatory">
                          <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.academic_info')}}</legend>
                          <b-row  class="p-3">
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Degree" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name_of_highest_degree"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.highest_degree')}} {{ $t('globalTrans.enn') }}
                                  </template>
                                  <b-form-input
                                      id="name_of_highest_degree"
                                      v-model="formData.name_of_highest_degree"
                                      :disabled="checkDisable('name_of_highest_degree')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Degree" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name_of_highest_degree_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.highest_degree')}} {{ $t('globalTrans.bnn') }}
                                  </template>
                                  <b-form-input
                                      id="name_of_highest_degree_bn"
                                      v-model="formData.name_of_highest_degree_bn"
                                      :disabled="checkDisable('name_of_highest_degree_bn')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="University/Board" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="board_university"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.board_university')}} {{ $t('globalTrans.enn') }}
                                  </template>
                                  <b-form-input
                                      id="board"
                                      v-model="formData.board"
                                      :disabled="checkDisable('board')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="University/Board" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="board_university"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.board_university')}} {{ $t('globalTrans.bnn') }}
                                  </template>
                                  <b-form-input
                                      id="board_bn"
                                      v-model="formData.board_bn"
                                      :disabled="checkDisable('board_bn')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Passing Year" vid="passing_year" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="passing_year"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.passing_year')}}
                                  </template>
                                  <b-form-input
                                      id="passing_year"
                                      v-model="formData.passing_year"
                                      :disabled="checkDisable('passing_year')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Group" vid="prof_group" rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="prof_group"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.group')}} {{ $t('globalTrans.enn') }}
                                  </template>
                                  <b-form-input
                                    id="prof_group"
                                    v-model="formData.prof_group"
                                    :disabled="checkDisable('prof_group')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Group" vid="prof_group_bn" rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="prof_group_bn"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.group')}} {{ $t('globalTrans.bnn') }}
                                  </template>
                                  <b-form-input
                                    id="prof_group_bn"
                                    v-model="formData.prof_group_bn"
                                    :disabled="checkDisable('prof_group_bn')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="CGPA" vid="cgpa" rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="cgpa"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.cgpa')}}
                                  </template>
                                  <b-form-input
                                    id="cgpa"
                                    v-model="formData.cgpa"
                                    :disabled="checkDisable('cgpa')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Attachment" vid="certificate_attachment" rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  :label="$t('globalTrans.attachment')"
                                  label-for="certificate_attachment"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{$t('globalTrans.attachment')}}
                                  </template>
                                  <b-form-file
                                    id="certificate_attachment"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    v-model="formData.certificate_file"
                                    :disabled="checkDisable('certificate_attachment')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-file>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                                  <a v-if="formData.certificate_attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/iab/personal_info/certificate/' + formData.certificate_attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                  <p class="text-danger mt-2" style="font-size: 10px;">{{$t('elearning_iabm.training_file_validation_format')}}</p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <!-- <b-col lg="6" sm="12">
                              <ValidationProvider name="Expertise" vid="expertise_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="expertise_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_config.expertise')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.expertise_id"
                                      :options="trainingExpertiseList"
                                      id="expertise_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                            <!-- <b-col lg="6" sm="12" v-if="formData.expertise_id === 999">
                              <ValidationProvider name="Expertise Name (En)" vid='expertise_name_en' rules="required">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="expertise_name_en"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.expertise_name')}} {{ $t('globalTrans.enn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="expertise_name_en"
                                    v-model="formData.expertise_name_en"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.expertise_id === 999">
                              <ValidationProvider name="Expertise Name (Bn)" vid='expertise_name_bn' rules="required">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="expertise_name_bn"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.expertise_name')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="expertise_name_bn"
                                    v-model="formData.expertise_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                          </b-row>
                        </fieldset>
                        <fieldset class="p-2 w-100">
                          <legend class="px-2 w-50 shadow-sm">{{$t('elearning_iabm.expertise_info')}}</legend>
                          <b-row class="p-3" v-for="(expertise_data, index) in formData.expertise_infos" :key="index">
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Expertise" :vid="'expertise_id' + index" :rules="(formData.applied_org_id == 6) ? '' : 'required|min_value:1'">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="expertise_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.expertise_field')}} <span class="text-danger">{{ formData.applied_org_id == 6 ? '' : '*' }}</span>
                                  </template>
                                  <v-select name="'expertise_infos['+index+'][expertise_id]'"
                                  v-model="expertise_data.expertise_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= trainingExpertiseList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                                  <!-- <b-form-select
                                      plain
                                      v-model="expertise_data.expertise_id"
                                      :options="trainingExpertiseList"
                                      :name="'expertise_infos['+index+'][expertise_id]'"
                                      :id="'expertise_infos['+index+'][expertise_id]'"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select> -->
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="expertise_data.expertise_id === 999">
                              <ValidationProvider name="Expertise Name (En)" :vid="'expertise_name_en' + index" :rules="(formData.applied_org_id == 6) ? '' : 'required'">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="expertise_name_en"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.expertise_name')}} {{ $t('globalTrans.enn')}} <span class="text-danger">{{ formData.applied_org_id == 6 ? '' : '*' }}</span>
                                  </template>
                                  <b-form-input
                                    v-model="expertise_data.expertise_name_en"
                                    :name="'expertise_infos['+index+'][expertise_name_en]'"
                                      :id="'expertise_infos['+index+'][expertise_name_en]'"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="expertise_data.expertise_id === 999">
                              <ValidationProvider name="Expertise Name (Bn)" :vid="'expertise_name_bn' + index" :rules="(formData.applied_org_id == 6) ? '' : 'required'">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="expertise_name_bn"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.expertise_name')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">{{ formData.applied_org_id == 6 ? '' : '*' }}</span>
                                  </template>
                                  <b-form-input
                                    v-model="expertise_data.expertise_name_bn"
                                    :name="'expertise_infos['+index+'][expertise_name_bn]'"
                                    :id="'expertise_infos['+index+'][expertise_name_bn]'"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Authenticator Name (En)" :vid="'authenticator_name_en' + index" :rules="(formData.applied_org_id == 6) ? '' : 'required'">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="authenticator_name_en"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.authenticator_name')}} {{ $t('globalTrans.enn')}} <span class="text-danger">{{ formData.applied_org_id == 6 ? '' : '*' }}</span>
                                  </template>
                                  <b-form-input
                                    v-model="expertise_data.authenticator_name_en"
                                    :name="'expertise_infos['+index+'][authenticator_name_en]'"
                                      :id="'expertise_infos['+index+'][authenticator_name_en]'"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Authenticator Name (Bn)" :vid="'authenticator_name_bn' + index" :rules="(formData.applied_org_id == 6) ? '' : 'required'">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="authenticator_name_bn"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.authenticator_name')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">{{ formData.applied_org_id == 6 ? '' : '*' }}</span>
                                  </template>
                                  <b-form-input
                                    v-model="expertise_data.authenticator_name_bn"
                                    :name="'expertise_infos['+index+'][authenticator_name_bn]'"
                                      :id="'expertise_infos['+index+'][authenticator_name_bn]'"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <input type="hidden" :name="'expertise_infos['+index+'][id]'" v-model="expertise_data.id"/>
                              <input type="hidden" :name="'expertise_infos['+index+'][pre_attachment]'" v-model="expertise_data.attachment"/>
                              <ValidationProvider :vid="'expertise_infos' + '.' + index + '.' + 'attachment'"  name="Attachment">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="attachment"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.attach_proof')}}
                                  </template>
                                  <b-form-file
                                    v-model="expertise_data.attachment"
                                    :name="'expertise_infos['+index+'][attachment]'"
                                    :id="'expertise_infos['+index+'][attachment]'"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-file>
                                  <div class="error invalid-feedback d-block" role="alert">
                                    {{ errors[0] }}
                                  </div>
                                  <a v-if="expertise_data.id && expertise_data.attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + expertise_data.attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                  <p class="text-danger mt-2" style="font-size: 10px;">{{ $i18n.locale == 'en' ? '[Maximum file size is 2 MB and file format is PNF/JPG/JPEG/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 2 MB এবং ফাইল ফরমেট PNF/JPG/JPEG/PDF]' }}</p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col sm="12" class="mt-0 text-right">
                              <b-button v-show="index == formData.expertise_infos.length-1" variant=" iq-bg-success" @click="addItem()"><i class="ri-add-line m-0"></i> {{ $t('globalTrans.add') }}</b-button>
                              <b-button v-show="index || ( !index && formData.expertise_infos.length > 1)" variant=" iq-bg-danger" @click="removeAttachment(index, expertise_infos)"><i class="ri-delete-bin-line m-0"></i></b-button>
                            </b-col>
                          </b-row>
                        </fieldset>
                        <fieldset class="p-2 w-100">
                          <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.professional_info')}}</legend>
                          <b-row class=" ml-2 mr-2">
                            <b-col lg="4" md="4" sm="12" xs="12">
                                <b-form-checkbox
                                    class="mt-2"
                                    id="same_as_mobile_number"
                                    v-model="formData.professional_info.retired"
                                    :value=1
                                    :unchecked-value=0
                                >
                                    {{$t('elearning_iabm.retired')}}
                                </b-form-checkbox>
                            </b-col>
                          </b-row>
                          <b-row class="mt-2 ml-2 mr-2" v-if="formData.professional_info.retired">
                              <b-col lg="12" md="12" sm="12" xs="12"><h5 style="text-align:left"> {{$t('elearning_iabm.last_office_information')}}</h5></b-col>
                              <hr/>
                          </b-row>
                          <b-row class="p-3">
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Profession Type" vid="profession_type" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="profession_type"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.profession_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.professional_info.profession_type"
                                      :disabled="checkDisable('profession_type', 'professional_info')"
                                      :options="professionTypeList"
                                      id="profession_type"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.professional_info.profession_type === 1 || formData.professional_info.profession_type === 2">
                              <ValidationProvider name="Job Type" vid="job_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="job_type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.job_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.professional_info.job_type_id"
                                      :disabled="checkDisable('job_type_id', 'professional_info')"
                                      :options="jobTypeList"
                                      id="job_type_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.professional_info.job_type_id == 1 && (formData.professional_info.profession_type == 1 ||  formData.professional_info.profession_type == 2)">
                              <ValidationProvider name="Cader Name (En)" vid='cader_name' rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="cader_name"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.cader_name')}} {{ $t('globalTrans.enn')}}
                                  </template>
                                  <b-form-input
                                    id="cader_name"
                                    v-model="formData.professional_info.cader_name"
                                    :disabled="checkDisable('cader_name', 'professional_info')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.professional_info.job_type_id == 1 && (formData.professional_info.profession_type == 1 ||  formData.professional_info.profession_type == 2)">
                              <ValidationProvider name="Cader Name (Bn)" vid='cader_name_bn' rules="">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="cader_name_bn"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.cader_name')}} {{ $t('globalTrans.bnn')}}
                                  </template>
                                  <b-form-input
                                    id="cader_name_bn"
                                    v-model="formData.professional_info.cader_name_bn"
                                    :disabled="checkDisable('cader_name_bn', 'professional_info')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.professional_info.job_type_id == 1 && (formData.professional_info.profession_type == 1 ||  formData.professional_info.profession_type == 2)">
                              <ValidationProvider name="Batch" vid="batch" rules="">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="batch"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{ $t('elearning_iabm.batch')}}
                                      </template>
                                      <b-form-input
                                          id="batch"
                                          v-model="formData.professional_info.batch"
                                          :disabled="checkDisable('batch', 'professional_info')"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.professional_info.profession_type == 2 || formData.professional_info.profession_type == 3">
                              <ValidationProvider name="Organization Name (En)" vid='other_org_name' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="other_org_name"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('globalTrans.org_name')}} {{ $t('globalTrans.enn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="other_org_name"
                                      v-model="formData.professional_info.other_org_name"
                                      :disabled="checkDisable('other_org_name', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.professional_info.profession_type == 2 || formData.professional_info.profession_type == 3">
                              <ValidationProvider name="Organization Name (Bn)" vid='other_org_name_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="other_org_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('globalTrans.org_name')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="other_org_name_bn"
                                      v-model="formData.professional_info.other_org_name_bn"
                                      :disabled="checkDisable('other_org_name_bn', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.professional_info.profession_type == 1">
                              <ValidationProvider name="Organization" vid="region_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalUserIrrigation.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.professional_info.org_id"
                                      :disabled="checkDisable('org_id', 'professional_info')"
                                      :options="orgList"
                                      id="org_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <!-- <b-col lg="6" sm="6" v-if="formData.professional_info.profession_type == 1 && formData.professional_info.not_here_office !== 1">
                                <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="office_type_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="formData.professional_info.office_type_id"
                                            :disabled="checkDisable('office_type_id', 'professional_info')"
                                            :options="officeTypeList"
                                            id="office_type_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6" v-if="formData.professional_info.profession_type == 1 && formData.professional_info.not_here_office !== 1">
                              <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="office_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                          plain
                                          v-model="formData.professional_info.office_id"
                                          :disabled="checkDisable('office_id', 'professional_info')"
                                          :options="officeList"
                                          id="office_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                            <!-- <b-col lg="12" sm="12" v-if="formData.professional_info.profession_type == 1" class="mb-3">
                                <b-form-checkbox
                                    class="mt-2"
                                    id="not_here_office"
                                    v-model="formData.professional_info.not_here_office"
                                    :disabled="checkDisable('not_here_office', 'professional_info')"
                                    :value=1
                                    :unchecked-value=0
                                >
                                <span class="text-danger">{{$t('elearning_iabm.not_here_office')}}</span>
                                </b-form-checkbox>
                            </b-col> -->
                            <!-- <b-col lg="6" sm="12"  v-if="formData.professional_info.not_here_office == 1"> -->
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Office Name (En)" vid='office_name' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="office_name"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.office_name_address')}} {{ $t('globalTrans.enn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="office_name"
                                      v-model="formData.professional_info.office_name"
                                      :disabled="checkDisable('office_name', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <!-- <b-col lg="6" sm="12" v-if="formData.professional_info.not_here_office == 1"> -->
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Office Name (Bn)" vid='office_name_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="office_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.office_name_address')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="office_name_bn"
                                      v-model="formData.professional_info.office_name_bn"
                                      :disabled="checkDisable('office_name_bn', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <!-- <b-col lg="6" sm="12" v-if="formData.professional_info.profession_type == 1 && formData.professional_info.not_here_office == 0" v-show="nonMandatory">
                              <ValidationProvider name="Office Email" vid='office_mail_address'>
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="office_mail_address"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.office_mail')}} {{ $t('globalTrans.enn') }}
                                  </template>
                                  <b-form-textarea
                                      id="office_mail_address"
                                      v-model="formData.professional_info.office_mail_address"
                                      :disabled="checkDisable('office_mail_address', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.professional_info.profession_type == 1 && formData.professional_info.not_here_office == 0" v-show="nonMandatory">
                              <ValidationProvider name="Office Email" vid='office_mail_address_bn'>
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="office_mail_address_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.office_mail')}} {{ $t('globalTrans.bnn') }}
                                  </template>
                                  <b-form-textarea
                                      id="office_mail_address_bn"
                                      v-model="formData.professional_info.office_mail_address_bn"
                                      :disabled="checkDisable('office_mail_address_bn', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                            <!-- <b-col lg="6" sm="12" v-if="formData.professional_info.profession_type == 1 && formData.professional_info.not_here_designation !== 1">
                              <ValidationProvider name="Designation" vid="designation_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="designation_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.designation')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.professional_info.designation_id"
                                      :disabled="checkDisable('designation_id', 'professional_info')"
                                      :options="designationList"
                                      id="designation_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                            <!-- <b-col lg="12" sm="12" v-if="formData.professional_info.profession_type == 1" class="mb-3">
                                <b-form-checkbox
                                    class="mt-2"
                                    id="not_here_designation"
                                    v-model="formData.professional_info.not_here_designation"
                                    :disabled="checkDisable('not_here_designation', 'professional_info')"
                                    :value=1
                                    :unchecked-value=0
                                >
                                <span class="text-danger">{{$t('elearning_iabm.not_here_designation')}}</span>
                                </b-form-checkbox>
                            </b-col> -->
                            <!-- <b-col lg="6" sm="12"  v-if="formData.professional_info.not_here_designation == 1 || formData.professional_info.profession_type == 2 || formData.professional_info.profession_type == 3"> -->
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Designation Name (En)" vid='designation_en' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="designation_en"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('globalTrans.designation')}} {{ $t('globalTrans.enn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="designation_en"
                                      v-model="formData.professional_info.designation_en"
                                      :disabled="checkDisable('designation_en', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <!-- <b-col lg="6" sm="12" v-if="formData.professional_info.not_here_designation == 1 || formData.professional_info.profession_type == 2 || formData.professional_info.profession_type == 3"> -->
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Designation Name (Bn)" vid='designation_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="designation_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('globalTrans.designation')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="designation_bn"
                                      v-model="formData.professional_info.designation_bn"
                                      :disabled="checkDisable('designation_bn', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.professional_info.profession_type === 1 || formData.professional_info.profession_type === 2" v-show="nonMandatory">
                              <ValidationProvider name="Service Id" vid="service_id" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="service_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.service_id')}}
                                  </template>
                                  <b-form-input
                                      id="service_id"
                                      v-model="formData.professional_info.service_id"
                                      :disabled="checkDisable('service_id', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.professional_info.profession_type === 1 || formData.professional_info.profession_type === 2">
                              <ValidationProvider name="Pay Grade" vid="grade_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="pay_grade"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.pay_grade')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.professional_info.grade_id"
                                      :disabled="checkDisable('grade_id', 'professional_info')"
                                      :options="gradeList"
                                      id="pay_grade"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="!formData.professional_info.retired && formData.professional_info.profession_type" v-show="nonMandatory">
                              <ValidationProvider name="Controlling Authority (Designation)" vid="controlling_authority">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="controlling_authority"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.designation_c')}} {{ $t('globalTrans.enn') }}
                                  </template>
                                  <b-form-input
                                      id="controlling_authority"
                                      v-model="formData.professional_info.controlling_authority"
                                      :disabled="checkDisable('controlling_authority', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="!formData.professional_info.retired && formData.professional_info.profession_type" v-show="nonMandatory">
                              <ValidationProvider name="Controlling Authority (Designation)" vid="controlling_authority_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="controlling_authority_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.designation_c')}} {{ $t('globalTrans.bnn') }}
                                  </template>
                                  <b-form-input
                                      id="controlling_authority_bn"
                                      v-model="formData.professional_info.controlling_authority_bn"
                                      :disabled="checkDisable('controlling_authority_bn', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="!formData.professional_info.retired && formData.professional_info.profession_type" v-show="nonMandatory">
                              <ValidationProvider name="Telephone" vid='telephone_c' rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="telephone_c"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.telephone_c')}}
                                  </template>
                                  <b-form-input
                                      type="text"
                                      id="telephone_c"
                                      v-model="formData.professional_info.telephone"
                                      :disabled="checkDisable('telephone', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="!formData.professional_info.retired && formData.professional_info.profession_type" v-show="nonMandatory">
                              <ValidationProvider name="Email" vid='email_c' rules="email|min:3">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="email_c"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.email_c')}}
                                  </template>
                                  <b-form-input
                                      id="email_c"
                                      v-model="formData.professional_info.email"
                                      :disabled="checkDisable('email', 'professional_info')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-show="formData.professional_info.retired">
                                <ValidationProvider name="Retirement Date" vid="retirement_date" rules="">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="retirement_date"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{ $t('elearning_iabm.retirement_date')}}
                                        </template>
                                        <b-form-input
                                            class="fromDate"
                                            v-model="formData.professional_info.retirement_date"
                                            :placeholder="$t('elearning_iabm.retirement_date')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="6" v-show="nonMandatory">
                                <ValidationProvider name="Specialist" vid='specialist'>
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="specialist"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{ $t('elearning_iabm.specialist')}}
                                        </template>
                                        <b-form-textarea
                                            id="specialist"
                                            v-model="formData.professional_info.specialist"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                          </b-row>
                        </fieldset>
                        <fieldset class="p-2 w-100" v-if="formData.applied_org_id === 6">
                          <legend class="px-2 w-50 shadow-sm">{{$t('elearning_iabm.work_space_info')}}</legend>
                          <b-row class="p-3">
                            <b-col lg="12" sm="12">
                              {{ $t('elearning_iabm.apply_note') }}
                            </b-col>
                          </b-row>
                          <b-row class="p-3">
                            <b-col lg="6" sm="6">
                                <ValidationProvider name="Office Type" vid="recomend_office_type_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="recomend_office_type_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="formData.recomend_office_type_id"
                                            :options="brriOfficeTypeList"
                                            id="recomend_office_type_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Office" vid="recomend_office_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="recomend_office_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                          plain
                                          v-model="formData.recomend_office_id"
                                          :options="brriOfficeList"
                                          id="recomend_office_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <b-row class="p-3">
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Designation" vid="recomend_designation_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="recomend_designation_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalTraining.designation')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.recomend_designation_id"
                                      :options="brriDesignationList"
                                      id="recomend_designation_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </fieldset>
                      </b-overlay>
                    </b-row>
                    <b-row v-if="$store.state.Auth.activeRoleId === 1">
                        <b-col lg="9" sm="12">
                            <ValidationProvider name="Applied Organization" vid="applied_org_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    :label-cols-sm="$i18n.locale === 'bn' ? 4 : 5"
                                    label-for="applied_org_id"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('elearning_iabm.registration_note_new')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.applied_org_id"
                                    :disabled="!$store.state.Auth.activeRoleId"
                                    :options="orgList"
                                    id="applied_org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                            <p class="text-danger"><b>{{ $t('elearning_iabm.registration_note')}}</b></p>
                            <!-- <p><b>{{ $t('elearning_iabm.registration_note_2')}} (<span class="text-danger">*</span>) {{ $t('elearning_iabm.registration_note_3')}}</b></p> -->
                        </b-col>
                    </b-row>
                    <div class="text-right">
                      <b-button type="submit" variant="primary" class="mr-2" :disabled="isLoading">
                        <span v-if="isLoading"><b-spinner small></b-spinner> {{ $t('globalTrans.loading') }}</span>
                        <span v-else>{{ saveBtnName }}</span>
                      </b-button>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-container>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
      <!-- <pre>{{formData}}</pre> -->
    </b-row>
  </b-container>
</template>
<style>
.checkboxes label.custom-control-label {
    color: white !important;
    font-size: 13px !important;
    font-weight: bold !important;
}
select#profession_type {
    text-transform: none !important;
}
</style>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { personalInfoStore, personalInfoUpdate, personalInfoTrainerShow, personalInfoData } from '../../api/routes'
import { Common } from '@/mixins/helper-functions'
import flatpickr from 'flatpickr'
import { SpinnerPlugin } from 'bootstrap-vue'
import Vue from 'vue'
Vue.use(SpinnerPlugin)

export default {
  mixin: [Common],
  created () {
    if (this.id) {
      this.getFormData()
    }
    let orgiId = 0
    if (this.$store.state.Auth.activeRoleId !== 1) {
      orgiId = this.$store.state.Auth.authUser.org_id
    }
    this.formData.applied_org_id = orgiId
  },
  data () {
    return {
      id: this.$route.query.id,
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      already_applied: false,
      ItemShow: false,
      nonMandatory: false,
      CityCorpItemShow: false,
      PauroshobaItemShow: false,
      UnionItemShow: false,
      ItemShow1: false,
      CityCorpItemShow1: false,
      PauroshobaItemShow1: false,
      UnionItemShow1: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      formData: {
        id: 0,
        first_entry: 1,
        recomend_office_type_id: 0,
        recomend_office_id: 0,
        recomend_designation_id: 0,
        trainer: 1,
        name: '',
        name_bn: '',
        date_of_birth: '',
        name_of_father_husband: '',
        name_of_father_husband_bn: '',
        mother_name: '',
        mother_name_bn: '',
        email: '',
        nid: '',
        mobile: '',
        gender: 0,
        marital_status: 0,
        applied_org_id: 0,
        blood_group: 0,
        emergency_name: '',
        emergency_name_bn: '',
        emergency_relation: '',
        emergency_relation_bn: '',
        emergency_contact: '',
        name_of_highest_degree: '',
        name_of_highest_degree_bn: '',
        board: '',
        board_bn: '',
        passing_year: '',
        prof_group: '',
        prof_group_bn: '',
        cgpa: '',
        expertise_infos: [
          {
            id: '',
            expertise_id: '',
            expertise_name_en: '',
            expertise_name_bn: '',
            authenticator_name_en: '',
            authenticator_name_bn: '',
            attachment: null,
            pre_attachment: []
          }
        ],
        image_file: [],
        signature_file: [],
        nid_file: [],
        certificate_file: [],
        // expertise_id: 0,
        // expertise_name_en: '',
        // expertise_name_bn: '',
        professional_info: {
          retired: 0,
          org_id: 0,
          office_type_id: 0,
          office_id: 0,
          other_org_name: '',
          other_org_name_bn: '',
          not_here_office: 1,
          office_name: '',
          office_name_bn: '',
          not_here_designation: 1,
          designation_en: '',
          designation_bn: '',
          profession_type: 0,
          job_type_id: 0,
          designation_id: 0,
          grade_id: 0,
          service_id: '',
          batch: '',
          cader_name: '',
          cader_name_bn: '',
          controlling_authority: '',
          controlling_authority_bn: '',
          telephone: '',
          email: '',
          retirement_date: '',
          specialist: '',
          office_mail_address: '',
          office_mail_address_bn: ''
        },
        address: {
          pre_area_type_id: 0,
          pre_division_id: 0,
          pre_district_id: 0,
          pre_upazilla_id: 0,
          pre_ward_id: '',
          pre_union_id: '',
          pre_city_corporation_id: '',
          pre_pauroshoba_id: '',
          same_as_pre_address: '',
          per_area_type_id: 0,
          per_division_id: 0,
          per_district_id: 0,
          per_upazilla_id: 0,
          per_city_corporation_id: '',
          per_pauroshoba_id: '',
          per_ward_id: '',
          per_union_id: ''
        },
        is_draft: 1,
        trainer_status: 3
      },
      trainingData: {
        professional_info: {
          retired: 0,
          org_id: 0,
          office_type_id: 0,
          office_id: 0,
          other_org_name: '',
          other_org_name_bn: '',
          not_here_office: 1,
          office_name: '',
          office_name_bn: '',
          not_here_designation: 1,
          designation_en: '',
          designation_bn: '',
          profession_type: 0,
          job_type_id: 0,
          designation_id: 0,
          grade_id: 0,
          applied_org_id: 0,
          service_id: '',
          batch: '',
          cader_name: '',
          cader_name_bn: '',
          controlling_authority: '',
          controlling_authority_bn: '',
          telephone: '',
          email: '',
          retirement_date: '',
          specialist: '',
          office_mail_address: '',
          office_mail_address_bn: ''
        },
        address: {
          pre_area_type_id: 0,
          pre_division_id: 0,
          pre_district_id: 0,
          pre_upazilla_id: 0,
          pre_ward_id: '',
          pre_union_id: '',
          pre_city_corporation_id: '',
          pre_pauroshoba_id: '',
          same_as_pre_address: 0,
          per_area_type_id: 0,
          per_division_id: 0,
          per_district_id: 0,
          per_upazilla_id: 0,
          per_city_corporation_id: '',
          per_pauroshoba_id: '',
          per_ward_id: '',
          per_union_id: ''
        }
      },
      search: {
        mobile: ''
      },
      saved: false,
      officeTypeList: [],
      expertise_infos_remove: [],
      officeList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      wardList: [],
      cityCorporationList: [],
      pauroshobaList: [],
      districtList1: [],
      upazilaList1: [],
      unionList1: [],
      wardList1: [],
      cityCorporationList1: [],
      pauroshobaList1: [],
      designationList: [],
      brriDesignationList: [],
      brriOfficeTypeList: [],
      brriOfficeList: [],
      zoneList: [],
      unitList: [],
      invalidNid: 1,
      loading: false,
      isDisabled: false,
      isLoading: false,
      isLoadingData: false,
      myFilter: (option, text, search) => {
              const temp = search.toLowerCase()
              return option.text_en.toLowerCase().indexOf(temp) > -1 ||
              option.text_bn.toLowerCase().indexOf(temp) > -1
          }
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  computed: {
    yesNoList () {
        return [
            { text: this.$i18n.locale === 'bn' ? 'হ্যাঁ' : 'Yes', value: 1 },
            { text: this.$i18n.locale === 'bn' ? 'না' : 'No', value: 0 }
        ]
    },
    professionTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non-Goverment Organization',
          text_en: 'Non-Goverment Organization',
          text_bn: 'বেসরকারি সংস্থা'
        }
      ]
    },
    authenticatorNameList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'সার্টিফিকেট' : 'Certificate',
          text_en: 'Certificate',
          text_bn: 'সার্টিফিকেট'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'প্রত্যয়ন পত্র' : 'Approval',
          text_en: 'Approval',
          text_bn: 'প্রত্যয়ন পত্র'
        }
      ]
    },
    orgList () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    trainingExpertiseList () {
      let expertiseData
      if (this.formData.applied_org_id) {
        expertiseData = this.$store.state.TrainingElearning.commonObj.trainingExpertiseList.filter(item => item.status === 1)
      } else {
        expertiseData = this.$store.state.TrainingElearning.commonObj.trainingExpertiseList.filter(item => item.status === 1)
      }
      expertiseData.push(
        {
          value: 999,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য' : 'Other',
          text_en: 'Other',
          text_bn: 'অন্যান্য'
        }
      )
      return expertiseData
    },
    jobTypeList () {
      return this.$store.state.TrainingElearning.commonObj.jobTypeList
    },
    getAreaTypeList: function () {
      const objectData = this.$store.state.commonObj.wardTypeList
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.name_bn }
        } else {
          return { value: obj.id, text: obj.name }
        }
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Resource Person' : 'রিসোর্স পারসন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    genderList () {
      const genderList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Male' : 'পুরুষ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Female' : 'মহিলা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য' }
      ]
      return genderList
    },
    maritalList () {
      const status = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Married' : 'বিবাহিত' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Unmarried' : 'অবিবাহিত' }
      ]
      return status
    },
    bloodGroupList () {
      const bloodGroupList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'A+' : 'এ+' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'A-' : 'এ-' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'B+' : 'বি+' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'B-' : 'বি-' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'O+' : 'ও+' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'O-' : 'ও-' },
        { value: 7, text: this.$i18n.locale === 'en' ? 'AB+' : 'এবি+' },
        { value: 8, text: this.$i18n.locale === 'en' ? 'AB-' : 'এবি-' }
      ]
      return bloodGroupList
    },
    religionList () {
      const religionList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Muslim' : 'মুসলিম' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Hindu' : 'হিন্দু' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Christian' : 'খ্রিস্টান' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Buddhist' : 'বৌদ্ধ ' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য' }
      ]
      return religionList
    },
    gradeList: function () {
       return this.$store.state.commonObj.gradeList.filter(item => item.status === 0)
    }
  },
  watch: {
    // 'formData.mobile': function (newVal, oldVal) {
    //   this.getPersonalData(newVal)
    // },
    'formData.address.pre_area_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAreaTypeData(this.formData.address.pre_area_type_id)
      }
    },
    'formData.address.per_area_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAreaTypeData1(this.formData.address.per_area_type_id)
      }
    },
    'formData.address.pre_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'formData.address.pre_district_id': function (newVal, oldVal) {
      this.cityCorporationList = this.getCityCorporationList(newVal)
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'formData.address.pre_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.pauroshobaList = this.getPauroshobaList(newVal)
    },
    'formData.address.pre_city_corporation_id': function (newVal, oldVal) {
      this.wardList = this.getWardListByCityCorportaion(newVal)
    },
    'formData.address.pre_pauroshoba_id': function (newVal, oldVal) {
      this.wardList = this.getWardListByPauroshoba(newVal)
    },
    'formData.address.pre_union_id': function (newVal, oldVal) {
      this.wardList = this.getWardListByUnion(newVal)
    },
    'formData.address.per_division_id': function (newVal, oldVal) {
      this.districtList1 = this.getDistrictList(newVal)
    },
    'formData.address.per_district_id': function (newVal, oldVal) {
      this.cityCorporationList1 = this.getCityCorporationList(newVal)
      this.upazilaList1 = this.getUpazilaList(newVal)
    },
    'formData.address.per_upazilla_id': function (newVal, oldVal) {
      this.unionList1 = this.getUnionList(newVal)
      this.pauroshobaList1 = this.getPauroshobaList(newVal)
    },
    'formData.address.per_city_corporation_id': function (newVal, oldVal) {
      this.wardList1 = this.getWardListByCityCorportaion(newVal)
    },
    'formData.address.per_pauroshoba_id': function (newVal, oldVal) {
      this.wardList1 = this.getWardListByPauroshoba(newVal)
    },
    'formData.address.per_union_id': function (newVal, oldVal) {
      this.wardList1 = this.getWardListByUnion(newVal)
    },
    'formData.professional_info.org_id': function (newVal, oldVal) {
      if (newVal !== 0) {
        this.designationList = this.getDesignationByOrg(newVal)
        this.officeTypeList = this.getOfficeTypeList(newVal)
      }
    },
    'formData.professional_info.office_type_id': function (newValue) {
      if (newValue !== 0) {
        this.officeList = this.getParentOfficeList(newValue)
      }
    },
    'formData.address.same_as_pre_address': function (newVal, oldVal) {
      if (newVal === '1') {
        this.formData.address.per_area_type_id = this.formData.address.pre_area_type_id
        this.formData.address.per_division_id = this.formData.address.pre_division_id
        this.formData.address.per_district_id = this.formData.address.pre_district_id
        this.formData.address.per_upazilla_id = this.formData.address.pre_upazilla_id
        this.formData.address.per_city_corporation_id = this.formData.address.pre_city_corporation_id
        this.formData.address.per_pauroshoba_id = this.formData.address.pre_pauroshoba_id
        this.formData.address.per_ward_id = this.formData.address.pre_ward_id
        this.formData.address.per_union_id = this.formData.address.pre_union_id
        this.getAreaTypeData1(this.formData.address.pre_area_type_id)
      }
    },
    'formData.applied_org_id': function (newVal, oldVal) {
      if (newVal !== 0) {
        this.brriDesignationList = this.getDesignationByOrg(newVal)
        this.brriOfficeTypeList = this.getOfficeTypeList(newVal)
      }
    },
    'formData.recomend_office_type_id': function (newValue) {
      if (newValue !== 0) {
        this.brriOfficeList = this.getParentOfficeList(newValue)
      }
    }

  },
  methods: {
    addItem () {
      this.formData.expertise_infos.push({
        id: '',
        expertise_id: '',
        expertise_name_en: '',
        expertise_name_bn: '',
        authenticator_name_en: '',
        authenticator_name_bn: '',
        attachment: null,
        pre_attachment: []
      })
    },
    removeAttachment (index, data) {
      this.formData.expertise_infos.splice(index, 1)
      this.expertise_infos_remove.push(data)
    },
    nidRules (e) {
      if (e.length) {
          this.invalidNid = (parseInt(e.length) === 10 || parseInt(e.length) === 13 || parseInt(e.length) === 17) ? 0 : 1
      } else {
          this.invalidNid = 1
      }
    },
    sameCheckDisable (field, type = null) {
      if (field !== 'same_as_pre_address') {
        if (parseInt(this.formData.address.same_as_pre_address) === 1) {
          return true
        }
      }
      return this.checkDisable(field, type)
    },
    checkDisable (field, type = null) {
      let mainData = []
      if (type) {
        if (type === 'address') {
          mainData = this.trainingData.address
        }
        if (type === 'professional_info') {
          mainData = this.trainingData.professional_info
        }
      } else {
        mainData = this.trainingData
      }
      const data = mainData[field]
      if (data) {
          return true
      }
      return false
    },
    searchData () {
      const mobile = this.search.mobile
      this.getPersonalData(mobile)
    },
    async getPersonalData (mobile) {
      this.isLoadingData = true
      const params = {
        mobile: mobile,
        email: this.search.email,
        applied_org_id: this.$store.state.Auth.authUser.org_id,
        trainer: 1
      }
      this.formData.mobile = mobile
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, personalInfoData, params)
      let orgiId = 0
      if (this.$store.state.Auth.activeRoleId !== 1) {
        orgiId = this.$store.state.Auth.authUser.org_id
      }
      if (result.success) {
        this.isLoadingData = false
        if (result.data.trainer === 1) {
          this.already_applied = true
        } else {
          this.formData = result.data
          if (!result.data.expertise_infos.length) {
            this.formData.expertise_infos = [{
              id: '',
              expertise_id: 0,
              expertise_name_en: '',
              expertise_name_bn: '',
              authenticator_name_en: '',
              authenticator_name_bn: '',
              attachment: null,
              pre_attachment: []
            }]
          }
          this.trainingData = result.otherData
          if (this.formData.nid) {
            this.invalidNid = 0
          }
          this.already_applied = false
          if (this.$store.state.Auth.activeRoleId !== 1) {
            this.formData.applied_org_id = this.$store.state.Auth.authUser.org_id
          }
        }
      } else {
        this.$refs.form.reset()
        this.isLoadingData = false
        let userId
        if (result.userData) {
          userId = result.userData.id
        } else {
          userId = 0
        }
        this.formData = {
          id: 0,
          trainer: 1,
          user_id: userId,
          name: result.userData ? result.userData.name : '',
          name_bn: result.userData ? result.userData.name_bn : '',
          date_of_birth: '',
          name_of_father_husband: '',
          name_of_father_husband_bn: '',
          mother_name: '',
          mother_name_bn: '',
          email: result.userData.email ? result.userData.email : '',
          nid: '',
          mobile: result.userData.mobile_no ? result.userData.mobile_no : '',
          gender: 0,
          marital_status: 0,
          applied_org_id: orgiId,
          blood_group: 0,
          religion: '',
          emergency_name: '',
          emergency_name_bn: '',
          emergency_relation: '',
          emergency_relation_bn: '',
          emergency_contact: '',
          name_of_highest_degree: '',
          name_of_highest_degree_bn: '',
          board: '',
          board_bn: '',
          passing_year: '',
          prof_group: '',
          prof_group_bn: '',
          cgpa: '',
          expertise_infos: [
            {
              id: '',
              expertise_id: 0,
              expertise_name_en: '',
              expertise_name_bn: '',
              authenticator_name_en: '',
              authenticator_name_bn: '',
              attachment: null,
              pre_attachment: []
            }
          ],
          image_file: [],
          signature_file: [],
          nid_file: [],
          certificate_file: [],
          expertise_id: 0,
          expertise_name_en: '',
          expertise_name_bn: '',
          professional_info: {
            retired: 0,
            org_id: 0,
            other_org_name: '',
            other_org_name_bn: '',
            not_here_office: 1,
            office_name: '',
            office_name_bn: '',
            not_here_designation: 1,
            designation_en: '',
            designation_bn: '',
            profession_type: 0,
            job_type_id: 0,
            designation_id: 0,
            grade_id: 0,
            service_id: '',
            batch: '',
            cader_name: '',
            cader_name_bn: '',
            controlling_authority: '',
            controlling_authority_bn: '',
            telephone: '',
            email: '',
            retirement_date: '',
            specialist: '',
            office_mail_address: '',
            office_mail_address_bn: ''
          },
          address: {
            pre_area_type_id: 0,
            pre_division_id: 0,
            pre_district_id: 0,
            pre_upazilla_id: 0,
            pre_ward_id: '',
            pre_union_id: '',
            pre_city_corporation_id: '',
            pre_pauroshoba_id: '',
            same_as_pre_address: '',
            per_area_type_id: 0,
            per_division_id: 0,
            per_district_id: 0,
            per_upazilla_id: 0,
            per_city_corporation_id: '',
            per_pauroshoba_id: '',
            per_ward_id: '',
            per_union_id: ''
          },
          is_draft: 1,
          status: 1
        }
      }
      // this.formData = Object.assign({}, this.formData, {
      //   fiscal_year_id: this.$store.state.currentFiscalYearId
      // })
    },
    getDesignationByOrg (orgId = null) {
      const desigList = this.$store.state.commonObj.masterDesignationList.filter(item => item.status === 0)
      if (desigList) {
          return desigList.filter(item => item.org_id === parseInt(orgId))
      }
      return desigList
    },
    getAreaTypeData (typeId) {
      this.ItemShow = true
      if (typeId === 1) {
        this.CityCorpItemShow = true
        this.PauroshobaItemShow = false
        this.UnionItemShow = false
      } else if (typeId === 2) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = true
        this.UnionItemShow = false
      } else if (typeId === 3) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = false
        this.UnionItemShow = true
      }
    },
    getAreaTypeData1 (typeId) {
      this.ItemShow1 = true
      if (typeId === 1) {
        this.CityCorpItemShow1 = true
        this.PauroshobaItemShow1 = false
        this.UnionItemShow1 = false
      } else if (typeId === 2) {
        this.CityCorpItemShow1 = false
        this.PauroshobaItemShow1 = true
        this.UnionItemShow1 = false
      } else if (typeId === 3) {
        this.CityCorpItemShow1 = false
        this.PauroshobaItemShow1 = false
        this.UnionItemShow1 = true
      }
    },
    async getFormData () {
      this.isLoading = true
      const params = {
        applied_org_id: this.$store.state.Auth.authUser.org_id,
        id: this.id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, personalInfoTrainerShow, params)
      this.formData = JSON.parse(JSON.stringify(result.data))
      this.formData.not_here_designation = 1
      this.formData.not_here_office = 1
      if (result.data.trainer_status === 3 && result.data.trainer === 1) {
        this.nonMandatory = true
      }
      if (this.formData.address.pre_area_type_id) {
        this.getAreaTypeData(this.formData.address.pre_area_type_id)
      }
      if (this.formData.address.per_area_type_id) {
        this.getAreaTypeData1(this.formData.address.per_area_type_id)
      }
      if (this.formData.address.same_as_pre_address) {
        this.formData.address.same_as_pre_address = String(this.formData.address.same_as_pre_address)
      }
      if (this.formData.nid) {
        this.invalidNid = 0
      }
      this.isLoading = false
    },
    async register () {
      this.isLoading = true
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: true, listReload: false }
      var formData = new FormData(document.getElementById('form'))
      Object.keys(this.formData).map(key => {
      if (key === 'image_file') {
        formData.append('image', this.formData.image_file)
      } else if (key === 'signature_file') {
        formData.append('signature', this.formData.signature_file)
      } else if (key === 'nid_file') {
        formData.append('nid_attachment', this.formData.nid_file)
      } else if (key === 'certificate_file') {
        formData.append('certificate_attachment', this.formData.certificate_file)
      } else if (key === 'expertise_infos') {
        // Handle expertise_infos array here if needed
      } else {
        // Check if the value is not null, 'expertise_infos', empty, or 'null'
        if (this.formData[key] !== null && this.formData[key] !== '' && this.formData[key] !== 'null') {
          formData.append(key, this.formData[key])
        }
      }
    })
      formData.append('trainer', 1)
      if (!this.formData.id) {
        formData.append('trainer_status', 3)
      }
      formData.append('expertCheck', 2)
      formData.append('address', JSON.stringify(this.formData.address))
      formData.append('professional_info', JSON.stringify(this.formData.professional_info))
      // formData.append('expertise_infos', JSON.stringify(this.formData.expertise_infos))
      formData.append('expertise_infos_remove', JSON.stringify(this.expertise_infos_remove))
      // let orgiId = 0
      // if (this.$store.state.Auth.activeRoleId !== 1) {
      //   orgiId = this.$store.state.Auth.authUser.org_id
      // }
      // formData.append('applied_org_id', orgiId)
      if (!this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${personalInfoStore}`, formData, config)
      } else {
        formData.append('_method', 'POST')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${personalInfoUpdate}/${this.formData.id}`, formData, config)
      }
      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/iabm/trainer-registration')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message
          })
        }
      }
      this.isLoading = false
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }

      return unionList
    },
    getOfficeTypeList (orgId = null) {
      let officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        if (orgId === 8) {
          officeTypeList = officeTypeList.filter(office => office.value === 85 || office.value === 101 || office.value === 120)
        }
        return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeTypeList
    },
    getParentOfficeList (officeTypeId = null) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(office => office.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCityCorporationList (districtId) {
      const objectData = this.$store.state.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    getPauroshobaList (upazillaId = null) {
      const objectData = this.$store.state.commonObj.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
      if (upazillaId) {
        return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
      }
    },
    getWardListByCityCorportaion (cityCorpId) {
      const objectData = this.$store.state.commonObj.wardList
      const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getWardListByPauroshoba (pauroshobaId) {
      const objectData = this.$store.state.commonObj.wardList
      const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 0)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getWardListByUnion (unionId) {
      const objectData = this.$store.state.commonObj.wardList
      const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 0)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getUnitList (zoneId = null) {
      const unitList = this.$store.state.agriMarketing.unitList
      if (unitList) {
        return unitList.filter(item => item.zone_id === zoneId && item.status === 1)
      }
      return unitList
    }
  }
}
</script>
