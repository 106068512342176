<template>
  <b-container fluid>
    <Search @LOAD_DATA="getSearchData" :key="componentKey" :menuId="$route.params.id" />
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ checkBn ? menuInfoList.grid_title_bn : menuInfoList.grid_title_en }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-overlay :show="listLoading">
              <b-button class="mr-1" variant="primary" @click="pdfExport">
                <i class="fa fa-print"></i> {{  $t('globalTrans.print') }}
              </b-button>

              <b-button variant="primary">
                <export-excel
                  worksheet="Report Sheet"
                  :title=excelHeaderValue
                  :fields=excelFields
                  :data=excelData
                  :name="`${menuInfoList.grid_title_en}.xls`">
                  {{ $t('globalTrans.export_excel') }}
                </export-excel>
              </b-button>
            </b-overlay>
          </template>
          <template v-slot:body>
            <b-overlay :show="listLoading">
              <b-row>
                  <b-col v-if="menuId">
                      <list-report-head :baseUrl="licenseRegistrationServiceBaseUrl" :url="'/configuration/report-heading/detail'" org-id="1">
                        {{ checkBn ? allData.title_bn : allData.title_en }} </list-report-head>
                  </b-col>
              </b-row>
              <!-- <pre>{{searchHeader.from_date.dynamic_field}}</pre> -->
              <b-row class="mt-3" v-if="searchHeader.from_date.service_id === 17">
                <b-col>
                  <strong>{{ $t('org_pro_division.division') }}: </strong>{{ searchHeader.division_id ? getColumnName($store.state.commonObj.divisionList,'value', searchHeader.division_id) : $t('globalTrans.all') }}
                </b-col>
                <b-col>
                  <strong>{{ $t('org_pro_district.district') }}: </strong>{{ searchHeader.district_id ? getColumnName($store.state.commonObj.districtList,'value', searchHeader.district_id) : $t('globalTrans.all') }}
                </b-col>
                <b-col>
                  <strong>{{ $t('org_pro_upazilla.upazilla') }}: </strong>{{ searchHeader.upazilla_id ? getColumnName($store.state.commonObj.upazilaList,'value', searchHeader.upazilla_id) : $t('globalTrans.all') }}
                </b-col>
              </b-row>
              <b-row class="mt-3" v-if="searchHeader.from_date.service_id === 17">
                <b-col>
                  <strong>{{ $t('li_step.company_type') }}: </strong>{{ searchHeader.company_t_2857 ? getColumnName(companyType,'value', searchHeader.company_t_2857) : $t('globalTrans.all') }}
                </b-col>
                <b-col>
                  <strong>{{ $t('globalTrans.license_no') }}: </strong>{{ searchHeader.license_no ? searchHeader.license_no : $t('globalTrans.all') }}
                </b-col>
                <b-col>
                  <strong>{{ $t('globalTrans.company_name') }}: </strong>{{ searchHeader.company_name ? searchHeader.company_name : $t('globalTrans.all') }}
                </b-col>
              </b-row>
              <b-row class="mt-3" v-if="searchHeader.from_date.service_id === 17">
                <b-col>
                  <strong>{{ $t('globalTrans.from_date') + $t('globalTrans.and ') + $t('globalTrans.to_date') }}: </strong>
                  <span>
                    <slot v-if="searchHeader.from_date.from_date && searchHeader.from_date.to_date">
                    {{ searchHeader.from_date.from_date | dateFormat }} {{ $t('globalTrans.from') }} {{ searchHeader.from_date.to_date | dateFormat }}
                    </slot>
                    <slot v-else>
                      {{ $t('globalTrans.all') }}
                    </slot>
                  </span>
                </b-col>
                <b-col>
                  <strong>{{ $t('li_step.type_of_work') }}: </strong>{{ searchHeader.type_of_w_8863 ? getColumnName(typeOfWork,'value', searchHeader.type_of_w_8863) : $t('globalTrans.all') }}
                </b-col>
                <b-col>
                  <strong>{{ $t('globalTrans.status') }}: </strong>{{ searchHeader.status ? statusFind(searchHeader.status) : $t('globalTrans.all') }}
                </b-col>
              </b-row>
              <br/>
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(application_id)="data">
                      {{ $n(data.item.application_id, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(mobile_no____5100)="data">
                      {{ data.item.mobile_no____5100 | mobileNumber}}
                    </template>
                    <template v-slot:cell(nid_no____6440)="data">
                      {{ $n(data.item.nid_no____6440, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(org_id)="data">
                      {{ getMainStoreData('orgList', data.item.org_id) }}
                    </template>
                    <template v-slot:cell(service_id)="data">
                      {{ getStoreData('licenseRegistration', 'serviceNamesList', data.item.service_id) }}
                    </template>
                    <template v-slot:cell(issue_date)="data">
                      <span v-if="(data.item.issue_date)">{{ data.item.issue_date | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(expire_date)="data">
                      <span v-if="(data.item.expire_date)">{{ data.item.expire_date | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(generate_id)="data">
                      <span v-if="data.item.generate_id !== null">
                        <span v-if="parseInt(data.item.generate_id) !== 0">
                          {{ $n(data.item.generate_id, {useGrouping: false}) }}
                        </span>
                      </span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <span v-if="parseInt(data.item.status) === 10">
                        {{ ServiceLastStepCheck(data.item.service_id, data.item.current_step, data.item.status, data.item.generate_id, data.item.doc_verified, data.item.type) }}
                      </span>
                      <span v-else>
                        {{ getStepName(data.item.current_step, data.item.status) + ' ' + ServiceLastStepCheck(data.item.service_id, data.item.current_step, data.item.status, data.item.generate_id, data.item.doc_verified, data.item.type) }}
                      </span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <!-- Details View -->
                        <button style='margin-left: 4px' type="button" class="btn btn-info btn-sm"  size="sm" @click="detailsData(data.item, data.item.status, 2)"><i :class="getBtn(2).icon"></i></button>
                        <!-- <router-link :to="{ name: getBtn(2).url, params: { stepChange }}" :title="getBtn(2).text" :class="`btn btn-sm`+ getBtn(2).color" size="sm"><span @click="SetStep(data.item, 1)"><i :class="getBtn(2).icon"></i></span></router-link> -->
                        <!-- License View -->
                        <slot v-if="data.item.service_id === 17">
                          <button v-if="data.item.generate_id !== null && data.item.status === 1"  style='margin-left: 4px' type="button" class="btn btn-success btn-sm"  size="sm" @click="detailsData(data.item, 1, 5)"><i :class="getBtn(5).icon"></i></button>
                        </slot>
                        <slot v-else>
                          <button v-if="data.item.generate_id !== null"  style='margin-left: 4px' type="button" class="btn btn-success btn-sm"  size="sm" @click="detailsData(data.item, 1, 5)"><i :class="getBtn(5).icon"></i></button>
                        </slot>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Search from '../../components/searchReport'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { reportDataList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/route-base-list'
import common from '@/mixins/common'
import ListReportHead from '@/components/custom/ListReportHeadLrcpnOn.vue'
import reportPdf from './export_pdf'
import { dateFormat, mobileNumber } from '@/Utils/fliter'
/** Excel */
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  mixins: [ModalBaseMasterList, common],
  components: {
    Search,
    ListReportHead
  },
  data () {
    return {
      listLoading: false,
      componentKey: 0,
      stepChange: {
        application_id: [],
        step_id: 0,
        service_id: 0,
        org_id: 0,
        status: 1
      },
      search: {},
      searchHeader: {
        from_date: '',
        to_date: '',
        status_date: '',
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        type_of_w_8863: 0,
        company_t_2857: 0
      },
      columns: [],
      allData: {},
      menuInfo: [],
      companyType: [
        { value: 'Individual ownership', text: this.$i18n.locale === 'en' ? 'Individual ownership' : 'ব্যক্তি মালিকানা' },
        { value: 'Joint ownership', text: this.$i18n.locale === 'en' ? 'Joint ownership' : 'যৌথ মালিকানা' },
        { value: 'Private', text: this.$i18n.locale === 'en' ? 'Private' : 'প্রাইভেট' },
        { value: 'Government', text: this.$i18n.locale === 'en' ? 'Government' : 'সরকারি' },
        { value: 'Multinational', text: this.$i18n.locale === 'en' ? 'Multinational' : 'বহুজাতিক' },
        { value: 'NGO', text: this.$i18n.locale === 'en' ? 'Individual ownership' : 'এনজিও' }
      ],
      typeOfWork: [
        { value: 'Seed production', text: this.$i18n.locale === 'en' ? 'Seed production' : 'বীজ উৎপাদন' },
        { value: 'Seed Sale', text: this.$i18n.locale === 'en' ? 'Seed Sale' : 'বীজ বিক্রি' },
        { value: 'Seeds Processing and Packaging', text: this.$i18n.locale === 'en' ? 'Seeds Processing and Packaging' : 'বীজ প্রক্রিয়াজাত ও প্যাকেটজাত' },
        { value: 'Seed Import', text: this.$i18n.locale === 'en' ? 'Seed Import' : 'বীজ আমদানি' },
        { value: 'Seed Export', text: this.$i18n.locale === 'en' ? 'Seed Export' : 'বীজ রপ্তানি' },
        { value: 'Seed Research and Development', text: this.$i18n.locale === 'en' ? 'Seed Research and Development' : 'বীজ গবেষণা ও উন্নয়ন' }
      ],
      isAddBtn: false,
      isAppPayment: false,
      gridLangData: '',
      resultData: '',
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      reportHeadData: {
        orgName: '',
        address: ''
      }
    }
  },
  created () {
    this.setCommonGrid()
    this.loadData()
  },
  computed: {
    statusList () {
      return [
        { value: 3, text_en: 'Processing', text_bn: 'প্রক্রিয়াধীন' },
        { value: 1, text_en: 'Approved', text_bn: 'অনুমোদিত' },
        { value: 2, text_en: 'Rejected', text_bn: 'প্রত্যাখ্যাত' },
        { value: 10, text_en: 'License Forfeited', text_bn: 'লাইসেন্স বাজেয়াপ্ত' }
      ]
    },
    excelHeaderValue: function () {
      const headerVal = []
      headerVal[0] = this.$t('globalTrans.government_republic_of_bangladesh')
      if (this.$i18n.locale === 'bn') {
        headerVal[1] = 'কৃষি মন্ত্রণালয়'
        headerVal[2] = 'বিল্ডিং নং ৪, সচিবালয়, আব্দুল গণি রোড, ঢাকা'
        headerVal[3] = 'বীজ ডিলার নিবন্ধন রিপোর্ট'
      } else {
        headerVal[1] = 'Ministry of Agriculture'
        headerVal[2] = 'Building no 4, Secretariat, Abdul Gani Rd, Dhaka'
        headerVal[3] = 'Seed Dealer Registration Report'
      }
      if (this.searchHeader.from_date.service_id === 17) {
        let dateW = this.$t('globalTrans.from_date') + this.$t('globalTrans.and ') + this.$t('globalTrans.to_date') + ' : ' + this.$t('globalTrans.all') + ';'
        if (this.searchHeader.from_date.from_date && this.searchHeader.from_date.to_date) {
          dateW = this.$t('globalTrans.from_date') + this.$t('globalTrans.and ') + this.$t('globalTrans.to_date') + ' : ' + dateFormat(this.searchHeader.from_date.from_date) + ' ' + this.$t('globalTrans.from') + dateFormat(this.searchHeader.from_date.to_date) + ';'
        }
        let statusW = this.$t('globalTrans.status') + ' : ' + this.$t('globalTrans.all') + ';'
        if (this.searchHeader.status) {
          statusW = this.$t('globalTrans.status') + ' : ' + this.statusFind(this.searchHeader.status) + ';'
        }
        let division = this.$t('org_pro_division.division') + ' : ' + this.$t('globalTrans.all') + '; '
        if (this.searchHeader.division_id) {
          division = this.$t('org_pro_division.division') + ' : ' + this.getColumnName(this.$store.state.commonObj.divisionList, 'value', this.searchHeader.division_id) + '; '
        }
        let district = this.$t('org_pro_district.district') + ' : ' + this.$t('globalTrans.all') + '; '
        if (this.searchHeader.district_id) {
          district = this.$t('org_pro_district.district') + ' : ' + this.getColumnName(this.$store.state.commonObj.districtList, 'value', this.searchHeader.district_id) + '; '
        }
        let upazila = this.$t('org_pro_upazilla.upazilla') + ' : ' + this.$t('globalTrans.all') + '; '
        if (this.searchHeader.upazilla_id) {
          upazila = this.$t('org_pro_upazilla.upazilla') + ' : ' + this.getColumnName(this.$store.state.commonObj.upazilaList, 'value', this.searchHeader.upazilla_id) + '; '
        }
        let cmType = this.$t('li_step.company_type') + ' : ' + this.$t('globalTrans.all') + '; '
        if (this.searchHeader.company_t_2857) {
          cmType = this.$t('li_step.company_type') + ' : ' + this.getColumnName(this.companyType, 'value', this.searchHeader.company_t_2857) + '; '
        }
        let typeOfWork = this.$t('li_step.company_type') + ' : ' + this.$t('globalTrans.all') + '; '
        if (this.searchHeader.type_of_w_8863) {
          typeOfWork = this.$t('li_step.company_type') + ' : ' + this.getColumnName(this.typeOfWork, 'value', this.searchHeader.type_of_w_8863) + '; '
        }
        let companyName = this.$t('globalTrans.company_name') + ' : ' + this.$t('globalTrans.all') + '; '
        if (this.searchHeader.company_name) {
          companyName = this.$t('globalTrans.company_name') + ' : ' + this.searchHeader.company_name + '; '
        }
        let licenseName = this.$t('globalTrans.license_no') + ' : ' + this.$t('globalTrans.all') + '; '
        if (this.searchHeader.license_no) {
          licenseName = this.$t('globalTrans.license_no') + ' : ' + this.searchHeader.license_no + '; '
        }
        headerVal[4] = []
        headerVal[5] = division + district + upazila + cmType + typeOfWork
        headerVal[6] = dateW + statusW + companyName + licenseName
        headerVal[7] = []
      }
      return headerVal
    },
    excelFields () {
      if (this.columns.length <= 0) return {}
      const obj = Object.create(null)

      for (let index = 0; index < this.columns.length; index++) {
        const element = this.columns[index]
        if (element === null || element === undefined) continue
        if (element.key === 'action') continue
        obj[element.label] = element.key
      }

      return obj
    },
    excelData: function () {
      if (this.listData.length <= 0) return []
      const excelData = []

      for (let index = 0; index < this.listData.length; index++) {
        const dataItem = this.listData[index]
        const obj = Object.create(null)

        for (const key in this.excelFields) {
          const elementV = this.excelFields[key]
          if (elementV === 'index') {
            obj.index = this.$n(index + 1)
          } else if (elementV === 'application_id') {
            obj[elementV] = this.$n(dataItem[elementV], { useGrouping: false })
          } else if (elementV === 'nid_no____6440') {
            obj[elementV] = this.$n(dataItem[elementV], { useGrouping: false })
          } else if (elementV === 'mobile_no____5100') {
            obj[elementV] = mobileNumber(dataItem[elementV], { useGrouping: false })
          } else if (elementV === 'generate_id') {
            let gentr = ''
            if (dataItem[elementV] !== null) {
              if (dataItem[elementV] !== 0) {
                gentr = this.$n(dataItem[elementV], { useGrouping: false })
              }
            }
            obj[elementV] = gentr
          } else if (elementV === 'expire_date' || elementV === 'issue_date') {
            let dateData = ''
            if (dataItem[elementV]) {
              dateData = dateFormat(dataItem[elementV])
            }
            obj[elementV] = dateData
          } else if (elementV === 'status') {
            if (parseInt(dataItem.status) === 10) {
              obj.status = this.ServiceLastStepCheck(dataItem.service_id, dataItem.current_step, dataItem.status, dataItem.generate_id, dataItem.doc_verified, dataItem.type)
            } else {
              obj.status = this.getStepName(dataItem.current_step, dataItem.status) + ' ' + this.ServiceLastStepCheck(dataItem.service_id, dataItem.current_step, dataItem.status, dataItem.generate_id, dataItem.doc_verified, dataItem.type)
            }
          } else {
            obj[elementV] = dataItem[elementV]
          }
        }

        excelData.push(obj)
      }

      return excelData
    },
    auth () {
      return this.$store.state.Auth
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('step_name.step_name_entry') : this.$t('step_name.step_name') + ' ' + this.$t('globalTrans.modify')
    },
    menuId () {
      return this.$route.params.id
    },
    menuInfoList () {
      const menuId = this.$route.params.id
      return this.$store.state.licenseRegistration.commonObj.reportMenuList.find(item => item.menu_id === parseInt(menuId))
    },
    step () {
      const stepId = this.menuInfoList.step_id
      return this.$store.state.licenseRegistration.commonObj.stepNamesList.find(item => item.value === parseInt(stepId))
    },
    laodList () {
      return this.$store.state.licenseRegistration.commonObj.laodList
    },
    local: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    laodList: function (n, o) {
      if (n !== o) {
        this.loadData()
      }
    },
    menuId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    local: function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.getLanguateWisedata()
          this.componentKey = this.componentKey + 1
      }
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getColumnName (list, field, groupId) {
      const obj = list.find(item => item[field] === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        } else {
          return obj.text_en
        }
      } else {
        return ''
      }
    },
    ServiceLastStepCheck (serviceId, stepId, status, generateId, docVerify, type) {
      // const tmpStepList = this.$store.state.licenseRegistration.commonObj.serviceSteps.filter(item => item.service_id === parseInt(serviceId))
      // const tmpIndVStepList = tmpStepList.find(item => item.step_id === parseInt(stepId))
      // const order = tmpIndVStepList.order
      // const orderCheck = tmpStepList.find(item => item.order === order + 1)
      const actionData = this.actions(serviceId, stepId)
      if (!actionData) {
        return ''
      }
      const actions = JSON.parse(this.actions(serviceId, stepId).actions)
      if (type === 2 && status === 1) {
        return this.$t('globalTrans.completed')
      } else if (status === 0 && !docVerify) {
        return this.$t('globalTrans.processing')
      } else if (status === 2) {
        return this.$t('globalTrans.rejected')
      } else if (status === 7) {
        return this.$t('externalLrcpn.application_resubmit')
      } else if (status === 8) {
        return this.$t('externalLrcpn.transfered_to_nothi')
      } else if (status === 10) {
        return this.$t('li_step.registration_cancel')
      } else if (actions.includes(4)) {
        if (status === 1) {
          return this.$t('globalTrans.completed')
        } else {
          return this.$t('globalTrans.processing')
        }
      } else if (actions.includes(6)) {
        if (docVerify === 1) {
          return this.$t('globalTrans.completed')
        } else {
          return this.$t('globalTrans.processing')
        }
      } else if (actions.includes(12)) {
        if (generateId) {
          return this.$t('globalTrans.completed')
        } else {
          return this.$t('globalTrans.processing')
        }
      } else if (actions.includes(14)) {
        if (generateId && status === 1) {
          return this.$t('globalTrans.completed')
        } else {
          return this.$t('globalTrans.processing')
        }
      } else {
        return this.$t('globalTrans.completed')
      }
    },
    actions (serviceId, stepId) {
      const actions = this.$store.state.licenseRegistration.commonObj.seviceStepWiseButtonList.find(item => item.step_id === stepId && item.service_id === serviceId)
      if (typeof actions === 'undefined') {
        return ''
      } else {
        return actions
      }
    },
    detailsData (item, status, btnId) {
      this.stepChange.application_id[0] = item.application_id
      this.stepChange.step_id = item.current_step
      this.stepChange.service_id = item.service_id
      this.stepChange.org_id = item.org_id
      this.stepChange.status = status
      const urlTo = this.getBtn(btnId).url
      this.$router.push({ name: urlTo, params: { stepChange: this.stepChange } })
    },
    SetStep (item, status) {
      this.stepChange.application_id[0] = item.application_id
      this.stepChange.step_id = item.current_step
      this.stepChange.service_id = item.service_id
      this.stepChange.org_id = item.org_id
      this.stepChange.status = status
    },
    getBtn (value) {
      return this.$store.state.licenseRegistration.commonObj.dynamicButtonList.find(item => item.value === parseInt(value))
    },
    inputReportHead (data) {
      if (data.orgName) {
        this.reportHeadData = data
      } else {
        if (this.checkBn) {
          this.reportHeadData.orgName = 'কৃষি মন্ত্রণালয়'
          this.reportHeadData.address = 'বিল্ডিং নং ৪, সচিবালয়, আব্দুল গণি রোড, ঢাকা'
        } else {
          this.reportHeadData.orgName = 'Ministry of Agriculture'
          this.reportHeadData.address = 'Building no 4, Secretariat, Abdul Gani Rd, Dhaka'
        }
      }
    },
    getLanguateWisedata () {
      this.setGridData(this.gridLangData)
      this.setStatusColumn()
      this.setActionColumn()
    },
    getStepName (stId, status = null) {
      if (status === 10) {
        return ''
      }
      const step = this.$store.state.licenseRegistration.commonObj.stepNamesList.find(item => item.value === stId)
      return step.text
    },
    statusFind (id) {
      const status = this.statusList.find(item => item.value === parseInt(id))
      if (typeof status !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return status.text_bn
        }
        return status.text_en
      }
      return ''
    },
    searchData () {
      this.loadData()
    },
    loadData () {
      const params = Object.assign({}, this.search, {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        service_id: this.menuInfoList.service_id,
        report_process_id: this.menuInfoList.report_process_id,
        is_org_admin: this.$store.state.Auth.authUser.is_org_admin,
        office_id: this.$store.state.Auth.authUser.office_id
      })
      this.searchHeader.from_date = this.search
      this.searchHeader.to_date = this.search.to_date
      this.searchHeader.status = this.search.status
      this.searchHeader.division_id = this.search.division_id
      this.searchHeader.district_id = this.search.district_id
      this.searchHeader.upazilla_id = this.search.upazilla_id
      this.searchHeader.company_t_2857 = this.search.company_t_2857
      this.searchHeader.type_of_w_8863 = this.search.type_of_w_8863
      this.listLoading = true
      RestApi.getData(licenseRegistrationServiceBaseUrl, reportDataList, params).then(response => {
        if (response.success) {
          let result = []
          if (response.data.data.length) {
            this.resultData = response.data.data
            result = this.getRelationData(response.data.data)
          } else {
            result = []
          }
          this.$store.dispatch('setList', result)
          this.paginationData(response.data)
        }
        this.listLoading = false
      })
    },
    getRelationData (data) {
        return data.map(item => {
          let tmpdd = {}
          Object.keys(item).map(key => {
            this.columns.map(element => {
              if ((typeof element !== 'undefined') && (typeof element.layData !== 'undefined')) {
                if (element.layData.field_name === key && element.layData.field_type === 'dropdown') {
                  if (element.layData.dropdownData.component_id === '1') {
                    tmpdd = Object.assign(item, item[key] = this.getCommonStoreData(element.layData.dropdownData.dropdown_name, item[key]))
                  } else {
                    tmpdd = item
                  }
                }
              } else {
                tmpdd = item
              }
            })
          })
          return tmpdd
        })
    },
    getSearchData (res) {
      if (res.type === 2) {
        this.allData = res.data.commonData
        this.search = res.searchData
        if (res.data.success) {
          const dynamicGrid = res.data.grid_field.map(item => {
            const tmpData = JSON.parse(item.data)
            return { text_en: tmpData.label, text_bn: tmpData.label_bn, value: tmpData.field_name, layData: tmpData, langType: typeof tmpData.lang_type !== 'undefined' ? tmpData.lang_type : '' }
          })
          this.gridLangData = dynamicGrid
          this.setGridData(dynamicGrid)
          this.setIssueExpireGrid(this.allData)
          if (this.allData.status) {
            this.setStatusColumn()
          }
          if (this.allData.detail) {
            this.setActionColumn()
          }
        } else {
          this.setCommonGrid()
        }
      } else {
        this.search = res.data
      }
      this.loadData()
    },
    setCommonGrid () {
      this.setSlAction(2)
    },
    setIssueExpireGrid (data) {
      const gridData = JSON.parse(data.grids)
      const itemArray = []
      gridData.forEach(item => {
        if (item === 10000) {
          itemArray.push({ label: this.checkBn ? 'প্রদানের তারিখ' : 'Issue Date', label_en: 'Issue Date', label_bn: 'প্রদানের তারিখ', class: 'text-left', key: 'issue_date' })
        } else if (item === 10001) {
          itemArray.push({ label: this.checkBn ? 'মেয়াদ উত্তীর্ণের তারিখ' : 'Expire Date', label_en: 'Expire Date', label_bn: 'মেয়াদ উত্তীর্ণের তারিখ', class: 'text-left', key: 'expire_date' })
        } else if (item === 10002) {
          itemArray.push({ label: this.checkBn ? 'লাইসেন্স নং' : 'License No', label_en: 'License No', label_bn: 'লাইসেন্স নং', class: 'text-left', key: 'generate_id' })
        }
      })
      this.columns = this.columns.concat(itemArray)
    },
    setGridData (data) {
      const newArray = data.map(item => {
        if (item.value === 'mobile_no____5100' || item.value === 'nid_no____6440') {
          return { label: item.text_bn, label_en: item.text_bn, class: 'text-left', key: item.value, layData: item.layData }
        } else {
          if ((this.local === 'en' && item.langType === 'en') || (this.local === 'en' && item.langType === '')) {
            return { label: item.text_en, label_en: item.text_en, class: 'text-left', key: item.value, layData: item.layData }
          } else if ((this.local === 'bn' && item.langType === 'bn') || (this.local === 'bn' && item.langType === '')) {
            return { label: item.text_bn, label_en: item.text_bn, class: 'text-left', key: item.value, layData: item.layData }
          }
        }
      })
      this.columns = newArray
      this.setSlAction(1)
    },
    setSlAction (type) {
      this.columns = [
        // fsa
        { label: this.checkBn ? 'ক্রমিক নং' : 'SL No', label_en: 'SL No', label_bn: 'ক্রমিক নং', class: 'text-left', key: 'index' },
        { label: this.checkBn ? 'আবেদন আইডি' : 'Application Id', label_en: 'Application Id', label_bn: 'আবেদন আইডি', class: 'text-left', key: 'application_id' }
        ].concat(this.columns)
    },
    setStatusColumn () {
      this.columns = this.columns.concat([
        { label: this.checkBn ? 'অবস্থা' : 'Status', label_en: 'Status', label_bn: 'অবস্থা', class: 'text-left', key: 'status' }
      ])
    },
    setActionColumn () {
      this.columns = this.columns.concat([
        { label: this.checkBn ? 'অ্যাকশন' : 'Action', label_en: 'Action', label_bn: 'অ্যাকশন', class: 'text-left', key: 'action' }
      ])
    },
    getServiceWiseStep (serviceId) {
        const tmpStepList = this.$store.state.licenseRegistration.commonObj.serviceSteps.filter(item => item.service_id === parseInt(serviceId))
        const tmpIndVStepList = tmpStepList.find(item => item.step_id === parseInt(this.step.value))
        const order = tmpIndVStepList.order
        const orderCheck = tmpStepList.find(item => item.order === order + 1)
        if (typeof orderCheck !== 'undefined') {
          return orderCheck.step_id
        }
    },
    pdfExport () {
      const reportTitle = this.checkBn ? this.allData.title_bn : this.allData.title_en
      reportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, this.resultData[0].org_id, reportTitle, this.columns, this.resultData, this)
    }
  }
}
</script>
<style scoped>
  .btn i {
      margin-right: 0px !important;
  }
  .btn {
    margin-left: 2px;
  }
</style>
