import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import ReportHeading from '@/Utils/report-head-lic-o'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, search, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      {
        columns: reportHeadData.reportHeadColumn
      },
      { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
      { text: reportHeadData.address, style: 'address', alignment: 'center' },
      { text: reportTitle, style: 'hhs', alignment: 'center' }
    ]
      let dateW = vm.$t('globalTrans.all')
      if (vm.searchHeader.from_date) {
        dateW = dateFormat(vm.searchHeader.from_date)
      }
      let dateWe = vm.$t('globalTrans.all')
      if (vm.searchHeader.to_date) {
        dateWe = vm.$t('globalTrans.to_date') + ' ' + dateFormat(vm.searchHeader.to_date)
      }
      const allRowsHead = []
      const rowItem = [
        { text: vm.$t('org_pro_division.division'), style: 'td', alignment: 'left' },
        { text: ':', style: 'td', alignment: 'left' },
        { text: vm.search.division_id ? vm.getColumnName(Store.state.commonObj.divisionList, vm.searchHeader.division_id) : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' },
        { text: vm.$t('org_pro_district.district'), style: 'td', alignment: 'left' },
        { text: ':', style: 'td', alignment: 'left' },
        { text: vm.search.district_id ? vm.getColumnName(Store.state.commonObj.districtList, vm.searchHeader.district_id) : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' },
        { text: vm.$t('org_pro_upazilla.upazilla'), style: 'td', alignment: 'left' },
        { text: ':', style: 'td', alignment: 'left' },
        { text: vm.search.upazilla_id ? vm.getColumnName(Store.state.commonObj.upazilaList, vm.searchHeader.upazilla_id) : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' }
      ]
      allRowsHead.push(rowItem)
      const rowItem2 = [
        { text: vm.$t('globalTrans.type'), style: 'td', alignment: 'left' },
        { text: ':', style: 'td', alignment: 'left' },
        { text: vm.search.type ? vm.getColumnName(vm.dataType, vm.searchHeader.type) : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' },
        { text: vm.$t('globalTrans.status'), style: 'td', alignment: 'left' },
        { text: ':', style: 'td', alignment: 'left' },
        { text: vm.search.status ? vm.getColumnName(vm.statusList, vm.searchHeader.status) : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' },
        { text: vm.$t('globalTrans.from_date'), style: 'td', alignment: 'left' },
        { text: ':', style: 'td', alignment: 'left' },
        { text: dateW, style: 'td', alignment: 'left' }
      ]
      allRowsHead.push(rowItem2)
      const rowItem3 = [
        { text: vm.$t('globalTrans.to_date'), style: 'td', alignment: 'left' },
        { text: ':', style: 'td', alignment: 'left' },
        { text: dateWe, style: 'td', alignment: 'left' },
        { text: vm.$t('globalTrans.application_id'), style: 'td', alignment: 'left' },
        { text: ':', style: 'td', alignment: 'left' },
        { text: vm.search.application_id ? vm.searchHeader.application_id : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' },
        { text: vm.$t('grantDistribution.organization_name'), style: 'td', alignment: 'left' },
        { text: ':', style: 'td', alignment: 'left' },
        { text: vm.search.organization_name ? vm.searchHeader.organization_name : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' }
      ]
      allRowsHead.push(rowItem3)
      const rowItem4 = [
        { text: vm.$t('globalTrans.mobile'), style: 'td', alignment: 'left' },
        { text: ':', style: 'td', alignment: 'left' },
        { text: vm.search.mobile_no ? vm.searchHeader.mobile_no : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' },
        {},
        {},
        {},
        {},
        {},
        {}
      ]
      allRowsHead.push(rowItem4)
      pdfContent.push({
        table: {
          widths: ['11%', '1%', '15%', '11%', '1%', '15%', '11%', '1%', '15%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })
      pdfContent.push({ text: '', style: 'fertilizer' })
      // option
      let allRows = []
      allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'td', alignment: 'center' },
          { text: vm.$t('boro_import.institute_name_addr'), style: 'td', alignment: 'center' },
          { text: vm.$t('li_step.warehouse_address'), style: 'td', alignment: 'center' },
          { text: vm.$t('li_step.fertilizer_name'), style: 'td', alignment: 'center' },
          { text: vm.$t('li_step.bin_no'), style: 'td', alignment: 'center' },
          { text: vm.$t('externalLrcpn.app_id'), style: 'td', alignment: 'center' },
          { text: vm.$t('globalTrans.applicants_name'), style: 'td', alignment: 'center' },
          { text: vm.$t('externalLrcpn.issue_and_expire_date'), style: 'td', alignment: 'center' },
          { text: vm.$t('externalLrcpn.type'), style: 'td', alignment: 'center' },
          { text: vm.$t('globalTrans.status'), style: 'td', alignment: 'center' }
        ]
      ]
      vm.listData.filter((data, optionIndex) => {
        const orgName = vm.$i18n.locale === 'bn' ? data.org_name_bn + '\n' + data.buniness_bn : data.org_name + '\n' + data.buniness_en
        const wareAddress = vm.$i18n.locale === 'bn' ? data.warehouse_bn : data.warehouse_en
        const fertilizer = vm.getColumnNameField(Store.state.licenseRegistration.commonObj.fertilizerName, data.fertilizer_name_id, 'chemical_element')
        const rowItem = [
          { text: vm.$n(optionIndex + 1), style: 'td', alignment: 'center' },
          { text: orgName, style: 'td', alignment: 'center' },
          { text: wareAddress, style: 'td', alignment: 'center' },
          { text: fertilizer, style: 'td', alignment: 'center' },
          { text: data.file_no, style: 'td', alignment: 'center', fontSize: 10 },
          { text: data.application_id, style: 'td', alignment: 'center', fontSize: 10 },
          { text: vm.$i18n.locale === 'bn' ? data.applicant__84 : data.applicant__11, style: 'td', alignment: 'center' },
          // { text: vm.getDistrict(data.district_8192), style: 'td', alignment: 'center' },
          // { text: vm.getUpazila(data.upazilla_4142), style: 'td', alignment: 'center' },
          { text: data.issue_date ? dateFormat(data.issue_date) + '\n' + dateFormat(data.expired_date) : '', style: 'td', alignment: 'center' },
          { text: vm.getColumnName(vm.dataType, data.type), style: 'td', alignment: 'center' },
          { text: vm.getColumnName(vm.statusList, data.status), style: 'td', alignment: 'center' }
        ]
        allRows.push(rowItem)
      })
      pdfContent.push({ text: '', style: 'fertilizer' })
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['7%', '12%', '12%', '8%', '8%', '8%', '9%', '11%', '9%', '9%'],
          body: allRows
        }
      })
      pdfContent.push({ text: '', style: 'fertilizer' })
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        styles: {
          th: {
            fontSize: 12,
            bold: true,
            margin: [1, 1, 1, 1]
          },
          td: {
            fontSize: 11,
            margin: [1, 1, 1, 1]
          },
          header: {
              fontSize: 14,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 5, 25, 0]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          hhs: {
            fontSize: 13,
            bold: true,
            margin: [10, 5, 25, 5]
          },
          krishi: {
            margin: [0, 10, 0, 15],
            alignment: 'center'
          }
        }
      }
    pdfMake.createPdf(docDefinition, null, null, null).download('dae-report')
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
