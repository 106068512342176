import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning-portrait'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm, search, type = 1) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
        },
        { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
        { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
        { text: reportHeadData.address, style: 'address', alignment: 'center' },
        { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
        const allRowsHead = []
        if (search.fiscal_year_id) {
          const rowItem = [
            { text: vm.$t('elearning_config.fiscal_year'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? vm.searchHeaderData.fiscal_year_bn : vm.searchHeaderData.fiscal_year, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.training_title) {
          const rowItem = [
            { text: vm.$t('elearning_config.training_title'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_title_bn : search.training_title, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.circular_memo_no) {
          const rowItem = [
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: search.circular_memo_no, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.batch_no) {
          const rowItem = [
            { text: vm.$t('elearning_iabm.batch_no'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? vm.searchHeaderData.batch_bn : vm.searchHeaderData.batch_en, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (vm.searchEvaData) {
          const rowItem = [
            { text: vm.$t('elearning_tim.evaluation_date'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(vm.searchEvaData.exam_date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (vm.searchEvaData) {
          const rowItem = [
            { text: vm.$t('elearning_tim.pre_evaluation_marks'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$n(vm.searchEvaData.pre_evaluation_marks), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (vm.searchEvaData) {
          const rowItem = [
            { text: vm.$t('elearning_tim.post_evaluation_marks'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$n(vm.searchEvaData.post_evaluation_marks), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (vm.searchEvaData) {
          const rowItem = [
            { text: vm.$t('elearning_iabm.training_start_dates'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(vm.training_start_date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (vm.searchEvaData) {
          const rowItem = [
            { text: vm.$t('elearning_iabm.training_end_dates'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(vm.training_end_date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        // if (search.org_id) {
        //   const rowItem = [
        //     { text: vm.$t('globalTrans.organization'), style: 'td', alignment: 'left' },
        //     { text: ':', style: 'td', alignment: 'center' },
        //     { text: (i18n.locale === 'bn') ? vm.searchHeaderData.org_bn : vm.searchHeaderData.org_en, style: 'td', alignment: 'left' }
        //   ]
        //   allRowsHead.push(rowItem)
        // }
        if (allRowsHead.length > 0) {
          pdfContent.push({
            table: {
              widths: ['20%', '1%', '79%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tim.trainee'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tim.posting_office'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tim.pre_evaluation_mark'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tim.post_evaluation_mark'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tim.merit_position'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        data.forEach((info, index) => {
          let designation
          let office
          if (info.designation_id && parseInt(info.not_here_designation) === 0 && parseInt(info.profession_type) === 1) {
            designation = vm.getDesignation(info.designation_id)
          } else {
            designation = (vm.$i18n.locale === 'bn') ? info.designation_bn : info.designation_en
          }
          if (parseInt(info.profession_type) === 1 && parseInt(info.not_here_office) === 0) {
            office = vm.getOfficeName(info.professional_office_id) + ' , ' + vm.getOrgName(info.professional_org_id)
          }
          if (parseInt(info.profession_type) === 1 && parseInt(info.not_here_office) === 1) {
            office = (vm.$i18n.locale === 'bn') ? info.office_name_bn : info.office_name
          }
          if (parseInt(info.profession_type) !== 1) {
            office = (vm.$i18n.locale === 'bn') ? info.office_name_bn : info.office_name
          }
          allRows.push([
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.name_bn : info.name, alignment: 'center', style: 'td' },
            { text: designation, alignment: 'center', style: 'td' },
            { text: office, alignment: 'center', style: 'td' },
            { text: vm.$n(info.pre_evaluation_atten_marks), alignment: 'center', style: 'td' },
            { text: vm.$n(info.post_evaluation_atten_marks), alignment: 'center', style: 'td' },
            { text: info.post_evaluation_atten_marks > 0 ? ((vm.$i18n.locale === 'bn') ? vm.getOrdinalBangla(index + 1) : vm.getOrdinalEnglish(index + 1)) : '', alignment: 'center', style: 'td' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['8%', '16%', '17%', '17%', '14%', '14%', '14%'],
            body: allRows
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        if (i18n.locale === 'en') {
          pdfContent.push({
            text: [
                vm.$t('tpm_report.report_footer1') + ' ',
                { text: vm.$t('tpm_report.idsdp'), bold: true },
                ' ' + vm.$t('tpm_report.report_footer2')
            ],
            style: 'td',
            alignment: 'center'
          })
        }
        if (i18n.locale === 'bn') {
          pdfContent.push({
            text: [
                { text: vm.$t('tpm_report.idsdp'), bold: true },
                ' ' + vm.$t('tpm_report.report_footer1') + ' ' + vm.$t('tpm_report.report_footer2')
            ],
            style: 'td',
            alignment: 'center'
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'portrait',
        footer: function (currentPage, pageCount) {
          return [
            {
              columns: [
                {
                  text: vm.$t('tpm_report.page') + ' ' + vm.$n(currentPage.toString()) + ' / ' + vm.$n(pageCount),
                  alignment: 'left',
                  style: 'footerStyle'
                },
                {
                  text: vm.$t('tpm_report.idsdp'),
                  alignment: 'center',
                  style: 'footerStyle'
                },
                {
                  text: vm.$t('tpm_report.footer_date_time') + ' ' + (vm.$i18n.locale === 'bn' ? new Date().toLocaleDateString('bn-BD') : new Date().toLocaleDateString('en-US')) + ', ' + (vm.$i18n.locale === 'bn' ? new Date().toLocaleTimeString('bn-BD', { hour12: false }) : new Date().toLocaleTimeString('en-US', { hour12: false })),
                  alignment: 'right',
                  style: 'footerStyle'
                }
              ],
              margin: [10, 5] // Adjust the margin as needed
            }
          ]
        },
        styles: {
          footerStyle: {
            fontSize: 8,
            margin: [20, 5, 20, 5]
          },
          th: {
            fontSize: (i18n.locale === 'bn') ? 9 : 8,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 9 : 8,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 11,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      if (type === 1) {
        pdfMake.createPdf(docDefinition, null, null, null).print()
      } else {
        pdfMake.createPdf(docDefinition, null, null, null).download('pre-post-evaluation-report')
      }
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
