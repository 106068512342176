<template>
    <b-container fluid>
        <div>
            <!-- <button class="btn btn-success btn-sm mb-2" @click="printLicense()"><i class="ri-printer-line mr-0"></i>
                {{ $t('globalTrans.print') }}
            </button>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h4 class="">{{ $i18n.locale === 'bn' ? 'তফসিল-২' : 'Schedule-2' }}</h4>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                    <h5 class="">{{ $i18n.locale === 'bn' ? 'ফরম-ক' : 'Form-A' }}</h5>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h6> {{ $i18n.locale === 'bn' ? '[বিধি ৪ (১) দ্রষ্টব্য]' : '[Rules 4(1)]' }}</h6>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h6 class="customBorder"> {{ $i18n.locale === 'bn' ? 'সার আমদানি নিবন্ধনের আবেদনপত্র' : 'Application for Fertilizer import registration' }}</h6>
            </div> -->
            <!-- <pre>
                {{ badcStaticData[2] }}
            </pre> -->
            <b-row>
              <b-col md="12" class="table-responsive" v-if="isLoad">
                <b-overlay :show="loading">
                    <table class="table" border="1">
                        <tr>
                            <th style="width: 30%;" class="text-center">{{ $t('globalTrans.name') }}</th>
                            <th style="width: 10%">{{ $t('globalTrans.attachment') }}</th>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_1') }} </td>
                            <td>
                                    <a target="_blank" v-if="getFile(badcStaticData[1].image_73) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].image_73)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_2') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].rent_agree_5997) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].rent_agree_5997)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_3') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].environmen_79) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].environmen_79)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_4') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].lab_inform_1997) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].lab_inform_1997)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_5') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].manpower_r_7591) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].manpower_r_7591)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_6') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].descriptio_80) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].descriptio_80)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_7') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].the_detail_8033) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].the_detail_8033)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_8') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].nid_8030) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].nid_8030)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_9') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].uptodate_t_3373) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].uptodate_t_3373)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_10') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].vat_regist_5439) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].vat_regist_5439)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_11') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].updated_in_7031) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].updated_in_7031)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_12') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].updated_bf_2888) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].updated_bf_2888)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_13') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].uptodate_b_3755) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].uptodate_b_3755)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_14') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].registrati_7107) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].registrati_7107)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_15') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].informatio_3197) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].informatio_3197)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_16') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].ministry_n_9860) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].ministry_n_9860)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_17') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].undertakin_5815) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].undertakin_5815)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_18') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].office_war_9306) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].office_war_9306)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_19') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].label__sam_3672) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].label__sam_3672)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_20') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].memorandum_9173) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].memorandum_9173)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_24') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].packet_pre_8303) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].packet_pre_8303)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_21') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1]._year_perf_8133) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1]._year_perf_8133)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_22') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].old_regist_7465) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].old_regist_7465)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('li_step.dae_prod_23') }} </td>
                            <td>
                                <a target="_blank" v-if="getFile(badcStaticData[1].others_6605) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].others_6605)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                            </td>
                        </tr>
                    </table>
                </b-overlay>
              </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
// import Receipt from '@/modules/license-registration-service/license-step/pages/steps/Receipt'
// import ReceiptOffline from '@/modules/license-registration-service/license-step/pages/steps/ReceiptOffline'

export default {
  props: ['tabData', 'badcStaticData', 'history', 'users', 'stepData'],
//   components: {
//     Receipt, ReceiptOffline
//   },
  data () {
    return {
        lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
        isLoad: false,
        datas: {},
        generalInfoShow: true,
        attachmentShow: false,
        labelDesignShow: false,
        reportLoading: false,
        paymentRecipt: false,
        reportList: [],
        importData: [
          { id: 1, file: 'image_73', yes_no: 1, remarks: null },
          { id: 2, file: 'rent_agree_5997', yes_no: 0, remarks: null },
          { id: 3, file: 'environmen_79', yes_no: 0, remarks: null },
          { id: 4, file: 'lab_inform_1997', yes_no: 0, remarks: null },
          { id: 5, file: 'manpower_r_7591', yes_no: 0, remarks: null },
          { id: 6, file: 'descriptio_80', yes_no: 0, remarks: null },
          { id: 7, file: 'the_detail_8033', yes_no: 0, remarks: null },
          { id: 8, file: 'nid_8030', yes_no: 0, remarks: null },
          { id: 9, file: 'uptodate_t_3373', yes_no: 0, remarks: null },
          { id: 10, file: 'vat_regist_5439', yes_no: 0, remarks: null },
          { id: 11, file: 'updated_in_7031', yes_no: 0, remarks: null },
          { id: 12, file: 'updated_bf_2888', yes_no: 0, remarks: null },
          { id: 13, file: 'uptodate_b_3755', yes_no: 0, remarks: null },
          { id: 14, file: 'registrati_7107', yes_no: 0, remarks: null },
          { id: 15, file: 'informatio_3197', yes_no: 0, remarks: null },
          { id: 16, file: 'ministry_n_9860', yes_no: 0, remarks: null },
          { id: 17, file: 'undertakin_5815', yes_no: 0, remarks: null },
          { id: 18, file: 'office_war_9306', yes_no: 0, remarks: null },
          { id: 19, file: 'label__sam_3672', yes_no: 0, remarks: null },
          { id: 20, file: 'memorandum_9173', yes_no: 0, remarks: null },
          { id: 21, file: '_year_perf_8133', yes_no: 0, remarks: null },
          { id: 22, file: 'old_regist_7465', yes_no: 0, remarks: null },
          { id: 23, file: 'others_6605', yes_no: 0, remarks: null }
        ],
        daeList: []
    }
  },
  created () {
    this.daeList = this.import
    this.loadData()
  },
  computed: {
    local: function () {
        return this.$i18n.locale
    },
    ingridentList: function () {
      return this.$store.state.licenseRegistration.commonObj.activeIngredientList.filter(item => item.status === 1)
    },
    labList: function () {
       return this.$store.state.licenseRegistration.commonObj.labList.filter(item => item.status === 1)
    }
  },
  methods: {
    getCountryManufactureName (id) {
        const obj = this.$store.state.licenseRegistration.commonObj.countryList.find(el => el.value === parseInt(id))
        if (typeof obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
    },
    getCompanyName (id) {
        const obj = this.$store.state.licenseRegistration.commonObj.foreignEnlishmentList.find(el => el.value === parseInt(id))
        if (typeof obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
    },
    checkFileFunction (field) {
      return field
    },
    getFile (value) {
      if (value === '') {
         return false
      }
      if (value) {
        const fileData = JSON.parse(value)
        if (fileData[0]) {
          return fileData[0]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    loadData () {
        const search = {
            service_id: this.badcStaticData[0].service_id,
            application_id: this.badcStaticData[0].application_id
        }
        const params = Object.assign({}, search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(licenseRegistrationServiceBaseUrl, '/step/dae-attachment/search', params).then(response => {
            if (response.success) {
                this.datas = response.data
                this.isLoad = true
            } else {
                this.isLoad = true
            }
        })
    },
    tabChanged () {
        this.attachmentShow = false
        this.labelDesignShow = false
        this.generalInfoShow = false
    },
    tabChange (val) {
        if (val === 0) {
            this.attachmentShow = false
            this.labelDesignShow = false
            this.generalInfoShow = true
        }
        if (val === 1) {
            this.labelDesignShow = false
            this.generalInfoShow = false
            this.attachmentShow = true
        }
        if (val === 2) {
            this.generalInfoShow = false
            this.attachmentShow = false
            this.labelDesignShow = true
        }
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    async getreportData () {
        this.generalInfoShow = false
        this.attachmentShow = false
        this.labelDesignShow = false
        this.reportLoading = true
        const applicationID = parseInt(this.badcStaticData[0].application_id)
        await RestApi.getData(licenseRegistrationServiceBaseUrl, `${'/configuration/dae-lab-assign/lab-assign'}/${applicationID}`).then(response => {
            if (response.success) {
                if (response.data.length > 0) {
                    this.reportList = response.data[0].lab_details
                }
            }
            this.reportLoading = false
        })
    },
    getLabName (labId) {
      const lab = this.labList.find(item => item.value === labId)
      if (this.$i18n.locale === 'en') {
        return lab.text_en
      } else {
        return lab.text_bn
      }
    },
    getIngredientName (ingredientId) {
      const ingredient = this.ingridentList.find(item => item.value === ingredientId)
      if (this.$i18n.locale === 'en') {
        return ingredient.text_en
      } else {
        return ingredient.text_bn
      }
    },
    getUserName (userId) {
      const user = this.users.find(user => user.value === userId)
      return user !== undefined ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
    },
    printLicense () {
        const search = {
            id: 9,
            service_id: parseInt(this.badcStaticData[0].service_id),
            step_id: parseInt(this.stepData.step_id),
            applicationID: parseInt(this.badcStaticData[0].application_id),
            org_id: parseInt(this.badcStaticData[0].org_id),
            type: 'pdf',
            local: this.$i18n.locale
        }
        const params = Object.assign({}, search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(licenseRegistrationServiceBaseUrl, '/step/step-from-data', params).then(response => {
            if (response.success) {
                this.datas = response.data
                this.isLoad = true
            } else {
                this.isLoad = true
            }
        })
    }
  }
}

</script>
