<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                      <b-col lg="12" sm="12">
                          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                          <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                          <b-row>
                            <b-col lg="6" md="6" sm="12" xs="12" v-if="$store.state.Auth.activeRoleId === 1">
                              <ValidationProvider  name="Organization" vid="org_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="6"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                                    </template>
                                    <!-- <b-form-select
                                    plain
                                    v-model="trainingTitleData.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                    <v-select
                                      id="org_id"
                                      v-model="trainingTitleData.org_id"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" md="6" sm="12" xs="12">
                              <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="6"
                                  label-for="training_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                  plain
                                  v-model="trainingTitleData.training_type_id"
                                  :options="trainingTypeList"
                                  id="training_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                  id="org_id"
                                  v-model="trainingTitleData.training_type_id"
                                  :reduce="op => op.value"
                                  :options="trainingTypeList"
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                  label="text"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" md="6" sm="12" xs="12">
                              <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="6"
                                  label-for="training_category_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                  plain
                                  v-model="trainingTitleData.training_category_id"
                                  :options="trainingCategory"
                                  id="training_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                  id="org_id"
                                  v-model="trainingTitleData.training_category_id"
                                  :reduce="op => op.value"
                                  :options="trainingCategory"
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                  label="text"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" md="6" sm="12" xs="12">
                              <ValidationProvider name="Training Title (En)" vid="training_title_en" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="6"
                                  label-for="training_title_en"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.training_title_en')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="training_title_en"
                                  v-model="trainingTitleData.training_title_en"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" md="6" sm="12" xs="12">
                              <ValidationProvider name="Training Title (Bn)" vid="training_title_bn" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="6"
                                  label-for="training_title_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.training_title_bn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="training_title_bn"
                                  v-model="trainingTitleData.training_title_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" md="6" sm="12" xs="12">
                              <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="6"
                                      label-for="remarks"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{$t('elearning_config.remarks_en')}}
                                      </template>
                                      <b-form-textarea
                                          rows="2"
                                          id="remarks"
                                          v-model="trainingTitleData.remarks"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-textarea>
                                      <div class="invalid-feedback">
                                          {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" md="6" sm="12" xs="12">
                              <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="6"
                                      label-for="remarks_bn"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{$t('elearning_config.remarks_bn')}}
                                      </template>
                                      <b-form-textarea
                                          rows="2"
                                          id="remarks_bn"
                                          v-model="trainingTitleData.remarks_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-textarea>
                                      <div class="invalid-feedback">
                                          {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" md="6" sm="12" xs="12">
                              <ValidationProvider name="Person Type" vid="type" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="6"
                                  label-for="training_status"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.person_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="trainingTitleData.training_status"
                                  :options="statusList"
                                  id="training_status"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="trainingTypeDisable"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <b-overlay>
                            <template>
                              <div style="font-size:14px; background-color: #66CC99; padding:6px" class="mt-4">
                                  <h5 class="text-white text-center"> {{ $t('elearning_config.training_mat') }}</h5>
                              </div>
                            </template>
                            <b-row v-for="(info, index) in trainingTitleData.material_details" :key="index" class="mt-3">
                              <b-col lg="3" md="3" sm="12" xs="12">
                                <ValidationProvider name="Name" vid="`name$(index)`">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        label-for="`name$(index)`"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('globalTrans.name') }}<span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="name"
                                        v-model="info.name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="3" md="3" sm="12" xs="12">
                                <ValidationProvider name="Name BN" vid="`name_bn$(index)`">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        label-for="`name_bn$(index)`"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('globalTrans.name_bn') }}<span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="name_bn"
                                        v-model="info.name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="3" md="3" sm="12" xs="12">
                                <ValidationProvider name="Quantity" vid="`quantity$(index)`">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        label-for="`quantity$(index)`"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('globalTrans.quantity') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="name"
                                        v-model="info.quantity"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col xl="3" lg="3" md="3" sm="12" style="margin-top:5%">
                                <b-button v-show="index == trainingTitleData.material_details.length-1" variant=" iq-bg-success" size="sm" @click="addMat()"><i class="ri-add-line m-0"></i>{{$t('dae_config.add_more')}}</b-button>
                                <b-button v-show="index || ( !index && trainingTitleData.material_details.length > 1)" variant=" iq-bg-danger" size="sm" @click="removeMat(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                              </b-col>
                            </b-row>
                          </b-overlay>
                              <div class="row">
                              <div class="col-sm-3"></div>
                              <div class="col text-right">
                                  <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                  &nbsp;
                                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                              </div>
                              </div>
                          </b-form>
                          </ValidationObserver>
                      </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingTitleStore, trainingTitleUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.trainingTitleData.org_id = this.$store.state.Auth.authUser.org_id
    }
    if (this.id) {
      const tmp = this.gettrainingTitleData()
       if (tmp.material_details.length === 0) {
        tmp.material_details = [
          {
            name: '',
            name_bn: '',
            quantity: ''
          }
        ]
      }
      this.trainingTitleData = tmp
    }
    if (this.$store.state.Auth.authUser.org_id === 16) {
        this.trainingTitleData.training_status = 2
        this.trainingTypeDisable = true
    }
    if (parseInt(this.$store.state.Auth.authUser.org_id) === 3) {
      this.statusList = [
        { value: 1, text: this.$t('globalTrans.scientists_officials_and_employees') }
      ]
    } else if (parseInt(this.$store.state.Auth.authUser.org_id) === 4) {
      this.statusList = [
        { value: 1, text: this.$t('globalTrans.scientists_officials_and_employees') },
        { value: 2, text: this.$t('globalTrans.other') }
      ]
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      trainingTypeDisable: false,
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      trainingTitleData: {
        id: '',
        org_id: '',
        training_category_id: '',
        training_type_id: '',
        training_title_en: '',
        training_title_bn: '',
        remarks: '',
        remarks_bn: '',
        training_status: 0,
        material_details: [
          {
            name: '',
            name_bn: '',
            quantity: ''
          }
        ]
      },
      trainingCategory: [],
      trainingTypeList: [],
      statusList: [
        { value: 1, text: this.$t('warehouseFarmer.officer') },
        { value: 2, text: this.$t('globalTrans.other') }
      ],
      trainingMat: false,
      myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
  watch: {
    'trainingTitleData.org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
    },
    'trainingTitleData.training_type_id': function (newValue) {
        this.trainingCategory = this.categoryList(newValue)
    }
  },
  methods: {
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === orgId)
      }
      return type
    },
    gettrainingTitleData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    categoryList (typeId) {
       const category = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return category.filter(item => item.training_type_id === typeId)
       }
       return category
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.trainingTitleData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${trainingTitleUpdate}/${this.id}`, this.trainingTitleData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingTitleStore, this.trainingTitleData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = true
    },
    addMat () {
      const tampbpSetup = {
        name: '',
        name_bn: '',
        quantity: ''
      }
      const key1 = parseInt(this.trainingTitleData.material_details.length - 1)
      const item = this.trainingTitleData.material_details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
          this.trainingTitleData.material_details.push(tampbpSetup)
      }
    },
    removeMat (key) {
      this.trainingTitleData.material_details.splice(key, 1)
    }
  }
}
</script>
