<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col lg="10" sm="12" offset-lg="1">
          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
              <ValidationProvider name="Note" vid="note" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="note"
                      slot-scope="{ valid, errors }"
                  >
                      <template v-slot:label>
                          {{ $t('budget.note') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-textarea
                      plain
                      v-model="form.note"
                      id="note"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-textarea>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                      </div>
                  </b-form-group>
              </ValidationProvider>
              <div class="row">
                <div class="col-8 offset-4" sm="12">
                  <div class="text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-29')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { dynamicFormAppRej } from '../../api/routes'

export default {
  props: ['pDatas'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      previousRouteName: '',
      previousRouteParams: '',
      saveBtnName: this.$t('globalTrans.save'),
      form: {
        step_id: this.pDatas.step_id,
        service_id: this.pDatas.service_id,
        application_id: this.pDatas.application_id,
        status: 10,
        note: '',
        next_step_id: '',
        note_id: 0,
        attachment: ''
      }
    }
  },
  created () {
    this.form.next_step_id = this.getServiceWiseStep(this.stepChange.service_id)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.previousRouteName = from.name
      vm.previousRouteParams = from.params || {}
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.previousRouteName = from.name
    this.previousRouteParams = from.params || {}
    next()
  },
  watch: {
    'form.note_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setNote(newVal)
      }
    }
  },
  computed: {
    noteList: function () {
      return this.$store.state.licenseRegistration.commonObj.orgNotesList.filter(item => item.status === 1)
    }
  },
  methods: {
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      result = await RestApi.postData(licenseRegistrationServiceBaseUrl, dynamicFormAppRej, this.form)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false

      if (result.success) {
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$store.dispatch('licenseRegistration/setLaodList', true)
          this.$bvModal.hide('modal-29')
      } else {
          this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: result.message
          })
          this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
