<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('tpm_report.pre_post_compare_evaluation_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
            <b-row>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                  <b-row class="ml-2 mr-2">
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="fiscal_year_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                            </template>
                            <v-select name="organization"
                              v-model="search.fiscal_year_id"
                              label="text"
                              :reduce="item => item.value"
                              :options= fiscalYearList
                              :placeholder="$t('globalTrans.select')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            />
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="org_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="search.org_id"
                            :options="orgList"
                            :disabled="!$store.state.Auth.activeRoleId || $store.state.Auth.activeRoleId !== 1"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="training_type_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_type_id"
                            :options="trainingTypeList"
                            id="training_type_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Training Category"  vid="training_category_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="training_category_id"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Training Title"  vid="training_title_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="training_title_id"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="search.training_title_id"
                              :options="trainingTitleList"
                              id="training_title_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Batch No"  vid="batch_no" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="batch_no"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="search.batch_no"
                              :options="batchList"
                              id="batch_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option value=0>{{ circularLoading ? 'Loading....' : $t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Circular Memo No"  vid="circular_memo_no" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="circular_memo_no"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="search.circular_memo_no"
                              :options="circularList"
                              id="circular_memo_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-button size="sm" type="submit" variant="primary">
                        <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                      </b-button>
                    </b-col>
                  </b-row>
                  <!-- <div class="row">
                    <b-col sm="9">
                    </b-col>
                    <b-col sm="3" style="text-align: right;">
                      <b-button size="sm" type="submit" variant="primary" class="mt-20">
                        <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                      </b-button>
                    </b-col>
                  </div> -->
                </b-form>
              </ValidationObserver>
            </b-row>
          </template>
        </iq-card>
        <b-row>
          <b-col md="12">
            <b-overlay :show="loading">
              <b-row v-if='datas.length > 0'>
                <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('tpm_report.pre_post_compare_evaluation_report') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button class="btn_add_new" @click="pdfExport">
                        <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                      </b-button>
                      <b-button class="btn_add_new ml-1" @click="pdfDownload">
                        <i class="fas fa-print"></i>{{ $t('globalTrans.download') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay>
                        <b-row mt-5>
                          <b-col md="12" class="table-responsive">
                            <div style="border: 2px solid;margin:10px;padding:10px">
                              <b-row>
                                <b-col>
                                  <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                    <template v-slot:projectNameSlot>
                                      {{ }}
                                    </template>
                                    {{ $t('tpm_report.pre_post_compare_evaluation_report') }}
                                  </list-report-head>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <div class="text-center">
                                    <table style="width:100%;color:black;" class="ml-3">
                                      <tr>
                                        <td align="left" style="width:23%">{{ $t('elearning_config.fiscal_year') }}</td>
                                        <td align="center" style="width:2%">:</td>
                                        <td align="left" style="width:75%" colspan="4" v-if="search.fiscal_year_id"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.fiscal_year_bn : searchHeaderData.fiscal_year_en }}</b></td>
                                      </tr>
                                      <tr>
                                        <td align="left">{{ $t('elearning_config.training_title') }}</td>
                                        <td align="center">:</td>
                                        <td align="left" v-if="search.training_title_id" colspan="4"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.training_title_bn : searchHeaderData.training_title_en }}</b></td>
                                      </tr>
                                      <tr v-if="searchEvaData">
                                        <td align="left">{{ $t('elearning_tim.evaluation_date') }}</td>
                                        <td align="center">:</td>
                                        <td align="left"><b>{{ searchEvaData.exam_date | dateFormat }}</b></td>
                                      </tr>
                                      <!-- <tr>
                                        <td align="left">{{ $t('globalTrans.organization') }}</td>
                                        <td align="center">:</td>
                                        <td align="left" colspan="4" v-if="search.org_id"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.org_bn : searchHeaderData.org_en }}</b></td>
                                      </tr> -->
                                      <tr>
                                        <td align="left">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                        <td align="center">:</td>
                                        <td align="left" v-if="search.circular_memo_no" colspan="4"><b>{{ ($i18n.locale==='bn') ? search.circular_memo_no : search.circular_memo_no }}</b></td>
                                      </tr>
                                      <tr>
                                        <td align="left">{{ $t('elearning_iabm.batch_no') }}</td>
                                        <td align="center">:</td>
                                        <td align="left" v-if="search.batch_no"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.batch_bn : searchHeaderData.batch_en }}</b></td>
                                      </tr>
                                      <tr v-if="searchEvaData">
                                        <td align="left">{{ $t('elearning_tim.pre_evaluation_marks') }}</td>
                                        <td align="center">:</td>
                                        <td align="left"><b>{{ $n(searchEvaData.pre_evaluation_marks) }}</b></td>
                                      </tr>
                                      <tr v-if="searchEvaData">
                                        <td align="left">{{ $t('elearning_tim.post_evaluation_marks') }}</td>
                                        <td align="center">:</td>
                                        <td align="left"><b>{{ $n(searchEvaData.post_evaluation_marks) }}</b></td>
                                      </tr>
                                      <tr v-if="searchEvaData">
                                        <td align="left">{{ $t('elearning_iabm.training_start_dates') }}</td>
                                        <td align="center">:</td>
                                        <td align="left"><b>{{ training_start_date | dateFormat }}</b></td>
                                      </tr>
                                      <tr v-if="searchEvaData">
                                        <td align="left">{{ $t('elearning_iabm.training_end_dates') }}</td>
                                        <td align="center">:</td>
                                        <td align="left"><b>{{ training_end_date | dateFormat }}</b></td>
                                      </tr>
                                    </table>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <div class="col-md-12" id="form">
                                  <div class="col-12 mt-2">
                                    <b-table-simple hover small caption-top responsive border v-if="datas.length > 0">
                                      <b-thead class="thead">
                                        <b-tr>
                                          <b-th style="vertical-align: middle;width:80px" class="text-center">{{$t('globalTrans.sl_no')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-center">{{$t('elearning_tim.trainee')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-center">{{$t('globalTrans.designation')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-center">{{$t('elearning_tim.posting_office')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-center">{{$t('elearning_tim.pre_evaluation_mark')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-center">{{$t('elearning_tim.post_evaluation_mark')}}</b-th>
                                          <b-th style="vertical-align: middle" class="text-right">{{$t('elearning_tim.merit_position')}}</b-th>
                                        </b-tr>
                                      </b-thead>
                                      <b-tr v-for="(evaluation, index) in datas" :key="index">
                                        <b-td class="text-center">{{$n(index+1)}}</b-td>
                                        <b-td class="text-center">
                                          {{ ($i18n.locale==='bn') ? evaluation.name_bn : evaluation.name }}
                                        </b-td>
                                        <b-td class="text-center">
                                          <span v-if="evaluation.designation_id && evaluation.not_here_designation == 0 && evaluation.profession_type == 1">{{ getDesignation(evaluation.designation_id) }}</span>
                                          <span v-else>{{ ($i18n.locale=='bn') ? evaluation.designation_bn : evaluation.designation_en}}</span>
                                        </b-td>
                                        <b-td class="text-center">
                                          <span v-if="evaluation.profession_type == 1 && evaluation.not_here_office == 0">{{ getOfficeName(evaluation.professional_office_id) + ' , ' + getOrgName(evaluation.professional_org_id) }}</span>
                                          <span v-else-if="evaluation.profession_type == 1 && evaluation.not_here_office == 1">{{ ($i18n.locale=='bn') ? evaluation.office_name_bn : evaluation.office_name }}</span>
                                          <span v-else>{{ ($i18n.locale=='bn') ? evaluation.office_name_bn : evaluation.office_name }}</span>
                                        </b-td>
                                        <b-td class="text-center">
                                          {{ $n(evaluation.pre_evaluation_atten_marks) }}
                                        </b-td>
                                        <b-td class="text-center">
                                          {{ $n(evaluation.post_evaluation_atten_marks) }}
                                        </b-td>
                                        <b-td class="text-center">
                                          <span v-if="evaluation.post_evaluation_atten_marks > 0">
                                            {{ ($i18n.locale==='bn') ? getOrdinalBangla(index+1) :  getOrdinalEnglish(index+1) }}
                                          </span>
                                        </b-td>
                                      </b-tr>
                                    </b-table-simple>
                                    <template v-if="datas.length === 0">
                                        <br/>
                                        <br/>
                                        <h5 class="text-center text-danger" style="height: 80px">{{$t('globalTrans.noDataFound')}}</h5>
                                    </template>
                                  </div>
                                </div>
                              </b-row>
                              <b-row>
                                <b-col class="text-center" v-if="$i18n.locale==='bn' && datas.length > 0"><strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer1') }} {{ $t('tpm_report.report_footer2') }}</b-col>
                                <b-col class="text-center" v-if="$i18n.locale==='en' && datas.length > 0">{{ $t('tpm_report.report_footer1') }} <strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer2') }}</b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
          <!-- <pre>{{search}}</pre> -->
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { prePostEvaluationCompareReport, circularList } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'

export default {
    props: ['id'],
    components: {
        ListReportHead
    },
    created () {
      this.search = Object.assign({}, this.search, {
        // org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.search.org_id = this.$store.state.Auth.authUser.org_id
        if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
          this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
        }
      }
    },
    data () {
        return {
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          search: {
            fiscal_year_id: 0,
            org_id: 0,
            training_category_id: 0,
            training_type_id: 0,
            training_title_id: 0,
            circular_memo_no: '',
            batch_no: 0,
            coordinator_id: 0
          },
          searchHeaderData: {
            fiscal_year_en: '',
            fiscal_year_bn: '',
            org_en: '',
            org_bn: '',
            circular_memo_no: '',
            training_type_en: '',
            training_type_bn: '',
            training_title_en: '',
            training_title_bn: '',
            batch_en: '',
            batch_bn: ''
          },
          searchEvaData: {},
          trainingTypeList: [],
          trainingCategoryList: [],
          trainingTitleList: [],
          courseList: [],
          circularList: [],
          allBatchDataList: [],
          batchList: [],
          circularLoading: false,
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          allreadyApplicant: [],
          courses: [],
          loading: false,
          showData: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
        this.getCircularList()
    },
    computed: {
      currentLocale: function () {
        return this.$i18n.locale
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      currentFiscalYearId: function () {
        return this.$store.state.TrainingElearning.currentFiscalYearId
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      },
        i18 () {
            return this.$i18n.locale
        }
    },
    watch: {
        'search.org_id': function (newValue) {
          this.trainingTypeList = this.getTypeList(newValue)
        },
        'search.training_type_id': function (newValue) {
          this.trainingCategoryList = this.getCategoryList(newValue)
        },
        'search.training_category_id': function (newValue) {
          this.trainingTitleList = this.getTrainingTitleList(newValue)
        },
        'search.training_title_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
            this.getCircularList()
          }
        },
        'search.batch_no': function (newVal, oldVal) {
          if (newVal !== oldVal) {
            const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
            if (typeof allBatchListData !== 'undefined') {
              this.search.circular_memo_no = allBatchListData.circular_memo_no
            }
          }
        }
    },
    methods: {
      // getBengaliOrdinal (number) {
      //   if (this.$i18n.locale === 'bn') {
      //     let ordinal = ''
      //     if (number === 1) {
      //       ordinal = '১ম'
      //     } else if (number === 2) {
      //       ordinal = '২য়'
      //     } else if (number === 3) {
      //       ordinal = '৩য়'
      //     } else if (number % 10 === 1) {
      //       ordinal = this.$n(number) + 'ম'
      //     } else if (number % 10 === 2) {
      //       ordinal = this.$n(number) + 'য়'
      //     } else if (number % 10 === 3) {
      //       ordinal = this.$n(number) + 'য়'
      //     } else {
      //       ordinal = this.$n(number) + 'ম'
      //     }
      //     return ordinal
      //   } else {
      //     if (number === 1) {
      //       return '1st'
      //     } else if (number === 2) {
      //       return '2nd'
      //     } else if (number === 3) {
      //       return '3rd'
      //     } else if (number >= 4 && number <= 20) {
      //       return number + 'th'
      //     } else if (number % 10 === 1) {
      //       return number + 'st'
      //     } else if (number % 10 === 2) {
      //       return number + 'nd'
      //     } else if (number % 10 === 3) {
      //       return number + 'rd'
      //     } else {
      //       return number + 'th'
      //     }
      //   }
      // },
      getOrdinalEnglish (n) {
        const suffixes = ['th', 'st', 'nd', 'rd']
        const v = n % 100
        return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
      },
      getOrdinalBangla (n) {
        const suffixes = [
          'ম',
          'য়',
          'য়',
          'র্থ',
          'ম',
          'ষ্ঠ',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ষ্ঠ',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'য়',
          'থ',
          'তম',
          'ষ্ঠ',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম',
          'ম'
        ]
        return this.$n(n) + suffixes[n - 1]
      },
      getOrgName (id) {
          const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => parseInt(item.value) === parseInt(id))
          if (this.$i18n.locale === 'bn') {
              return trainingType !== undefined ? trainingType.text_bn : ''
          } else {
              return trainingType !== undefined ? trainingType.text_en : ''
          }
      },
      getDesignation (id) {
        const designation = this.$store.state.commonObj.designationList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return designation !== undefined ? designation.text_bn : ''
        } else {
          return designation !== undefined ? designation.text_en : ''
        }
      },
      getOfficeName (id) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return office !== undefined ? office.text_bn : ''
        } else {
          return office !== undefined ? office.text_en : ''
        }
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      getTypeList (orgId) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      },
      batchInfo () {
        const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
        const batchList = []
        batchData.map((obj, index) => {
          if (obj.batch_no) {
            const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
            if (typeof isThere !== 'undefined') {
              batchList.push(isThere)
            }
          }
        })
        this.batchList = batchList
        if (this.batchList.length === 1) {
          this.search.batch_no = 1
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id,
          training_type_id: this.search.training_type_id,
          training_category_id: this.search.training_category_id,
          training_title_id: this.search.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          this.allBatchListData = result.batchList
          this.training_start_date = result.batchList[0].training_start_date
          this.training_end_date = result.batchList[0].training_end_date

          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      },
      async searchData () {
        this.loading = true
        this.showData = true
        if (this.search.fiscal_year_id) {
          const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
          const customItem = {
            fiscal_year_en: fiscalYear?.text_en,
            fiscal_year_bn: fiscalYear?.text_bn
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
        }
        if (this.search.org_id) {
          const fiscalYear = this.orgList.find(obj => obj.value === this.search.org_id)
          const customItem = {
            org_en: fiscalYear?.text_en,
            org_bn: fiscalYear?.text_bn
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
        }
        if (this.search.training_title_id) {
          const titleObj = this.trainingTitleList.find(obj => obj.value === this.search.training_title_id)
          const titleData = {
            training_title_en: titleObj?.text_en,
            training_title_bn: titleObj?.text_bn
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, titleData)
        }
        if (this.search.batch_no) {
          const batchObj = this.allBatchList.find(obj => obj.value === this.search.batch_no)
          const batchData = {
            batch_en: batchObj?.text_en,
            batch_bn: batchObj?.text_bn
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, batchData)
        }
        this.getCustomDataSearch()
        const searchData = this.search
        searchData.details = []
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, prePostEvaluationCompareReport, searchData)
        if (!result.success) {
          this.datas = []
          this.loading = false
        } else {
          this.datas = result.data
          this.searchEvaData = result.data[0]
          this.loading = false
        }
      },
      getCustomDataSearch () {
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(this.search.training_type_id))
        if (typeof trainingTypeObj !== 'undefined') {
            this.search.training_type = trainingTypeObj.text_en
            this.search.training_type_bn = trainingTypeObj.text_bn
        } else {
            this.search.training_type = ''
            this.search.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(this.search.training_category_id))
        if (typeof trainingCategoryObj !== 'undefined') {
            this.search.training_category = trainingCategoryObj.text_en
            this.search.training_category_bn = trainingCategoryObj.text_bn
        } else {
            this.search.training_category = ''
            this.search.training_category_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(this.search.training_title_id))
        if (typeof trainingTitleObj !== 'undefined') {
            this.search.training_title = trainingTitleObj.text_en
            this.search.training_title_bn = trainingTitleObj.text_bn
        } else {
            this.search.training_title = ''
            this.search.training_title_bn = ''
        }
      },
      pdfExport () {
        const reportTitle = this.$t('tpm_report.pre_post_compare_evaluation_report')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search, 1)
      },
      pdfDownload () {
        const reportTitle = this.$t('tpm_report.pre_post_compare_evaluation_report')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search, 2)
      }
    }
}
</script>
