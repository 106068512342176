<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                    <b-row>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="fiscal year" vid="fiscal_year_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="fiscal_year_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('warehouse_config.fiscal_year') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="manage_schedule.fiscal_year_id"
                                                    :options="fiscalList"
                                                    id="fiscal_year_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="schedule date" vid="schedule_date" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="schedule_date"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('cotton_ginner_grower.schedule_date')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="datepicker"
                                                    v-model="manage_schedule.schedule_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="season" vid="seasons_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="seasons_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('cotton_ginner_grower.season') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="manage_schedule.seasons_id"
                                                    :options="seasonList"
                                                    id="region_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="region" vid="region_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="region_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('cotton_config.region') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="manage_schedule.region_id"
                                                    :options="regionList"
                                                    id="region_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="isOfficeUser"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="zone" vid="zone_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="zone_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('cotton_config.zone') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="manage_schedule.zone_id"
                                                    :options="zoneList"
                                                    id="zone_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="isOfficeUser"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="unit" vid="unit_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="unit_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('cotton_config.unit_name') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="manage_schedule.unit_id"
                                                    :options="unitList"
                                                    id="unit_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="isOfficeUser"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider>
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="district_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_district.district') }}
                                                    </template>
                                                    <!-- <b-form-input
                                                        disabled
                                                        v-if="($i18n.locale === 'en')"
                                                        id="district_id"
                                                        v-model="manage_schedule.district_name"
                                                    ></b-form-input>
                                                    <b-form-input
                                                        disabled
                                                        v-if="($i18n.locale === 'bn')"
                                                        id="district_id"
                                                        v-model="manage_schedule.district_name_bn"
                                                    ></b-form-input> -->
                                                    <b-form-select
                                                    plain
                                                    v-model="manage_schedule.district_id"
                                                    :options="districtList"
                                                    id="district_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option value="0">
                                                            {{ $t('globalTrans.select') }}
                                                        </b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider>
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="upazilla_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.upazila') }}
                                                    </template>
                                                    <!-- <b-form-input
                                                        disabled
                                                        v-if="($i18n.locale === 'en')"
                                                        id="upazilla_id"
                                                        v-model="manage_schedule.upazila_name"
                                                    ></b-form-input>
                                                    <b-form-input
                                                        disabled
                                                        v-if="($i18n.locale === 'bn')"
                                                        id="upazilla_id"
                                                        v-model="manage_schedule.upazila_name_bn"
                                                    ></b-form-input> -->
                                                    <b-form-select
                                                    plain
                                                    v-model="manage_schedule.upazila_id"
                                                    :options="upazilaList"
                                                    id="upazila_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option value="0">
                                                            {{ $t('globalTrans.select') }}
                                                        </b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="company name" vid="application_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="applicant_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.company_name') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="manage_schedule.applicant_id"
                                                    :options="ginnerList"
                                                    id="applicant_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option value="0">
                                                            {{ growerLoading ? 'Loading..' : $t('globalTrans.select') }}
                                                        </b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider>
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="father_name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('cotton_ginner_grower.ginnerName') }}
                                                </template>
                                                    <b-form-input
                                                        disabled
                                                        v-if="($i18n.locale === 'en')"
                                                        id="father_name"
                                                        v-model="manage_schedule.father_name"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <b-form-input
                                                        disabled
                                                        v-if="($i18n.locale === 'bn')"
                                                        id="father_name"
                                                        v-model="manage_schedule.father_name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Hat Name" vid="hatt_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="hatt_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('cotton_ginner_grower.hat_name') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="manage_schedule.hatt_id"
                                                    :options="hatList"
                                                    id="hatt_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="cotton variety Name" vid="cotton_variety_id" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="cotton_variety_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('cotton_config.cotton_variety') }}
                                                    </template>
                                                    <!-- <b-form-select
                                                    plain
                                                    v-model="manage_schedule.cotton_variety_id"
                                                    :options="cottonVaritiesList"
                                                    id="cotton_variety_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select> -->
                                                    <v-select
                                                        id="cotton_variety_id"
                                                        v-model="manage_schedule.cotton_variety_ids"
                                                        :reduce="op => op.value"
                                                        multiple
                                                        :options="cottonVaritiesList"
                                                        label="text"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        @input="getCottonNameList"
                                                    >
                                                    </v-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Cotton Name" vid="cotton_id" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="cotton_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('cotton_config.cotton_name') }}
                                                    </template>
                                                    <!-- <b-form-select
                                                    plain
                                                    v-model="manage_schedule.cotton_id"
                                                    :options="cottonNameList"
                                                    id="cotton_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select> -->
                                                    <v-select
                                                        id="cotton_id"
                                                        v-model="manage_schedule.cotton_ids"
                                                        :reduce="op => op.value"
                                                        multiple
                                                        :options="cottonNameList"
                                                        label="text"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </v-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="quantity" vid="quantity" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="quantity"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('cotton_ginner_grower.quantity')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    type="text"
                                                    min="0"
                                                    id="quantity"
                                                    v-model="manage_schedule.quantity"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Remarks (En)" vid="remarks">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="remarks"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('cotton_ginner_grower.remarks_en')}}
                                                </template>
                                                    <b-form-input
                                                    id="remarks"
                                                    v-model="manage_schedule.remarks"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Remarks (Bn)" vid="remarks_bn">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="remarks_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('cotton_ginner_grower.remarks_bn')}}
                                                    </template>
                                                    <b-form-input
                                                    id="remarks_bn"
                                                    v-model="manage_schedule.remarks_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <div class="row">
                                        <div class="col-sm-3"></div>
                                        <div class="col text-right">
                                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                            &nbsp;
                                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                        </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { ManageScheuleStore, ManageScheuleUpdate, ginnerList } from '../../api/routes'
import flatpickr from 'flatpickr'
import Common from '@/Utils/common'
import { getCottonMarketUserInfo } from '@/Utils/data-filtering-by-priviledge'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getEditingData()
            this.manage_schedule = tmp
            this.manage_schedule = Object.assign({}, this.manage_schedule, {
                cotton_variety_ids: JSON.parse(tmp.cotton_variety_ids),
                cotton_ids: JSON.parse(tmp.cotton_ids)
            })
            this.getCottonNameList()
        }
        this.getGinnerList()
        if (this.isOfficeUser) {
          const authOfficeDetail = this.$store.state.Auth.authUser.office_detail
          const unit = this.$store.state.agriMarketing.commonObj.unitList.find(obj => obj.district_id === authOfficeDetail.district_id)
          if (unit !== undefined) {
            this.manage_schedule.region_id = unit.region_id
            this.manage_schedule.zone_id = unit.zone_id
            this.manage_schedule.unit_id = unit.value
            this.manage_schedule.district_id = unit.district_id
            this.manage_schedule.upazilla_id = unit.upazilla_id
          }
        }
        this.setUserData()
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            manage_schedule: {
                seasons_id: '0',
                region_id: '0',
                zone_id: '0',
                unit_id: '0',
                fiscal_year_id: '0',
                schedule_date: '',
                applicant_id: '0',
                cotton_variety_ids: [],
                hatt_id: '0',
                cotton_ids: [],
                quantity: '',
                hat_date: '',
                remarks: '',
                remarks_bn: '',
                district_id: '0',
                upazila_id: '0',
                district_name: '',
                district_name_bn: '',
                upazila_name: '',
                upazila_name_bn: '',
                zone_name: '',
                zone_name_bn: '',
                region_name: '',
                region_name_bn: '',
                unit_name: '',
                unit_name_bn: '',
                name: '',
                name_bn: '',
                address: '',
                address_bn: '',
                father_name: '',
                father_name_bn: ''
            },
            growers: [],
            ginnerList: [],
            growerList: [],
            zoneList: [],
            upazilaList: [],
            unitList: [],
            cottonNameList: [],
            hatList: [],
            growerLoading: false
        }
    },
    watch: {
        'manage_schedule.region_id': function (newVal, oldVal) {
            if (oldVal !== newVal) {
                this.zoneList = this.getZoneList(newVal)
            }
        },
        'manage_schedule.zone_id': function (newVal, oldVal) {
            if (oldVal !== newVal) {
                this.unitList = this.getUnitList(newVal)
            }
        },
        'manage_schedule.unit_id': function (newVal, oldVal) {
            if (oldVal !== newVal) {
                this.setDistrictUpazila(newVal)
            }
        },
        'manage_schedule.district_id': function (newVal, oldVal) {
            if (oldVal !== newVal) {
                this.upazilaList = this.getUpazilaList(newVal)
            }
        },
        // 'manage_schedule.cotton_variety_id': function (newVal, oldVal) {
        //     if (oldVal !== newVal) {
        //         this.cottonNameList = this.getCottonNameList(newVal)
        //     }
        // },
        'manage_schedule.applicant_id': function (newVal, oldVal) {
            if (oldVal !== newVal) {
                this.getApplicantInfo(newVal)
            }
        },
        cottonMarketUserInfo: function () {
            this.setUserData()
        }
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
        cottonMarketUserInfo () {
            return getCottonMarketUserInfo()
        },
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList
        },
        fiscalList: function () {
            // const fiscalyearData = this.$store.state.commonObj.fiscalYearList
            // const currentFiscalYear = fiscalyearData.find(item => item.value === this.$store.state.currentFiscalYearId)
            // const maxSortingOrder = currentFiscalYear.sorting_order
            // const minSortingOrder = maxSortingOrder - 2
            // return fiscalyearData.filter(item => item.sorting_order >= minSortingOrder && item.sorting_order <= maxSortingOrder)
            //                     .sort((a, b) => b.soting_order - a.sorting_order)
            return Common.getFiscalYearList()
        },
        seasonList: function () {
            const seasonList = this.$store.state.agriMarketing.commonObj.seasonList.filter(item => item.status === 1)
            return seasonList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        regionList: function () {
            // const regionList = this.$store.state.agriMarketing.commonObj.regionList
            return this.cottonMarketUserInfo.regionList.filter(item => item.status === 1)
        },
        // hatList: function () {
        //     const hatData = this.$store.state.agriMarketing.commonObj.hatList
        //     return hatData.map(item => {
        //         if (this.$i18n.locale === 'bn') {
        //             return { value: item.value, text: item.text_bn }
        //         } else {
        //             return { value: item.value, text: item.text_en }
        //         }
        //     })
        // },
        cottonVaritiesList: function () {
            const ObjectData = this.$store.state.agriMarketing.commonObj.cottonVaritiesList.filter(item => item.status === 1)
            return ObjectData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        districtList () {
            return this.cottonMarketUserInfo.districtList.filter(item => item.status === 0)
        },
        isOfficeUser () {
        //   return !(this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.is_org_admin)
          return false
        }
    },
    methods: {
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(agriMarketingServiceBaseUrl, `${ManageScheuleUpdate}/${this.id}`, this.manage_schedule)
            } else {
                result = await RestApi.postData(agriMarketingServiceBaseUrl, ManageScheuleStore, this.manage_schedule)
            }
            this.loading = false
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            if (result.success) {
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        async getGinnerList () {
            this.circularLoading = true
            const result = await RestApi.getData(agriMarketingServiceBaseUrl, ginnerList)
            if (!result.success) {
            } else {
                const listObject = result.data
                const tmpList = listObject.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.applicant_id, text: obj.name_bn }
                } else {
                    return { value: obj.applicant_id, text: obj.name }
                }
                })
                this.ginnerList = tmpList
            }
            this.circularLoading = false
        },
        getRegionList (orgId) {
            // const regionList = this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1 && item.org_id === orgId)
            const regionList = this.cottonMarketUserInfo.regionList.filter(item => item.status === 1 && item.org_id === orgId)
            return regionList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        getZoneList (regionId) {
            // const zoneList = this.$store.state.agriMarketing.commonObj.zoneList.filter(item => item.status === 1 && item.region_id === regionId)
            const zoneList = this.cottonMarketUserInfo.zoneList.filter(item => item.status === 1 && item.region_id === regionId)
            return zoneList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.cottonMarketUserInfo.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getUnitList (zoneId) {
            // const unitList = this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1 && item.zone_id === zoneId)
            const unitList = this.cottonMarketUserInfo.unitList.filter(item => item.status === 1 && item.zone_id === zoneId)
            return unitList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        // getCottonNameList (cottonVarietyId = null) {
        //     const cottonNameList = this.$store.state.agriMarketing.commonObj.cottonNameList.filter(item => (item.status === 1) && (item.cotton_variety_id === cottonVarietyId))
        //     if (cottonVarietyId) {
        //         return cottonNameList.filter(cotton => cotton.cotton_variety_id === cottonVarietyId)
        //     }
        //     return cottonNameList
        // },
        getCottonNameList () {
            const cottonNameList = []
            if (this.manage_schedule.cotton_variety_ids.length) {
                this.manage_schedule.cotton_variety_ids.forEach((cottonVarietyId) => {
                    const cottonNameLists = this.$store.state.agriMarketing.commonObj.cottonNameList.filter(item => item.cotton_variety_id === cottonVarietyId)
                    cottonNameLists.forEach((item) => {
                        cottonNameList.push(item)
                    })
                })
                this.cottonNameList = cottonNameList
                // if the cotton id already selected, then update the cotton id
                // accrording to cotton variety id
                if (this.manage_schedule.cotton_ids.length) {
                    const idArr = []
                    this.manage_schedule.cotton_ids.forEach(id => {
                        const cottonNameObj = this.cottonNameList.find(obj => obj.value === parseInt(id))
                        if (cottonNameObj !== undefined) {
                            idArr.push(parseInt(cottonNameObj.value))
                        }
                    })
                    this.manage_schedule.cotton_ids = idArr
                }
            } else {
                this.cottonNameList = []
                this.manage_schedule.cotton_ids = []
            }
        },
        // async getGrowerList (unitId) {
        //     if (this.id) {
        //         this.loading = true
        //     }
        //     this.growerLoading = true
        //     let list = []
        //     const result = await RestApi.getData(agriMarketingServiceBaseUrl, growerList, { unit_id: unitId, type: 2 })
        //     if (result.success) {
        //         const data = result.data
        //         if (data.length > 0) {
        //             this.$store.dispatch('agriMarketingCottonGinnerGrower/setGrowerDropdownList', data)
        //             list = data.map(el => {
        //                 el.text = this.$i18n.locale === 'bn' ? el.name_bn : el.name
        //                 el.value = el.applicant_id
        //                 return el
        //             })
        //         }
        //         this.growerLoading = false
        //     } else {
        //         this.growerLoading = false
        //     }
        //     this.loading = false
        //     return list
        // },
        getRelatinalData (data) {
            const districtList = this.$store.state.commonObj.districtList
            const upazilaList = this.$store.state.commonObj.upazilaList
            const unitList = this.$store.state.agriMarketing.commonObj.unitList

            const listData = data.map(item => {
                const districtObject = districtList.find(district => district.value === item.district_id)
                const upazilaObject = upazilaList.find(upazila => upazila.value === item.upazilla_id)
                const unitObject = unitList.find(unit => unit.value === item.unit_id)

                const districtData = { district_name: districtObject.text, district_name_bn: districtObject.text_bn }
                const upazilaData = { upazila_name: upazilaObject.text, upazila_name_bn: upazilaObject.text_bn }
                const unitData = { unit_name: unitObject.text, unit_name_bn: unitObject.text_bn }

                return Object.assign({}, item, districtData, upazilaData, unitData)
            })
            return listData
        },
        getApplicantInfo (applicantId) {
            const grower = this.$store.state.agriMarketing.commonObj.ginnerGrowerList.find(item => item.value === applicantId)

            Object.assign(this.manage_schedule,
             { name: grower.name, name_bn: grower.name_bn, father_name: grower.father_name, father_name_bn: grower.father_name_bn, address: grower.address, address_bn: grower.address_bn }
            )
        },
        setDistrictUpazila (id) {
            const obj = this.cottonMarketUserInfo.unitList.find(item => item.value === parseInt(id))
            if (obj !== undefined) {
                const district = this.$store.state.commonObj.districtList.find(dist => dist.value === obj.district_id)
                const upazilla = this.$store.state.commonObj.upazilaList.find(upz => upz.value === obj.upazilla_id)

                Object.assign(this.manage_schedule,
                { district_id: obj.district_id, district_name: district.text_en, district_name_bn: district.text_bn },
                { upazila_id: obj.upazilla_id, upazila_name: upazilla.text_en, upazila_name_bn: upazilla.text_bn }
                )
                this.hatList = this.getHatList(district.value)
            }
        },
        getHatList (districtId) {
            const hatList = this.$store.state.agriMarketing.commonObj.hatList.filter(item => item.district_id === parseInt(districtId) && item.status === 1)
            return hatList
        },
        setUserData () {
            if (this.cottonMarketUserInfo.regionList.length === 1) {
                this.manage_schedule.region_id = this.cottonMarketUserInfo.regionList[0].value
            }
            if (this.cottonMarketUserInfo.zoneList.length === 1) {
                this.manage_schedule.zone_id = this.cottonMarketUserInfo.zoneList[0].value
            }
            if (this.cottonMarketUserInfo.unitList.length === 1) {
                this.manage_schedule.unit_id = this.cottonMarketUserInfo.unitList[0].value
            }
        }
    }
}
</script>
