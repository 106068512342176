<template>
    <b-container fluid>
        <b-overlay :show="unitLoad">
            <div>
                <button class="btn btn-success btn-sm mb-2" @click="printLicense()"><i class="ri-printer-line mr-0"></i>
                    {{ $t('globalTrans.print') }}
                </button>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                    <h4 class="">{{ $i18n.locale === 'bn' ? 'তফসিল-২' : 'Schedule-2' }}</h4>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                        <h5 class="">{{ $i18n.locale === 'bn' ? 'ফরম-ক' : 'Form-A' }}</h5>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                    <h6> {{ $i18n.locale === 'bn' ? '[বিধি ৪ (১) দ্রষ্টব্য]' : '[Rules 4(1)]' }}</h6>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                    <h6 class="customBorder"> {{ $i18n.locale === 'bn' ? 'সার আমদানি নিবন্ধনের আবেদনপত্র' : 'Fertilizer Import Registration' }}</h6>
                </div>
                <!-- <pre>
                    {{ badcStaticData[1]['image_73'] }}
                </pre> -->
                <b-tabs content-class="mt-3">
                    <b-tab v-for="(item, index) in tabData" :key="index" :active="index === 0" @click="tabChange(index)">
                        <template v-slot:title>
                            <span>{{ $i18n.locale === 'bn' ? item.tab_title_bn : item.tab_title }}</span>
                        </template>
                    </b-tab>
                    <template v-if="generalInfoShow">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-left"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder">{{ $i18n.locale === 'bn' ? 'সাধারণ তথ্য' : 'General information' }}</h5><!----></div><!----><!---->
                            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px" v-if="badcStaticData[0].applicant__11">
                                <!-- <div style="margin-left: 50px;margin-top: 20px;margin-bottom:20px" v-if="badcStaticData[0].applicant__11"> -->
                                <table style="width: 100%">
                                    <tr>
                                        <td style="width:50%">{{ $i18n.locale === 'bn' ? 'সারের নাম ' : 'Fertilizer Name' }}:  {{ getColumnName($store.state.ExternalLrcpn.lrcpnObj.fertilizerName, badcStaticData[0].imported_f_4767) }}</td>
                                        <td style="width:50%">{{ $i18n.locale === 'bn' ? 'আবেদনের নাম্বার ' : 'Application No.' }} : {{ $n(badcStaticData[0].application_id, { useGrouping: false }) }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $i18n.locale === 'bn' ? 'বিন নাম্বার ' : 'Bin No.' }} : {{ $n(officeData.file_no, { useGrouping: false }) }}</td>
                                        <td v-if="pDatas.hasManual">{{ $i18n.locale === 'bn' ? 'লাইসেন্স নাম্বার ' : 'License No' }} : {{ $n(pDatas.generate_id, { useGrouping: false }) }}</td>
                                    </tr>
                                </table>
                                <!-- </div> -->
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-left"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder">{{ $i18n.locale === 'bn' ? 'আবেদনকারীর তথ্য' : 'Applicant Info' }}</h5><!----></div><!----><!---->
                            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px" v-if="badcStaticData[0].applicant__11">
                                <!-- <div style="margin-left: 50px;margin-top: 20px;margin-bottom:20px" v-if="badcStaticData[0].applicant__11"> -->
                                <table style="width: 100%">
                                    <tr>
                                        <td style="width:50%">{{ $i18n.locale === 'bn' ? 'আবেদনকারীর নাম ' : 'Applicant Name' }}: {{ $i18n.locale === 'bn' ? badcStaticData[0].applicant__84 : badcStaticData[0].applicant__11 }}</td>
                                        <td style="width:50%">{{ $i18n.locale === 'bn' ? 'পিতার নাম ' : 'Fathers Name' }} : {{ $i18n.locale === 'bn' ? badcStaticData[0].fathers_na_82 : badcStaticData[0].fathers_na_21 }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $i18n.locale === 'bn' ? 'মাতার নাম ' : 'Mothers Name' }} : {{ $i18n.locale === 'bn' ? badcStaticData[0].mothers_na_86 : badcStaticData[0].mothers_na_16 }}</td>
                                        <td>{{ $i18n.locale === 'bn' ? 'স্বামীর বা স্ত্রীর নাম ' : 'Spouse Name' }} : {{ $i18n.locale === 'bn' ? badcStaticData[0].spouse_nam_85 : badcStaticData[0].spouse_nam_22 }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $i18n.locale === 'bn' ? 'জাতীয় পরিচয়পত্র নম্বর ' : 'NID' }} : {{ $n(badcStaticData[0].national_i_12, { useGrouping: false }) }}</td>
                                        <td>{{ $i18n.locale === 'bn' ? 'মোবাইল নম্বর ' : 'Mobile Number' }} : {{ badcStaticData[0].mobile_num_20 | mobileNumber }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $i18n.locale === 'bn' ? 'ই-মেইল ' : 'Email' }} : {{ badcStaticData[0].email____71 }}</td>
                                    </tr>
                                </table>
                                <!-- </div> -->
                            </div>
                        </div>

                        <!-- present part  -->
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'বর্তমান ঠিকানা' : 'Present Address' }}</h5><!----></div><!----><!---->
                            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                                <table border="0" style="width: 100%">
                                <tr>
                                    <td style="width:50%">{{$t('org_pro_division.division')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_39) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_22) }}
                                    </slot> -->
                                    </td>
                                    <td style="width:50%">{{$t('org_pro_district.district')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_40) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                                    </slot> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:50%">{{$t('org_pro_upazilla.upazilla')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazila_41) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                                    </slot> -->
                                    </td>
                                    <td style="width:50%">{{$t('globalTrans.address')}} : {{ $i18n.locale === 'bn' ? badcStaticData[0]['present_address(bn)'] : badcStaticData[0]['present_address(en)'] }}</td>
                                </tr>
                                </table>
                            </div>
                        </div>

                        <!-- permanent part  -->
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'স্থায়ী ঠিকানা' : 'Permanent Address' }}</h5><!----></div><!----><!---->
                            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                                <table border="0" style="width: 100%">
                                <tr>
                                    <td style="width:50%">{{$t('org_pro_division.division')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_id_3036) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_22) }}
                                    </slot> -->
                                    </td>
                                    <td style="width:50%">{{$t('org_pro_district.district')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_8192) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                                    </slot> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{$t('org_pro_upazilla.upazilla')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_4142) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                                    </slot> -->
                                    </td>
                                    <td>{{$t('globalTrans.address')}} : {{ $i18n.locale === 'bn' ? badcStaticData[0].permanent__5790 : badcStaticData[0]['permanent_address(en)'] }}</td>
                                </tr>
                                </table>
                            </div>
                        </div>
                        <!-- business part  -->
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'ব্যবসায়িক তথ্য' : 'Business Information' }}</h5><!----></div><!----><!---->
                            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                                <table border="0" style="width: 100%">
                                <tr>
                                    <td style="width:50%">{{ $i18n.locale === 'bn' ? $t('প্রতিষ্ঠানের নাম') : 'Company Name' }} :
                                    <slot>
                                        {{ $i18n.locale === 'bn' ? badcStaticData[0].organizati_9606 : badcStaticData[0].organizati_2112 }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_22) }}
                                    </slot> -->
                                    </td>
                                    <td style="width:50%">{{$t('org_pro_division.division')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_3384) }}
                                    </slot>
                                    <!-- <td>{{$t('ব্যবসায়িক ঠিকানা')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                                    </slot> -->
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                                    </slot> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{$t('org_pro_district.district')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_8192) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_22) }}
                                    </slot> -->
                                    </td>
                                    <td>{{$t('org_pro_upazilla.upazilla')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_4142) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                                    </slot> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $i18n.locale === 'bn' ? $t('ব্যবসায়িক ঠিকানা') : 'Business Address'}} :
                                    <slot>
                                        {{ $i18n.locale === 'bn' ? badcStaticData[0].business_a_8633 : badcStaticData[0].business_a_8032 }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                                    </slot> -->
                                    </td>
                                    <!-- <td>{{$t('globalTrans.address')}} : {{ $i18n.locale === 'bn' ? badcStaticData[0].address_bn_25 : badcStaticData[0].address_en_4957 }}</td> -->
                                </tr>
                                </table>
                            </div>
                        </div>
                        <!-- warehouse  -->
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'গুদামের তথ্য' : 'Warehouse Information' }}</h5><!----></div><!----><!---->
                            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                                <table border="0" style="width: 100%">
                                <tr>
                                    <td style="width:50%">{{$t('org_pro_division.division')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_2696) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_22) }}
                                    </slot> -->
                                    </td>
                                    <td style="width:50%">{{$t('org_pro_district.district')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_5327) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                                    </slot> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{$t('org_pro_upazilla.upazilla')}} :
                                    <slot>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_8191) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                                    </slot> -->
                                    </td>
                                    <td>{{$t('globalTrans.address')}} : {{ $i18n.locale === 'bn' ? badcStaticData[0].warehouse__6533 : badcStaticData[0].business_a_8032 }}</td>
                                </tr>
                                <tr>
                                    <td>{{$t('globalTrans.mobile')}} :
                                    <slot>
                                        {{ badcStaticData[0].mobile_num_4023 | mobileNumber }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                                    </slot> -->
                                    </td>
                                    <td>{{$t('fertilizerConfig.godown_height')}} : {{ $n(badcStaticData[0].warehouse__7273, { useGrouping: false }) }}</td>
                                </tr>
                                <tr>
                                    <td>{{$t('fertilizerConfig.godown_width')}} :
                                    <slot>
                                        {{ $n(badcStaticData[0].warehouse__4761, { useGrouping: false }) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                                    </slot> -->
                                    </td>
                                    <td>{{$t('fertilizerConfig.godown_height2')}} : {{ $n(badcStaticData[0].warehouse__6021, { useGrouping: false }) }}</td>
                                </tr>
                                <tr>
                                    <td>{{$t('warehouse_service.holding_capacity')}} :
                                    <slot>
                                        {{ $n(badcStaticData[0].capacity__1343, { useGrouping: false }) }}
                                    </slot>
                                    <!-- <slot v-else>
                                        {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                                    </slot> -->
                                    </td>
                                </tr>
                                </table>
                            </div>
                        </div>

                                <!-- fertilizer information part  -->
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'সারের তথ্যঃ' : 'Fertilizer Information' }}</h5><!----></div><!----><!---->
                            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                                <table border="0" style="width: 100%">
                                    <tr>
                                        <td style="width:50%">{{$t('li_step.import_fertilizer_name')}} :
                                        <slot>
                                            {{ getColumnName($store.state.ExternalLrcpn.lrcpnObj.fertilizerName, badcStaticData[0].imported_f_4767) }}
                                            <!-- {{ $i18n.locale === 'bn' ? badcStaticData[0].imported_f_4872 : badcStaticData[0].imported_f_1778 }} -->
                                        </slot>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{$t('dam-config.chemical_name')}} :
                                            <slot>
                                                {{ getColumnNameField($store.state.ExternalLrcpn.lrcpnObj.fertilizerName, badcStaticData[0].imported_f_4767, 'chemical_element') }}
                                            </slot>
                                        </td>
                                        <td>
                                            {{$t('dam-config.chemical_signal')}} :
                                            {{ getColumnNameField($store.state.ExternalLrcpn.lrcpnObj.fertilizerName, badcStaticData[0].imported_f_4767, 'chemical_signal') }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{$t('externalLrcpn.country_name')}} :
                                            <slot>
                                                {{ getCountryManufactureName(badcStaticData[0].country_na_1757) }}
                                            </slot>
                                        </td>
                                        <td>{{$t('globalTrans.company_name')}} :
                                            <slot>
                                                {{ getCompanyName(badcStaticData[0].company_na_2647) }}
                                            </slot>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <!-- register fertilizer information part  -->
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'নিবন্ধিত সারের তথ্য' : 'Register Fertilizer Information' }}</h5><!----></div><!----><!---->
                            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                                <table border="0" style="width: 100%">
                                <tr>
                                    <td style="width:50%">{{ $i18n.locale === 'bn' ? 'কৃষি সম্প্রসারণ অধিদপ্তর কর্তৃক নিবন্ধিত সারের নাম' : 'Name of Fertilizer Registered by the DAE' }} :
                                    <slot>
                                        {{ $i18n.locale === 'en' ? badcStaticData[0].name_of_fe_6864 : badcStaticData[0].name_of_fe_4525 }}
                                    </slot>
                                    </td>
                                    <td style="width:50%">{{ $i18n.locale === 'bn' ? 'রেজিস্ট্রেশন নম্বর(যদি থাকে)' : 'Registration Number(If any)' }} :
                                    <slot>
                                        {{ $n(badcStaticData[0].______4700, { useGrouping: false }) }}
                                    </slot>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $i18n.locale === 'bn' ? 'কোম্পানির মালিকের স্বাক্ষর' : 'Company Owner Signature' }} :
                                    <slot>
                                        <a target="_blank" v-if="getFile(badcStaticData[0].company_ow_1740) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[0].company_ow_1740)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                    </slot>
                                    </td>
                                    <td>{{ $i18n.locale === 'bn' ? 'অনুমোদনের সংযুক্তি' : 'Authorization Attachment'}} :
                                        <!-- {{ badcStaticData[0].authorizat_7246 }} -->
                                        <slot>
                                        <a target="_blank" v-if="getFile(badcStaticData[0].authorizat_9571) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[0].authorizat_9571)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                    </slot>
                                        </td>
                                </tr>
                                </table>
                            </div>
                        </div>
                    </template>
                    <template v-if="attachmentShow">
                        <b-row>
                            <b-col md="12" class="table-responsive" v-if="isLoad">
                                <b-overlay :show="loading">
                                    <table class="table" border="1">
                                        <tr>
                                            <th style="width: 30%;" class="text-center">{{ $t('globalTrans.name') }}</th>
                                            <th style="width: 10%">{{ $t('globalTrans.attachment') }}</th>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_1') }} </td>
                                            <td>
                                                 <a target="_blank" v-if="getFile(badcStaticData[1].image_73) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].image_73)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_2') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].rent_agree_5997) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].rent_agree_5997)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_3') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].nid_79) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].nid_79)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_4') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].updated_tr_5857) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].updated_tr_5857)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_5') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].tin_certif_1997) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].tin_certif_1997)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_6') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].vat_regist_7591) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].vat_regist_7591)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_7') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].updated_in_80) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].updated_in_80)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_8') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].updated_ir_8033) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].updated_ir_8033)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_9') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].updated_bf_3787) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].updated_bf_3787)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_10') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].uptodate_b_2747) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].uptodate_b_2747)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_11') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].undertakin_8030) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].undertakin_8030)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_12') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].address_an_7107) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].address_an_7107)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_13') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].authorizat_7806) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].authorizat_7806)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_14') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].paclobutra_7492) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].paclobutra_7492)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_15') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].approved_s_9860) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].approved_s_9860)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_19') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1]['packet_prepared_in_light_of_approved_sample_label_(renewal)']) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1]['packet_prepared_in_light_of_approved_sample_label_(renewal)'])"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_16') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1]._year_perf_2280) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1]._year_perf_2280)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_17') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].old_regist_6668) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].old_regist_6668)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('li_step.dae_18') }} </td>
                                            <td>
                                                <a target="_blank" v-if="getFile(badcStaticData[1].others_9133) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].others_9133)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                            </td>
                                        </tr>
                                    </table>
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </template>
                    <template v-if="labelDesignShow">
                        <!-- fertilizer information part  -->
                        <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'আমদানিকৃত সারের নাম' : 'আমদানিকৃত সারের নাম' }}</h5><!----></div><!----><!---->
                        <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                            <table border="0" style="width: 100%">
                            <tr>
                                <td style="width:50%">{{$t('li_step.brand_name')}} :
                                <slot>
                                    {{ badcStaticData[2].brand_name_4227 }}
                                </slot>
                                </td>
                                <td style="width:50%">{{$t('dam-config.chemical_name')}} :
                                <slot>
                                    {{ badcStaticData[2].chemical_n_3415 }}
                                </slot>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $i18n.locale === 'bn' ? 'পুষ্টি উপাদানের নাম' : 'Nutrient Name' }} :
                                <slot>
                                    {{ badcStaticData[2].nutrient_n_2775 }}
                                </slot>
                                </td>
                                <td>{{ $i18n.locale === 'bn' ? 'পুষ্টি উপাদানের শতকরা হার' : 'Percentage of Nutrients' }} : {{ badcStaticData[2].percentage_4735 }}</td>
                            </tr>
                            <tr>
                                <td>{{ $i18n.locale === 'bn' ? 'সারের নিট ওজন' : 'Net Weight of Fertilizer' }} :
                                <slot>
                                    {{ badcStaticData[2].net_weight_9789 }}
                                </slot>
                                </td>
                                <td>{{ $i18n.locale === 'bn' ? 'ব্যাচ নং/লট নং' : 'Batch No./Lot No.' }} : {{ badcStaticData[2].batch_nolo_3573 }}</td>
                            </tr>
                            <tr>
                                <td>{{ $i18n.locale === 'bn' ? 'উৎপাদনের তারিখ' : 'Date of production' }} :
                                <slot>
                                    {{ badcStaticData[2].date_of_pr_8517 | dateFormat }}
                                </slot>
                                </td>
                                <td>{{ $i18n.locale === 'bn' ? 'সর্বোচ্চ খুচরা মূল্য' : 'Maximum retail price' }} : {{ badcStaticData[2].maximum_re_7699 }}</td>
                            </tr>
                            </table>
                        </div>
                    </div>

                    <!-- register fertilizer information part  -->
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'আমদানীকারকের তথ্যঃ' : 'আমদানীকারকের তথ্যঃ' }}</h5><!----></div><!----><!---->
                        <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                            <table border="0" style="width: 100%">
                            <tr>
                                <td style="width:50%">{{ $i18n.locale === 'bn' ? 'আমদানীকারকের নাম' : 'Importer Name' }} :
                                <slot>
                                    {{ badcStaticData[2].importer_n_1729 }}
                                </slot>
                                <!-- <slot v-else>
                                    {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_22) }}
                                </slot> -->
                                </td>
                                <td style="width:50%">{{ $i18n.locale === 'bn' ? 'আমদানীকারকের মোবাইল নম্বর' : 'Importer Mobile No.' }} :
                                <slot>
                                    {{ badcStaticData[2].importer_m_7964 | mobileNumber }}
                                </slot>
                                <!-- <slot v-else>
                                    {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                                </slot> -->
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $i18n.locale === 'bn' ? 'বিভাগ' : 'Division' }} :
                                <slot>
                                    {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[2].division_6671) }}
                                </slot>
                                <!-- <slot v-else>
                                    {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                                </slot> -->
                                </td>
                                <td>{{ $i18n.locale === 'bn' ? 'জেলা' : 'District' }} :  {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[2].district_4706) }}</td>
                            </tr>
                            <tr>
                                <td>{{ $i18n.locale === 'bn' ? 'উপজেলা' : 'Upazila' }} :
                                <slot>
                                    {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[2].upazila_2511) }}
                                </slot>
                                <!-- <slot v-else>
                                    {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                                </slot> -->
                                </td>
                                <td>{{ $i18n.locale === 'bn' ? 'আমদানীকারকের ঠিকানা' : 'Importer Address' }} : {{  $i18n.locale === 'bn' ? badcStaticData[2].importer_a_7902 : badcStaticData[2].importer_a_3177 }}</td>
                            </tr>
                            </table>
                        </div>
                    </div>

                    </template>
                </b-tabs>
            </div>
        </b-overlay>
    </b-container>
</template>

<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'

export default {
  props: ['tabData', 'badcStaticData', 'stepData', 'pDatas', 'officeData', 'step_id'],

  data () {
    return {
        unitLoad: false,
        isLoad: false,
        datas: {},
        generalInfoShow: true,
        attachmentShow: false,
        labelDesignShow: false,
        reportLoading: false,
        paymentRecipt: false,
        lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
        reportList: [],
        importData: [
            { id: 1, file: 'image_73', yes_no: 1, remarks: null },
            { id: 2, file: 'rent_agree_5997', yes_no: 1, remarks: null },
            { id: 3, file: 'nid_79', yes_no: 0, remarks: null },
            { id: 4, file: 'updated_tr_5857', yes_no: 0, remarks: null },
            { id: 5, file: 'tin_certif_1997', yes_no: 0, remarks: null },
            { id: 6, file: 'vat_regist_7591', yes_no: 0, remarks: null },
            { id: 7, file: 'updated_in_80', yes_no: 0, remarks: null },
            { id: 8, file: 'updated_ir_8033', yes_no: 0, remarks: null },
            { id: 9, file: 'updated_bf_3787', yes_no: 0, remarks: null },
            { id: 10, file: 'uptodate_b_2747', yes_no: 0, remarks: null },
            { id: 11, file: 'undertakin_8030', yes_no: 0, remarks: null },
            { id: 12, file: 'address_an_7107', yes_no: 0, remarks: null },
            { id: 13, file: 'photocopy__4458', yes_no: 0, remarks: null },
            { id: 14, file: 'authorizat_7806', yes_no: 0, remarks: null },
            { id: 15, file: 'paclobutra_7492', yes_no: 0, remarks: null },
            { id: 16, file: 'approved_s_9860', yes_no: 0, remarks: null },
            { id: 17, file: 'small_and__8570', yes_no: 0, remarks: null },
            { id: 18, file: '_year_perf_2280', yes_no: 0, remarks: null },
            { id: 19, file: 'old_regist_6668', yes_no: 0, remarks: null },
            { id: 20, file: 'others_9133', yes_no: 0, remarks: null }
        ],
        daeList: []
    }
  },
  created () {
    this.daeList = this.import
    this.loadData()
  },
  computed: {
    local: function () {
        return this.$i18n.locale
    },
    ingridentList: function () {
      return this.$store.state.licenseRegistration.commonObj.activeIngredientList.filter(item => item.status === 1)
    },
    labList: function () {
       return this.$store.state.licenseRegistration.commonObj.labList.filter(item => item.status === 1)
    }
  },
  methods: {
    getCountryManufactureName (id) {
        const obj = this.$store.state.ExternalLrcpn.lrcpnObj.countryList.find(el => el.value === parseInt(id))
        if (obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
    },
    getCompanyName (id) {
        const obj = this.$store.state.ExternalLrcpn.lrcpnObj.foreignEnlishmentList.find(el => el.value === parseInt(id))
        if (obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
    },
    checkFileFunction (field) {
      return field
    },
    getFile (value) {
      if (value === '') {
         return false
      }
      if (value) {
        const fileData = JSON.parse(value)
        if (fileData[0]) {
          return fileData[0]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    loadData () {
        const search = {
            service_id: this.badcStaticData[0].service_id,
            application_id: this.badcStaticData[0].application_id
        }
        const params = Object.assign({}, search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(licenseRegistrationServiceBaseUrl, '/step/dae-attachment/search', params).then(response => {
            if (response.success) {
                this.datas = response.data
                this.isLoad = true
            } else {
                this.isLoad = true
            }
        })
    },
    tabChanged () {
        this.attachmentShow = false
        this.labelDesignShow = false
        this.generalInfoShow = false
    },
    tabChange (val) {
        if (val === 0) {
            this.attachmentShow = false
            this.labelDesignShow = false
            this.generalInfoShow = true
        }
        if (val === 1) {
            this.labelDesignShow = false
            this.generalInfoShow = false
            this.attachmentShow = true
        }
        if (val === 2) {
            this.generalInfoShow = false
            this.attachmentShow = false
            this.labelDesignShow = true
        }
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getColumnNameField (list, groupId, column) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        return obj[column]
      } else {
        return ''
      }
    },
    async getreportData () {
        this.generalInfoShow = false
        this.attachmentShow = false
        this.labelDesignShow = false
        this.reportLoading = true
        const applicationID = parseInt(this.badcStaticData[0].application_id)
        await RestApi.getData(licenseRegistrationServiceBaseUrl, `${'/configuration/dae-lab-assign/lab-assign'}/${applicationID}`).then(response => {
            if (response.success) {
                if (response.data.length > 0) {
                    this.reportList = response.data[0].lab_details
                }
            }
            this.reportLoading = false
        })
    },
    getLabName (labId) {
      const lab = this.labList.find(item => item.value === labId)
      if (this.$i18n.locale === 'en') {
        return lab.text_en
      } else {
        return lab.text_bn
      }
    },
    getIngredientName (ingredientId) {
      const ingredient = this.ingridentList.find(item => item.value === ingredientId)
      if (this.$i18n.locale === 'en') {
        return ingredient.text_en
      } else {
        return ingredient.text_bn
      }
    },
    async printLicense () {
      const params = {
            id: 9,
            service_id: parseInt(this.badcStaticData[0].service_id),
            step_id: parseInt(this.step_id),
            applicationID: parseInt(this.badcStaticData[0].application_id),
            org_id: parseInt(this.badcStaticData[0].org_id),
            type: 'pdf',
            local: this.$i18n.locale
        }
      this.unitLoad = true

      try {
        const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, '/step/step-from-data', params)
        var blob = new Blob([result], { type: 'application/pdf' })
        var url = window.URL.createObjectURL(blob)

        // Create an anchor element for direct download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'importer-license.pdf' // Set the desired filename for the download

        // Trigger a click event on the anchor element to initiate the download
        document.body.appendChild(a)
        a.click()

        // Clean up the anchor element and URL object
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)

        this.unitLoad = false
      } catch (error) {
        this.unitLoad = false
      }
    }
    // printLicense () {
    //     const search = {
    //         id: 9,
    //         service_id: parseInt(this.badcStaticData[0].service_id),
    //         step_id: parseInt(this.stepData.step_id),
    //         applicationID: parseInt(this.badcStaticData[0].application_id),
    //         org_id: parseInt(this.badcStaticData[0].org_id),
    //         type: 'pdf',
    //         local: this.$i18n.locale
    //     }
    //     const params = Object.assign({}, search)
    //     this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
    //     RestApi.getData(licenseRegistrationServiceBaseUrl, '/step/step-from-data', params).then(response => {
    //         if (response.success) {
    //             this.datas = response.data
    //             this.isLoad = true
    //         } else {
    //             this.isLoad = true
    //         }
    //     })
    // }
  }
}

</script>
