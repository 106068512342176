<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_iabm.invitation') }} {{ $t('globalTrans.details') }}
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link :to="`/training-e-learning-service/iabm/invitation?circular_memo_no=${data.circular_memo_no}&batch_no=${data.batch_no}`" :class="'btn btn_add_new'">{{ $t('elearning_iabm.invitation') }} {{ $t('globalTrans.list') }}</router-link>
            <b-button type="button" variant="primary" class="ml-2" @click="pdfExport">{{ $t('globalTrans.print') }}</b-button>
            <b-button type="button" variant="primary" class="mr-2 ml-2" @click="pdfDownloadExport">{{ $t('elearning_tim.download_pdf') }}</b-button>
            <b-button type="button" variant="primary" class="mr-2 ml-2" @click="exportWord">{{ $t('globalTrans.word') }}</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-overlay :show="loadIt">
                  <b-row>
                      <b-col>
                          <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="org_id">
                          </list-report-head>
                      </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12" class="top-height mt-5" style="padding-left: 10px; padding-right: 10px">
                      <table border="0" class="mt-3 tabletwo"  style="width:100%">
                        <tbody>
                          <tr>
                            <th style="width: 8%"  class="text-left memo-no" >{{ $t('elearning_tim.memorial_no') }}:</th>
                            <td style="width: 39%" class="text-left">{{ data.office_memo }}</td>
                            <th style="width: 35%" class="text-right" >{{ $t('globalTrans.date') }}:</th>
                            <td style="width: 15%" class="text-left">{{ data.invitation_date | dateFormat }}</td>
                          </tr>
                        </tbody>
                      </table>
                      </b-col>
                    <b-col lg="12" sm="12" class="top-height m-0" style="padding-left: 10px; padding-right: 10px">
                      <table border="0" class="mt-3 tabletwo"  style="width:100%">
                        <tbody>
                          <tr>
                            <th style="width: 2%"  class="text-left memo-no" >{{ $t('irri_pump_main.subject') }}:</th>
                            <td style="width: 39%" class="text-left">{{ data.subject }}</td>
                          </tr>
                        </tbody>
                      </table>
                      </b-col>
                      <b-col lg="12" sm="12" class="top-height mt-3" style="padding-left: 10px; padding-right: 10px">
                        <div v-if="$i18n.locale == 'en'">
                          {{ 'In regard to the above-mentioned subject, it is informed that a training course on ' }} ''<B>{{ getTrainingTitle(data.training_title_id) }}</B>'' {{ 'is going to be held at the ' }}  ''<b>{{ getOrg(data.circular_org_id) }}</b>''
                          <span v-if="data.circular_org_id === 4">{{ ' for the officers/scientists of 13th NARS' }}</span> <span v-else>{{ ' for the officers/scientists of 17th' }}</span> {{ 'organization under/of the Ministry of Agriculture. You are cordially requested to conduct the following session as a resource speaker in that training course. You are requested to send a soft copy of the handout on the concerned topic to the undersigned e-mail:' }} <b>{{ data.signatory_email }}</b>
                        </div>
                        <div v-if="$i18n.locale == 'bn'">
                          {{ 'উপর্যুক্ত বিষয়ের প্রেক্ষিতে জানান যাচ্ছে যে, ' }} <b>{{ getOrg(data.circular_org_id) }}</b> <span v-if="data.circular_org_id === 4">{{ ' এ কৃষি মন্ত্রণালয়ের আওতাধীন ১৩ টি নার্সভুক্ত দপ্তর/ সংস্থার '}}</span> <span v-else>{{ ' তে কৃষি মন্ত্রণালয়ের আওতাধীন ১৭ টি দপ্তর/ সংস্থার '}}</span> {{ 'কর্মকর্তা/বিজ্ঞানীদের জন্য ' }}''<B>{{ getTrainingTitle(data.training_title_id) }}</B>'' {{ ' শীর্ষক একটি প্রশিক্ষণ কোর্স অনুষ্ঠিত হতে যাচ্ছে। আপনাকে উক্ত প্রশিক্ষণ কোর্সে রিসোর্স স্পিকার হিসাবে নিম্নলিখিত সেশনটি পরিচালনা করার জন্য বিনীতভাবে অনুরোধ করা হল। সংশ্লিষ্ট বিষয়ের উপর হ্যান্ডনোট এর সফট কপি নিম্নস্বাক্ষরকারীর ই-মেইল:' }} <b>{{ data.signatory_email }}</b> {{ 'এ প্রেরণ করার জন্য অনুরোধ করা হল।' }}
                        </div>
                      </b-col>
                    <b-col lg="12" id="form">
                        <b-table-simple bordered class="mt-3" style="width:100%;padding: 5px">
                          <b-thead class="thead">
                            <b-tr>
                              <th style="width:15%" class="text-center">{{ $t('elearning_tim.date_time') }} </th>
                              <th style="width:10%" class="text-center">{{ $t('elearning_tim.session_hrs') }}</th>
                              <th style="width:25%" class="text-center">{{$t('elearning_tim.lesson_topic')}} </th>
                              <th style="width:25%" class="text-center">{{$t('globalTrans.remarks')}} </th>
                            </b-tr>
                          </b-thead>
                          <b-tr v-for="(item, index) in data.trainer_course" :key="index">
                            <b-td class="text-center">
                              {{item.session_date | dateFormat}} <br>
                              {{ item.start_time | time12FormateTraining}} {{ '-' }} {{ item.end_time | time12FormateTraining }}
                            </b-td>
                            <b-td class="text-center">
                              <span  v-if="item.hours > 0">{{ $n(item.hours) }} {{ $t('elearning_iabm.hour') }}</span> <span v-if="item.minutes > 0">{{ $n(item.minutes) }} {{ $t('elearning_iabm.minute') }}</span>
                            </b-td>
                            <b-td class="text-center">
                              {{ getColumnName($store.state.TrainingElearning.commonObj.courseList, item.course_management_id)}}
                            </b-td>
                            <b-td class="text-center">
                              {{ $i18n.locale == 'en' ? item.remarks_en : item.remarks_bn }}
                            </b-td>
                          </b-tr>
                        </b-table-simple>
                    </b-col>
                    <!-- <pre>{{ data }}</pre> -->
                    <b-col lg="12" sm="12">
                      <b-table-simple class="tg mt-3" hover small caption-top>
                        <tbody>
                          <tr>
                            <!-- <td style="width: 32%" v-if="data.trainer.professional_info.profession_type == 1 && data.trainer.professional_info.not_here_office == 1 && data.trainer.professional_info.not_here_designation == 1" class="text-center">
                               {{ $t('elearning_iabm.recipient') }}<br/>
                               {{ $i18n.locale == 'en' ? data.trainer.name : data.trainer.name_bn }}<br/>
                               {{ $i18n.locale == 'en' ? data.trainer.professional_info.designation_en : data.trainer.professional_info.designation_bn  }}<br/>
                               {{ $i18n.locale == 'en' ? data.trainer.professional_info.office_name : data.trainer.professional_info.office_name_bn }}<br/>
                            </td>
                            <td style="width: 32%" v-else-if="data.trainer.professional_info.profession_type == 1 && data.trainer.professional_info.not_here_office == 0 && data.trainer.professional_info.not_here_designation == 0" class="text-center">
                               {{ $t('elearning_iabm.recipient') }}<br/>
                               {{ $i18n.locale == 'en' ? data.trainer.name : data.trainer.name_bn }}<br/>
                               {{ getDesignation(data.trainer.professional_info.designation_id) }}<br/>
                               {{ getOfficeType(data.trainer.professional_info.office_type_id) }}<br/>
                               {{ getOffice(data.trainer.professional_info.office_id) }}
                            </td>
                            <td style="width: 32%" v-else-if="(data.trainer.professional_info.profession_type == 2 || 3) && data.trainer.professional_info.not_here_office == 1 && data.trainer.professional_info.not_here_designation == 1" class="text-center">
                               {{ $t('elearning_iabm.recipient') }}<br/>
                               {{ $i18n.locale == 'en' ? data.trainer.name : data.trainer.name_bn }}<br/>
                               {{ $i18n.locale == 'en' ? data.trainer.professional_info.designation_en : data.trainer.professional_info.designation_bn  }}<br/>
                               {{ $i18n.locale == 'en' ? data.trainer.professional_info.office_name : data.trainer.professional_info.office_name_bn }}
                            </td>
                            <td style="width: 32%" v-else-if="(data.trainer.professional_info.profession_type == 2 || 3) && data.trainer.professional_info.not_here_office == 0 && data.trainer.professional_info.not_here_designation == 0" class="text-center">
                               {{ $t('elearning_iabm.recipient') }}<br/>
                               {{ $i18n.locale == 'en' ? data.trainer.name : data.trainer.name_bn }}<br/>
                               {{ getDesignation(data.trainer.professional_info.designation_id) }}<br/>
                               {{ getOfficeType(data.trainer.professional_info.office_type_id) }}<br/>
                               {{ getOffice(data.trainer.professional_info.office_id) }}
                            </td>
                            <td style="width: 32%" v-else  class="text-center">
                               {{ $t('elearning_iabm.recipient') }}<br/>
                               {{ $i18n.locale == 'en' ? data.trainer.name : data.trainer.name_bn }}<br/>
                               {{ $i18n.locale == 'en' ? data.trainer.professional_info.designation_en : data.trainer.professional_info.designation_bn  }}<br/>
                               {{ $i18n.locale == 'en' ? data.trainer.professional_info.office_name : data.trainer.professional_info.office_name_bn }}
                            </td> -->
                            <td style="width: 32%"  class="text-center">
                               {{ $t('elearning_iabm.recipient') }}<br/>
                               {{ $i18n.locale == 'en' ? data.history.name : data.history.name_bn }}<br/>
                               {{ $i18n.locale == 'en' ? data.history.designation_name : data.history.designation_name_bn  }}<br/>
                               {{ $i18n.locale == 'en' ? data.history.office_name : data.history.office_name_bn }}
                            </td>
                            <td style="width: 32%"  class="text-center">
                              <div v-html="data.signatory_info"></div>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 32%">
                              <div v-html="data.description"></div>
                            </td>
                            <td style="width: 32%">
                            </td>
                          </tr>
                        </tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTrainingRe.vue'
import { invitationShow, invitationPdfDownload, wordExportInvitation } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  created () {
    if (this.$route.query.id) {
      this.getData(this.$route.query.id)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      // loading: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      loadIt: false,
      data: {
        fiscal_year_id: 0,
        batch_no: 0,
        circular_memo_no: '',
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        profession_type: 1,
        org_id: 0,
        personal_info_id: null,
        trainer_course: [],
        trainer: {
          name: '',
          name_bn: '',
          email: ''
        }
      }
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    }
  },
  methods: {
    async exportWord () {
      this.loader = true
      const sendData = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id,
        circular_memo_no: this.data.circular_memo_no,
        batch_no: this.data.batch_no
      }
      const params = Object.assign(
        {
          request_type: 'word',
          local: this.$i18n.locale,
          org_id: this.orgId,
          id: this.$route.query.id
        },
        sendData
      )

      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      try {
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, wordExportInvitation, params)

        // Create a Blob and object URL
        var blob = new Blob([result], {
          type: 'application/msword' // Use 'application/msword' for Word documents
        })

        var url = window.URL.createObjectURL(blob)

        // Create a temporary link element to trigger the download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'speaker_invitation.docx' // Set the desired filename with .docx extension for Word documents
        this.loader = false
        // Trigger the download and cleanup
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        // Handle any errors here
      } finally {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
    async getData (id) {
      this.loadIt = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, invitationShow + '/' + id)
      if (result.success) {
        this.data = result.data
        const listData = this.data.trainer_course.map(item => {
          const sessionTimeInMinutes = item.session_hour
          const hours = Math.floor(sessionTimeInMinutes / 60)
          const minutes = sessionTimeInMinutes % 60
          // const formattedSessionTime = hours > 0 ? `${hours}` + this.$i18n.locale === 'bn' ? 'hrs' : ' hr' : `${minutes}`
          return { ...item, hours: hours, minutes: minutes }
      })
       this.data.trainer_course = listData
        this.loadIt = false
      } else {
        this.loadIt = false
      }
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getTrainingTitle (id) {
      const obj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1).find(item => item.value === parseInt(id))
      if (obj === undefined) return ''
      return this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en
    },
    getDesignation (id) {
      const obj = this.$store.state.commonObj.designationList.find(item => item.value === id)
      if (obj === undefined) return ''
      return this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en
    },
    getOfficeType (id) {
      const obj = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0).find(item => item.value === parseInt(id))
      if (obj === undefined) return ''
      return this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en
    },
    getOffice (id) {
      const obj = this.$store.state.commonObj.officeList.filter(item => item.status === 0).find(item => item.value === parseInt(id))
      if (obj === undefined) return ''
      return this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en
    },
    getOrg (id) {
      const obj = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0).find(item => item.value === parseInt(id))
      if (obj === undefined) return ''
      return this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en
    },
    async pdfExport () {
      const sendData = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id,
        circular_memo_no: this.data.circular_memo_no,
        batch_no: this.data.batch_no
      }
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: this.orgId, id: this.$route.query.id }, sendData)

      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, invitationPdfDownload, params)

        // Create a Blob and object URL
        var blob = new Blob([result], {
          type: 'application/pdf'
        })

        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async pdfDownloadExport () {
      const sendData = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id,
        circular_memo_no: this.data.circular_memo_no,
        batch_no: this.data.batch_no
      }
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: this.orgId, id: this.$route.query.id }, sendData)

      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      try {
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, invitationPdfDownload, params)

        // Create a Blob and object URL
        var blob = new Blob([result], {
          type: 'application/pdf'
        })

        var url = window.URL.createObjectURL(blob)

        // Create a temporary link element to trigger the download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'your_pdf_filename.pdf' // Set the desired filename

        // Trigger the download and cleanup
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        // Handle any errors here
      } finally {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    }
  }
}
</script>
