<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_config.schedule_setup') }}
              <slot v-if="$route.query.id">
                {{ $t('globalTrans.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="training-schedule" :class="'btn btn-success text-light'">{{ $t('elearning_config.schedule_setup') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-overlay :show="load">
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form" @submit.prevent="handleSubmit(addAll)" @reset.prevent="reset" >
                      <!-- <b-overlay :show="load"> -->
                        <b-row>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                </template>
                                <v-select
                                      id="fiscal_year_id"
                                      v-model="formData.fiscal_year_id"
                                      :reduce="op => op.value"
                                      :options="fiscalYearList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider  v-if="$store.state.Auth.activeRoleId === 1" name="Organization" vid="org_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('elearning_config.organization')}} <span class="text-danger">*</span>
                                </template>
                                <v-select
                                      id="org_id"
                                      v-model="formData.org_id"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.training_type_id"
                                  :options="trainingTypeList"
                                  id="training_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="training_type_id"
                                      v-model="formData.training_type_id"
                                      :reduce="op => op.value"
                                      :options="trainingTypeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_category_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="training_category_id"
                                      v-model="formData.training_category_id"
                                      :reduce="op => op.value"
                                      :options="trainingCategoryList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_title_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="training_title_id"
                                      v-model="formData.training_title_id"
                                      :reduce="op => op.value"
                                      :options="trainingTitleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Batch" vid="batch_no" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="batch_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.batch_no"
                                    :options="batchList"
                                    id="batch_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="circular_memo_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.circular_memo_no"
                                    :options="circularList"
                                    id="circular_memo_no"
                                    disabled
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Schedule Setup"  vid="training_schedule_setup_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_schedule_setup_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('elearning_config.topic_event') }} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.training_schedule_setup_id"
                                  :options="trainingScheduleSetup"
                                  id="training_schedule_setup_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select> -->
                                <v-select
                                      id="training_schedule_setup_id"
                                      v-model="formData.training_schedule_setup_id"
                                      :reduce="op => op.value"
                                      :options="trainingScheduleSetup"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12" v-if="formData.training_schedule_setup_id === 81">
                            <ValidationProvider name="Topic Event (En)" vid="topic_event">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="topic_event"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('elearning_config.topic_event_en') }}
                              </template>
                              <b-form-input
                                id="topic_event"
                                v-model="formData.topic_event"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12" v-if="formData.training_schedule_setup_id === 81">
                            <ValidationProvider name="Topic Event (Bn)" vid="topic_event_bn">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="topic_event_bn"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('elearning_config.topic_event_bn') }}
                              </template>
                              <b-form-input
                                id="topic_event_bn"
                                v-model="formData.topic_event_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <!-- <b-col lg="6" sm="6" v-show="formData.training_schedule_setup_id === 5 || 2">
                            <ValidationProvider name="Title" vid="other_title">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="other_title"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('globalTrans.title')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="other_title"
                                  v-model="formData.other_title"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-show="formData.training_schedule_setup_id === 5 || 2">
                            <ValidationProvider name="Title Bn" vid="other_title_bn">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="other_title_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('globalTrans.title_bn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="other_title_bn"
                                  v-model="formData.other_title_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col> -->
                          <b-col lg="6" sm="6" v-if="formData.training_schedule_setup_id === 3">
                            <ValidationProvider name="Schedule Type"  vid="trainer_other">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="trainer_other"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('elearning_config.trainer_other') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.trainer_other"
                                  :options="sheduleType"
                                  id="trainer_other"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-if="formData.trainer_other === 'Other' && formData.training_schedule_setup_id !== 2">
                            <ValidationProvider name="Other Name" vid="other_name" >
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="other_name"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.speaker_facilitator_e')}} {{ '(' }}{{ $t('globalTrans.en') }}{{ ')' }}
                                  </template>
                                  <b-form-input
                                  id="other_name"
                                  v-model="formData.other_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-if="formData.trainer_other === 'Other' && formData.training_schedule_setup_id !== 2">
                            <ValidationProvider name="Other Name Bn" vid="other_name_bn">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="other_name_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.speaker_facilitator_e')}} {{ '(' }}{{ $t('globalTrans.bn') }}{{ ')' }}
                                  </template>
                                  <b-form-input
                                  id="other_name_bn"
                                  v-model="formData.other_name_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-if="formData.training_schedule_setup_id === 2">
                            <ValidationProvider name="Guest Trainer"  vid="trainer_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="trainer_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_tpm.guest_trainer')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.trainer_id"
                                  :options="guestTrainerList"
                                  id="trainer_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value=0>{{loading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-if="formData.training_schedule_setup_id !== 3">
                            <ValidationProvider name="Training Date" vid="training_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('globalTrans.select_date') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_date"
                                  :options="selectDate"
                                  id="training_date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value=0>{{loading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Start Time" vid="start_time" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="start_time"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('globalTrans.start_time') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    type="time"
                                    v-model="formData.start_time"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="End Time" vid="end_time" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="end_time"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('globalTrans.end_time') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    type="time"
                                    v-model="formData.end_time"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Vanue Name (En)" vid="venu_name">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="venu_name"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('elearning_config.vanue_name_en') }}
                              </template>
                              <b-form-input
                                id="venu_name"
                                v-model="formData.venue"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Vanue Name (Bn)" vid="venu_name_bn">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="venu_name_bn"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('elearning_config.vanue_name_bn') }}
                              </template>
                              <b-form-input
                                id="venu_name_bn"
                                v-model="formData.venue_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="12" sm="12">
                            <b-button v-if="!scheduleListData" type="submit" variant="primary" class="btn btn-sm btn-primary float-right"><i class="fa fa-plus" aria-hidden="true"></i>{{ editIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add_schedule')}}</b-button>
                          </b-col>
                        </b-row>
                        <b-row v-if="!scheduleListData">
                          <div class="col-md-12" v-if="trainingCalendar.training_start_date && trainingCalendar.training_end_date">
                            <fieldset class="p-2 w-100">
                              <legend class="px-2 w-50 shadow-sm">{{ $t('elearning_tpm.training_calendar') }}</legend>
                                <table class="table" style="width:100%">
                                  <tr>
                                    <th style="width: 20%">{{$t('elearning_iabm.training_start_date')}}</th>
                                    <th style="width: 5%">:</th>
                                    <th style="width: 25%">
                                      <slot v-if="trainingCalendar.training_start_date">
                                        {{trainingCalendar.training_start_date | dateFormat}}
                                      </slot>
                                    </th>
                                    <th style="width: 25%">{{$t('elearning_iabm.training_end_date')}}</th>
                                    <th style="width: 5%">:</th>
                                    <th style="width: 25%">
                                      <slot v-if="trainingCalendar.training_end_date">
                                        {{trainingCalendar.training_end_date | dateFormat}}
                                      </slot>
                                    </th>
                                    <th></th>
                                  </tr>
                                </table>
                            </fieldset>
                          </div>
                        </b-row>
                      <!-- </b-overlay> -->
                    </b-form>
                  </ValidationObserver>
                  <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form1" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row v-if="!scheduleListData">
                        <div class="col-md-12" id="form">
                          <fieldset class="p-2 w-100">
                            <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.training_schedule')}} {{$t('globalTrans.list')}}</legend>
                            <table class="table" style="width:100%">
                              <thead class="thead">
                                <tr>
                                  <th style="width:15%" class="text-left">{{$t('globalTrans.date')}}</th>
                                  <th style="width:15%" class="text-left">{{$t('globalTrans.time')}}</th>
                                  <th style="width:20%" class="text-left">{{$t('elearning_config.topics_session_e')}}</th>
                                  <th style="width:18%" class="text-left">{{$t('elearning_config.speaker_facilitator_e')}}</th>
                                  <th style="width:22%" class="text-left">{{ $t('elearning_config.vanue_name') }}</th>
                                  <th style="width:10%" class="text-left"></th>
                                </tr>
                              </thead>
                              <template v-if="scheduleList.length === 0">
                                <tr>
                                  <th colspan="4" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                </tr>
                              </template>
                              <template v-else>
                                <tr v-for="(schedule,index) in scheduleList" :key="index">
                                  <td>{{schedule.training_date | dateFormat}}</td>
                                  <td>{{schedule.start_time | time12FormateTraining}} {{formattedTime(schedule.start_time)}} - {{schedule.end_time | time12FormateTraining}} {{formattedTime(schedule.start_time)}}</td>
                                  <td>
                                    {{ $i18n.locale === 'bn' ? schedule.topics_session_bn : schedule.topics_session }}
                                  </td>
                                  <td style="text-align: left;">
                                    <template class="p-0">{{ $i18n.locale === 'bn' ? schedule.speaker_bn : schedule.speaker }}</template><slot v-if="schedule.trainer_id">{{ ',' }}</slot>
                                    <template v-if="schedule.trainer_id">
                                      {{ getWorkPlace(schedule.personal_data.professional_info) }}{{ getOfficeData(schedule.personal_data.professional_info, 'admin') }}
                                      <!-- <designation :item="schedule.personal_data.professional_info" type="admin"/>
                                      <workplace :item="schedule.personal_data.professional_info" type="admin"/> -->
                                    </template>
                                  </td>
                                  <td>
                                    <span v-if="schedule.training_schedule_setup_id !== 3">
                                      {{ $i18n.locale === 'bn' ? schedule.venue_bn : schedule.venue }}
                                    </span>
                                    <b-form-input
                                      v-if="schedule.training_schedule_setup_id === 3"
                                      id="venu_name_bn"
                                      v-model="schedule.venue"
                                      :placeholder="$t('elearning_config.vanue_name_en')"
                                    ></b-form-input>
                                    <b-form-input
                                      v-if="schedule.training_schedule_setup_id === 3"
                                      class="mt-2"
                                      id="venu_name_bn"
                                      v-model="schedule.venue_bn"
                                      :placeholder="$t('elearning_config.vanue_name_bn')"
                                    ></b-form-input>
                                  </td>
                                  <td v-if="schedule.flag_status === 1 || flag_status === 1">
                                    <b-button @click="editItem(schedule, index)" title="Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                    <!-- <b-button type="button" variant="danger" class="btn btn-sm btn-danger float-right" @click="scheduleList.splice(index, 1)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                                    <b-button type="button" variant="danger" class="btn btn-sm btn-danger float-right" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                  </td>
                                </tr>
                              </template>
                            </table>
                          </fieldset>
                        </div>
                      </b-row>
                      <span v-if="scheduleListData && formData.circular_memo_no">
                        <h3 class="text-center text-danger">{{$t('elearning_tpm.circular_schedule_text')}}</h3>
                      </span>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <!-- <b-button type="button" variant="primary" @click="createData" class="mr-2">{{ saveBtnName }}</b-button> -->
                          <b-button type="submit" v-if="!scheduleListData" @click="flag_status = 1" variant="success" class="mr-1">{{ $t('globalTrans.draft') }}</b-button>
                          <b-button type="submit" v-if="!scheduleListData" @click="flag_status = 2" variant="primary" class="mr-1">{{ $t('globalTrans.finalSave') }}</b-button>
                          <router-link to="training-schedule" class="btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-overlay>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- <pre>{{scheduleList}}</pre> -->
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingScheduleStore, trainingScheduleUpdate, trainingScheduleList, circularList, scheduleCircularData } from '../../api/routes'
import flatpickr from 'flatpickr'
import { isNotEmpty } from '@amcharts/amcharts4/.internal/core/utils/Utils'
// import designation from './../../../component/designation'
// import workplace from './../../../component/workplace'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
    // designation,
    // workplace
  },
  created () {
    if (this.$route.query.circular_memo_no && this.$route.query.batch_no) {
      this.getscheduleCircular()
    } else {
      this.formData = Object.assign({}, this.formData, {
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.formData.org_id = this.$store.state.Auth.authUser.org_id
        if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
          this.formData.coordinator_id = this.$store.state.Auth.authUser.user_id
        }
      }
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      load: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      flag_status: 1,
      formData: {
        sheduleId: 0,
        coordinator_id: 0,
        circular_memo_no: '',
        batch_no: 0,
        fiscal_year_id: 0,
        org_id: '',
        training_category_id: '',
        training_type_id: '',
        training_title_id: '',
        trainer_other: 'Other',
        other_title_bn: '',
        other_title: '',
        other_name: '',
        other_name_bn: '',
        training_schedule_setup_id: '',
        topic_event: '',
        topic_event_bn: '',
        trainer_id: 0,
        training_date: 0,
        start_time: '',
        end_time: ''
      },
      editIndex: '',
      trainers: [],
      scheduleRemove: [],
      scheduleListData: false,
      scheduleList: [],
      trainerLoading: false,
      trainingTypeList: [],
      trainingCategoryList: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      guestTrainerListData: [],
      trainingTitleList: [],
      batchList: [],
      allBatchListData: [],
      listBatch: [],
      circularList: [],
      trainingCalendar: {
        training_start_date: '',
        training_end_date: ''
      },
      courseModuleError: '',
      circularLoading: false,
      moduleLoading: false,
      trainerErrors: [],
      selectDate: [],
      myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    guestTrainerList: function () {
      const listObject = this.guestTrainerListData
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    trainingScheduleSetup: function () {
      // return this.$store.state.TrainingElearning.commonObj.trainingScheduleSetup.filter(item => item.value !== 3)
      return this.$store.state.TrainingElearning.commonObj.trainingScheduleSetup.filter(item => item.org_id === this.formData.org_id)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    sheduleType: function () {
      return this.$store.state.TrainingElearning.commonObj.sheduleType
    },
    courseList: function () {
      return this.$store.state.TrainingElearning.commonObj.courseList
    },
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    }
  },
  watch: {
    'formData.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingTypeList = this.getTypeList(newValue)
      } else {
        this.trainingTypeList = []
      }
    },
    'formData.training_type_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      } else {
        this.trainingCategoryList = []
      }
    },
    'formData.training_category_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      } else {
        this.trainingTitleList = []
      }
    },
    'formData.fiscal_year_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getCircularList()
        this.getSheduleList()
      }
    },
    'formData.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCircularList()
      }
    },
    'formData.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.batch_no = allBatchListData.batch_no
        }
        this.getSheduleList()
      }
    },
    'formData.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.circular_memo_no = allBatchListData.circular_memo_no
        }
      }
    }
  },
  methods: {
    formattedTime (item) {
      // Split the time string into hours, minutes, and seconds
      const [hours] = item.split(':')

      // Convert hours to 12-hour format and determine "AM" or "PM"
      const ampm = hours >= 12 ? 'PM' : 'AM'

      // Construct the formatted time string
      const formattedTime = ampm
      return formattedTime
    },
    getWorkPlace (item) {
      if (parseInt(item.profession_type) === 1 && item.not_here_office === 0) {
        return ',' + this.getOfficeName(item.office_id)
      } else if (parseInt(item.profession_type) === 1 && item.not_here_office === 1) {
        return ',' + ((this.$i18n.locale === 'bn' ? item.office_name_bn : item.office_name))
      } else if (item.not_here_office === 1) {
        return ',' + ((this.$i18n.locale === 'bn' ? item.office_name_bn : item.office_name))
      } else {
        return ''
      }
    },
    getOfficeName (id) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    getOfficeData (item, type) {
      if (parseInt(item.not_here_designation) === 0 && parseInt(item.profession_type) === 1) {
        let desigObj
        if (type === 'admin') {
          desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === item.designation_id)
        }
        if (type === 'panel') {
          desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === item.designation_id)
        }
        if (typeof desigObj !== 'undefined') {
          return this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
        } else {
          return ''
        }
      } else {
        return this.$i18n.locale === 'en' ? item.designation_en : item.designation_bn
      }
    },
    remove (index) {
        window.vm.$swal({
        title: window.vm.$t('globalTrans.statusDeleteChangeMsg'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.scheduleList.splice(index, 1)
        }
      })
    },
    getTypeList (orgId) {
      if (this.formData.org_id) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      }
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.formData.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.formData.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.formData.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getscheduleCircular () {
      this.load = true
      const serchData = {
        circular_memo_no: this.$route.query.circular_memo_no,
        batch_no: this.$route.query.batch_no
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, scheduleCircularData, serchData)
      if (result.success) {
        this.formData.fiscal_year_id = result.data.fiscal_year_id
        this.formData.org_id = result.data.org_id
        this.formData.training_type_id = result.data.training_type_id
        this.formData.training_category_id = result.data.training_category_id
        this.formData.training_title_id = result.data.training_title_id
        this.formData.circular_memo_no = result.data.circular_memo_no
        this.formData.batch_no = result.data.batch_no
        this.allBatchListData = result.batchList
        this.getSheduleList()
        this.load = false
      } else {
        this.load = false
      }
    },
    async getCircularList () {
      if (this.formData.fiscal_year_id && this.formData.training_title_id) {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.formData.fiscal_year_id,
          training_type_id: this.formData.training_type_id,
          training_category_id: this.formData.training_category_id,
          training_title_id: this.formData.training_title_id,
          coordinator_id: this.formData.coordinator_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
          this.allBatchListData = []
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      }
    },
    // async getCircularList () {
    //   if (this.formData.fiscal_year_id && this.formData.training_title_id !== 0) {
    //     this.circularLoading = true
    //     const serchData = {
    //       fiscal_year_id: this.formData.fiscal_year_id,
    //       training_type_id: this.formData.training_type_id,
    //       training_category_id: this.formData.training_category_id,
    //       training_title_id: this.formData.training_title_id
    //     }
    //     const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
    //     if (!result.success) {
    //       this.circularList = []
    //       this.allBatchListData = []
    //     } else {
    //       this.allBatchListData = result.batchList
    //       const listObject = result.data
    //       const tmpList = listObject.map((obj, index) => {
    //         if (this.$i18n.locale === 'bn') {
    //           return { value: obj.circular_memo_no, text: obj.circular_memo_no }
    //         } else {
    //           return { value: obj.circular_memo_no, text: obj.circular_memo_no }
    //         }
    //       })
    //       this.circularList = tmpList
    //     }
    //     this.circularLoading = false
    //   }
    // },
    editItem (item, index) {
      this.formData = Object.assign({}, item, { id: 0 })
      this.editIndex = index
    },
    addAll () {
      let result = []
      if (this.editIndex !== '') {
          const scheduleArray = Object.values(this.scheduleList)
          const editList = [...scheduleArray]
          editList.splice(parseInt(this.editIndex), 1)
          result = [...editList]
        } else {
          result = this.scheduleList
        }
      let topicsSession = ''
      let topicsSessionBn = ''
      if (this.formData.training_schedule_setup_id === 5) {
        topicsSession = this.formData.other_title
        topicsSessionBn = this.formData.other_title_bn
      } else if (this.formData.training_schedule_setup_id === 3) {
        const courseObj = this.$store.state.TrainingElearning.commonObj.courseList.find(doc => doc.value === parseInt(this.formData.course_id))
        topicsSession = courseObj.text_en
        topicsSessionBn = courseObj.text_bn
      } else if (this.formData.training_schedule_setup_id === 81) {
        topicsSession = this.formData.topic_event
        topicsSessionBn = this.formData.topic_event_bn
      } else {
        const sheduleType = this.$store.state.TrainingElearning.commonObj.trainingScheduleSetup.find(sheduleType => sheduleType.value === parseInt(this.formData.training_schedule_setup_id))
        if (typeof sheduleType !== 'undefined') {
          topicsSession = sheduleType.text_en
          topicsSessionBn = sheduleType.text_bn
        }
      }
      let speaker = ''
      let speakerBn = ''
      if (this.formData.trainer_other === 'Other') {
        speaker = this.formData.other_name
        speakerBn = this.formData.other_name_bn
      }
      if (this.formData.training_schedule_setup_id === 2) {
        const trainerObj = this.guestTrainerListData.find(doc => doc.value === parseInt(this.formData.trainer_id))
        if (typeof trainerObj !== 'undefined') {
          speaker = trainerObj.text_en
          speakerBn = trainerObj.text_bn
        }
      }
      const checkData = {
        id: this.formData.sheduleId,
        circular_memo_no: this.formData.circular_memo_no,
        batch_no: this.formData.batch_no,
        fiscal_year_id: this.formData.fiscal_year_id,
        office_type_id: this.formData.office_type_id,
        office_id: this.formData.office_id,
        org_id: this.formData.org_id,
        training_category_id: this.formData.training_category_id,
        training_type_id: this.formData.training_type_id,
        training_title_id: this.formData.training_title_id,
        training_schedule_setup_id: this.formData.training_schedule_setup_id,
        other_title: this.formData.other_title,
        other_title_bn: this.formData.other_title_bn,
        other_name: this.formData.other_name,
        other_name_bn: this.formData.other_name_bn,
        course_id: this.formData.course_id,
        trainer_id: this.formData.trainer_id,
        trainer_other: this.formData.trainer_other,
        training_date: this.formData.training_date,
        start_time: this.formData.start_time,
        end_time: this.formData.end_time,
        venue: this.formData.venue,
        venue_bn: this.formData.venue_bn,
        topics_session: topicsSession,
        topics_session_bn: topicsSessionBn,
        speaker: speaker,
        speaker_bn: speakerBn
      }
      const newData = result.find(item => item.training_date === checkData.training_date && item.training_schedule_setup_id === checkData.training_schedule_setup_id)
      if (typeof newData === 'undefined') {
        if (this.editIndex !== '') {
          const index = parseInt(this.editIndex)
          this.scheduleList.splice(index, 1, { ...this.scheduleList[index], ...checkData })
        } else if (this.formData.sheduleId) {
          this.scheduleList = this.scheduleList.map(item =>
            item.id === this.formData.sheduleId ? { ...item, ...checkData } : item
          )
        } else {
          this.scheduleList.push(checkData)
        }
      } else {
        this.$toast.error({
              title: '!',
              message: 'Item already added',
              color: '#C0EC83'
          })
      }
      // if (this.formData.sheduleId) {
      //   const datas = this.scheduleList.map((item, index) => {
      //     if (item.id === this.formData.sheduleId) {
      //       return Object.assign({}, item, checkData)
      //     } else {
      //       return Object.assign({}, item)
      //     }
      //   })
      //   this.scheduleList = datas
      // } else {
      //   this.scheduleList.push(checkData)
      // }
      this.sheduleReset()
      this.editIndex = ''
    },
    sheduleReset () {
      this.formData.course_id = 0
      this.formData.other_title_bn = ''
      this.formData.other_title = ''
      this.formData.other_name = ''
      this.formData.other_name_bn = ''
      this.formData.training_schedule_setup_id = ''
      this.formData.trainer_id = 0
      // this.formData.training_date = null
      this.formData.start_time = ''
      this.formData.end_time = ''
      this.formData.sheduleId = 0
      this.formData.venue = ''
      this.formData.venue_bn = ''
      this.$refs.form.reset()
    },
    removeAttachment (index, data) {
      this.formData.module_attachments.splice(index, 1)
      if (data.pre_attachment) {
        this.formData.module_attachments_remove.push(data)
      }
    },
    getCategoryList (typeId) {
      if (this.formData.training_type_id) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
      }
    },
    getTrainingTitleList (categoryId) {
      if (this.formData.training_category_id) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      }
    },
    async getSheduleList () {
      if (this.formData.fiscal_year_id && this.formData.batch_no) {
        this.loading = true
        this.load = true
        const params = {
          no_pagination: true,
          fiscal_year_id: this.formData.fiscal_year_id,
          circular_memo_no: this.formData.circular_memo_no,
          batch_no: this.formData.batch_no
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleList, params)
        if (!result.success) {
          this.scheduleList = []
          this.guestTrainerListData = []
          this.loading = false
          this.load = false
        } else {
          if (isNotEmpty(result.data)) {
            if (result.data[0].flag_status === 2) {
              this.scheduleListData = true
            } else {
              this.scheduleList = result.data
              this.scheduleListData = false
            }
          } else {
            this.scheduleListData = false
          }
          this.scheduleList.flag_status = this.flag_status
          if (result.data.length !== 0) {
            // let batchDataList = []
            // result.data.map((item, index) => {
            //   batchDataList = this.allBatchListData.filter(data => data.batch_no === item.batch_no && item.flag_status === 1)
            // })
            // this.listBatch = batchDataList
            this.formData.training_type_id = result.data[0].training_type_id
            this.formData.training_category_id = result.data[0].training_category_id
            this.formData.training_title_id = result.data[0].training_title_id
            this.formData.circular_memo_no = result.data[0].circular_memo_no
            this.formData.batch_no = result.data[0].batch_no
          }
          this.guestTrainerListData = result.guestTrainerList
          const tmpList = result.dateList.map((obj, index) => {
            return { value: obj, text: obj }
          })
          this.selectDate = tmpList
          this.loading = false
          this.load = false
        }
      }
    },
    finalSave () {
      this.$swal({
        title: this.$t('globalTrans.final_save_msg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.flag_status = 2
          this.createData()
        }
      })
    },
    async createData () {
      this.load = true
      let result = null
      const datas = this.scheduleList.map((item, index) => {
        return Object.assign({}, item, { flag_status: this.flag_status })
      })
      this.scheduleList.flag_status = this.flag_status
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainingScheduleUpdate}/${this.formData.id}`, datas)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingScheduleStore, datas)
      }
      this.load = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/tpm/training-schedule')
      } else {
        this.trainerErrors = result.errors
        this.$refs.form1.setErrors(result.errors)
      }
  }
  }
}
</script>
