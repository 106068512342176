<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"> {{ $t('li_step.annual_performance_report') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(loadData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Service Name" vid="service_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="service_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('org_pro.service_namel') }}
                                </template>
                                <b-form-select
                                  plain
                                    v-model="search.service_id"
                                    :options="serviceNamesList"
                                    id="service_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Application Type">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="application_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.type') }}
                            </template>
                            <b-form-select
                              plain
                              v-model="search.application_type"
                              :options="applicationTypeList"
                              id="application_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="From Date" vid="from_date">
                          <b-form-group
                            :label="$t('globalTrans.from_date')"
                            label-for="from_date"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                            {{ $t('globalTrans.from_date') }}
                            </template>
                            <b-form-input
                              class="datepicker"
                              v-model="search.from_date"
                              placeholder="yyyy-mm-dd"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                          <ValidationProvider name="To Date" vid="to_date">
                          <b-form-group
                            :label="$t('globalTrans.to_date')"
                            label-for="to_date"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                            {{ $t('globalTrans.to_date') }}
                            </template>
                            <b-form-input
                              class="datepicker"
                              v-model="search.to_date"
                              placeholder="yyyy-mm-dd"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <!-- <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Fertilizer Name">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="fertilizer_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('dealer_management.fertilizer_name') }}
                            </template>
                            <v-select name="fertilizer_name_id"
                              v-model="search.fertilizer_name_id"
                              label="text"
                              :reduce="item => item.value"
                              :options= fertilizerNamesList
                              :placeholder="$t('globalTrans.select')"
                              :filter-by="myFilter"
                              :state="errors[0] ? false : (valid ? true : null)"
                            />
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6" md="6">
                        <ValidationProvider name="Organization Name" vid="organization_name" >
                          <b-form-group
                            label-for="organization_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('grantDistribution.organization_name') }}
                            </template>
                            <b-form-input
                              plain
                              v-model="search.organization_name"
                              id="organization_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6" md="6">
                        <ValidationProvider name="Mobile" vid="mobile_no" >
                          <b-form-group
                            label-for="mobile_no"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('globalTrans.mobile') }}
                            </template>
                            <b-form-input
                              plain
                              v-model="search.mobile_no"
                              id="mobile_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col> -->
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row v-if="showData">
          <b-col md="12">
            <b-overlay :show="loading">
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('li_step.annual_performance_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                          <b-button variant="warning">
                            <export-excel
                              worksheet="Report Sheet"
                              :title=excelHeaderValue
                              :fields=excelFields
                              :data=excelData
                              :before-generate = "headerDataExcel"
                              name="annual-performance-report.xls">
                              {{ $t('globalTrans.export_excel') }}
                            </export-excel>
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :baseUrl="licenseRegistrationServiceBaseUrl" :url="'/configuration/report-heading/detail'" :org-id="search.org_id">
                                        {{ $t('li_step.annual_performance_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-row class="mb-3">
                                        <b-col md="6" class="text-left">
                                          {{ $t('org_pro.service_namel') }}: <strong>{{ search.service_id ? getServiceName(search.service_id) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.type') }}: <strong>{{ search.application_type ? getApplicationTypeName(search.application_type) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.from_date') }} : <strong>{{ search.from_date ? dateFormatChange(search.from_date) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.to_date') }} : <strong>{{ search.to_date ? dateFormatChange(search.to_date) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <!-- <b-col md="6" class="text-left">
                                          {{ $t('dealer_management.fertilizer_name') }} : <strong>{{ search.fertilizer_name_id ? getColumnName($store.state.licenseRegistration.commonObj.fertilizerName, search.fertilizer_name_id) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('grantDistribution.organization_name') }}: <strong>{{ search.organization_name ? search.organization_name : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.mobile') }}: <strong>{{ search.mobile_no ? search.mobile_no : $t('globalTrans.all') }}</strong>
                                        </b-col> -->
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col md="12" class="table-responsive">
                                      <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="listData.length">
                                        <b-thead>
                                          <b-tr>
                                            <b-th class="text-center" width="7%">{{$t('globalTrans.sl_no')}}</b-th>
                                            <b-th class="text-center" width="12%">{{$t('globalTrans.month')}}</b-th>
                                            <b-th class="text-center" width="15%">{{$t('li_step.registration_type')}}</b-th>
                                            <b-th class="text-center" width="20%">{{$t('li_step.application_form_submission')}}</b-th>
                                            <b-th class="text-center" width="15%">{{$t('li_step.application_disposal')}}</b-th>
                                            <b-th class="text-center" width="15%">{{$t('li_step.percentage')}}</b-th>
                                            <b-th class="text-center">{{ $t('globalTrans.comments') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                          <template v-if="listData.length">
                                            <slot v-for="(monthItem, monthItemIndex) in listData">
                                              <b-tr v-for="(item, index) in monthItem.service_info" :key="`m-${monthItemIndex}-i-${index}`">
                                                <b-td class="text-center">
                                                  {{ $n(item.serial, { useGrouping: false }) }}
                                                </b-td>
                                                <b-td class="text-center">
                                                  {{ getMonthName(monthItem.month) }}/{{ $n(monthItem.year, { useGrouping: false }) }}
                                                </b-td>
                                                <b-td class="text-center">
                                                  {{ getServiceName(item.service_id) }}
                                                </b-td>
                                                <b-td class="text-center">
                                                  {{ $n(item.total_application) }}
                                                </b-td>
                                                <b-td class="text-center">
                                                  {{ $n(item.total_approved_application) }}
                                                </b-td>
                                                <b-td class="text-center">
                                                  {{ $n(item.percentage, { useGrouping: false }) }}
                                                </b-td>
                                                <b-td class="text-center"></b-td>
                                              </b-tr>
                                              <b-tr :key="`mt-${monthItemIndex}`">
                                                <b-td class="text-center" colspan="3">
                                                  {{ $t('globalTrans.total') }}
                                                </b-td>
                                                <b-td class="text-center">
                                                  {{ $n(monthItem.total_application) }}
                                                </b-td>
                                                <b-td class="text-center">
                                                  {{ $n(monthItem.total_approved_application) }}
                                                </b-td>
                                                <b-td class="text-center">
                                                  {{ $n(monthItem.percentage, { useGrouping: false }) }}
                                                </b-td>
                                                <b-td class="text-center"></b-td>
                                              </b-tr>
                                            </slot>
                                          </template>
                                          <b-tr v-else>
                                            <b-td colspan="7" class="text-center">
                                              {{ $t('globalTrans.noDataFound') }}
                                            </b-td>
                                          </b-tr>
                                        </b-tbody>
                                        <b-tfoot v-if="listData.length" class="font-weight-bold">
                                          <b-tr>
                                            <b-td colspan="3" class="text-center">
                                              {{ $t('globalTrans.total') }}
                                            </b-td>
                                            <b-td>
                                              {{ $n(grandTotalInfo.grand_total_application) }}
                                            </b-td>
                                            <b-td>
                                              {{ $n(grandTotalInfo.grand_total_approved_application) }}
                                            </b-td>
                                            <b-td>
                                              {{ $n(grandTotalInfo.total_percentage) }}
                                            </b-td>
                                            <b-td></b-td>
                                          </b-tr>
                                        </b-tfoot>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.table thead th, td {
  vertical-align: middle !important;
  text-align: center;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { daeAnnualPerformanceReport } from '../../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadLrcpnOn.vue'
import ExportPdf from './export_pdf'
import { helpers } from '@/mixins/helper-functions.js'
import flatpickr from 'flatpickr'
import { dateFormat } from '@/Utils/fliter'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  props: [],
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      search: {
        org_id: 2,
        service_id: 0,
        year: 0,
        from_date: '',
        to_date: '',
        application_type: 0,
        fertilizer_name_id: null,
        organization_name: '',
        mobile_no: '',
        paid_type: 0
      },
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      listData: [],
      grandTotalInfo: {},
      showData: false,
      last_tree_month: this.$t('step_assign.last_tree_month'),
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      },
      xcelColumnBn: {},
      excelColumn: {},
      headerExcelDefault: {
        orgName: null,
        orgNameBn: null,
        address: null,
        address_bn: null
      }
    }
  },
  computed: {
    excelHeaderValue: function () {
      const headerVal = []
      if (this.$i18n.locale === 'en') {
        headerVal[0] = "Government of the People's Republic of Bangladesh"
        headerVal[1] = this.headerExcelDefault.orgName
        headerVal[2] = this.headerExcelDefault.address
        headerVal[3] = ''
        headerVal[4] = this.$t('li_step.annual_performance_report', 'en')
        headerVal[5] = ''
        headerVal[6] = ''
        headerVal[7] = ''
        headerVal[8] = ''
        headerVal[9] = ''
      } else {
        headerVal[0] = 'গণপ্রজাতন্ত্রী বাংলাদেশ সরকার'
        headerVal[1] = this.headerExcelDefault.orgNameBn
        headerVal[2] = this.headerExcelDefault.address_bn
        headerVal[3] = ''
        headerVal[4] = this.$t('li_step.annual_performance_report', 'bn')
        headerVal[5] = ''
        headerVal[6] = ''
        headerVal[7] = ''
        headerVal[8] = ''
        headerVal[9] = ''
      }

      const service = this.$t('org_pro.service_namel') + ' : ' + (this.search.service_id ? this.getServiceName(this.search.service_id) : this.$t('globalTrans.all')) + ';   '
      const type = this.$t('globalTrans.type') + ' : ' + (this.search.application_type ? this.getApplicationTypeName(this.search.application_type) : this.$t('globalTrans.all')) + ';   '
      const fromDate = this.$t('globalTrans.from_date') + ' : ' + (this.search.from_date ? this.dateFormatChange(this.search.from_date) : this.$t('globalTrans.all'))
      const toDate = this.$t('globalTrans.to_date') + ' : ' + (this.search.from_date ? this.dateFormatChange(this.search.to_date) : this.$t('globalTrans.all'))

      headerVal[6] = service + type
      headerVal[7] = fromDate + toDate
      return headerVal
    },
    excelFields: function () {
      if (this.$i18n.locale === 'bn') {
        return {
          'ক্রমিক সংখ্যা': 'serial',
          'মাস ': 'month',
          'নিবন্ধনের ধরন ': 'service',
          'আবেদন পত্র জমা ': 'total_application',
          'আবেদন নিষ্পত্তি ': 'total_approved_application',
          'শতকরা ': 'percentage',
          'মন্তব্য ': 'comments'
        }
      } else {
        return {
          'SL No': 'serial',
          'Month ': 'month',
          'Type of Registration ': 'service',
          'Application Form Submission ': 'total_application',
          'Application Disposal ': 'total_approved_application',
          'Percentage ': 'percentage',
          'Comments ': 'comments'
        }
      }
    },
    excelData: function () {
      const excelData = []
      if (this.listData.length) {
        this.listData.forEach((monthItem, monthItemIndex) => {
          monthItem.service_info.forEach((item, index) => {
            excelData.push({
              serial: this.$n(item.serial),
              month: this.getMonthName(monthItem.month) + '/' + this.$n(monthItem.year, { useGrouping: false }),
              service: this.getServiceName(item.service_id),
              total_application: this.$n(item.total_application),
              total_approved_application: this.$n(item.total_approved_application),
              percentage: this.$n(item.percentage),
              comments: ''
            })
          })
          excelData.push({
            serial: '',
            month: this.$t('globalTrans.total'),
            service: '',
            total_application: this.$n(monthItem.total_application),
            total_approved_application: this.$n(monthItem.total_approved_application),
            percentage: this.$n(monthItem.percentage),
            comments: ''
          })
        })
        excelData.push({
          serial: '',
          month: this.$t('globalTrans.total'),
          service: '',
          total_application: this.$n(this.grandTotalInfo.grand_total_application),
          total_approved_application: this.$n(this.grandTotalInfo.grand_total_approved_application),
          percentage: this.$n(this.grandTotalInfo.total_percentage),
          comments: ''
        })
      }
      return excelData
    },
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
        return this.$store.state.commonObj.loading
    },
    orgList () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    serviceNamesList () {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => (item.org_id === 2))
    },
    fertilizerNamesList () {
      return this.$store.state.licenseRegistration.commonObj.fertilizerName
    },
    yearList () {
      return helpers.getYearListCurrent({ yearFrom: 2021 })
    },
    monthList () {
      return this.$store.state.commonObj.monthList
    },
    applicationTypeList () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'New' : 'নতুন', text_en: 'New', text_bn: 'নতুন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Reneiw' : 'রিনিউ', text_en: 'Reneiw', text_bn: 'রিনিউ' }
      ]
      return list
    },
    paymentTypeList () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online',
          text_en: 'Online',
          text_bn: 'অনলাইন'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline',
          text_en: 'Offline',
          text_bn: 'অফলাইন'
        }
      ]
    },
    authOrgId () {
      const authUser = this.$store.state.Auth.authUser
      const activeRoleId = this.$store.state.Auth.activeRoleId
      return activeRoleId !== 1 ? authUser.org_id : 0
    }
  },
  watch: {
    'search.org_id': function (newVal) {
      if (newVal) {
        this.serviceNamesList = this.getServiceList(newVal)
      } else {
        this.serviceNamesList = []
      }
    }
  },
  created () {
    if (this.authOrgId) {
      this.search.org_id = this.authOrgId
    }
  },
  mounted () {
    core.index()
    flatpickr('.datepicker', {})
    this.headerDataExcel()
  },
  methods: {
    dateFormatChange (data) {
      return dateFormat(data)
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    pdfExport () {
      const reportTitle = this.$t('li_step.annual_performance_report')
      ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', this.search.org_id, reportTitle, this)
    },
    headerDataExcel () {
      RestApi.getData(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail/3').then(response => {
        if (response.success) {
          const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 3)
          const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
          const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
          this.headerExcelDefault.orgName = orgName
          this.headerExcelDefault.orgNameBn = orgNameBn
          this.headerExcelDefault.address = response.data.address
          this.headerExcelDefault.address_bn = response.data.address_bn
        }
      })
    },
    async loadData () {
      this.showData = true
      this.listData = []
      this.grandTotalInfo = {}
      this.$store.commit('mutateCommonProperties', {
          loading: true
      })
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, daeAnnualPerformanceReport, this.search)
      if (result.success) {
        this.$store.commit('mutateCommonProperties', {
          loading: false
        })
        this.listData = result.data
        this.grandTotalInfo = result.total_info
      } else {
        this.listData = []
        this.grandTotalInfo = {}
        this.$store.commit('mutateCommonProperties', {
          loading: false
        })
      }
    },
    getServiceList (orgId) {
      const tmpService = this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => (item.org_id === orgId))
      return tmpService.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en })
      })
    },
    getOrganizationName (id) {
      const obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getServiceName (id) {
      const obj = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getApplicationTypeName (id) {
      const obj = this.applicationTypeList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getPaymentTypeName (id) {
      const obj = this.paymentTypeList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getMonthName (monthNo) {
      return helpers.getMonthName(monthNo)
    }
  }
}
</script>
<style >
.table-bordered th{
border: 1px solid #000 !important;
}
</style>
