<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                      plain
                                      v-model="formData.fiscal_year_id"
                                      :options="fiscalYearList"
                                      id="fiscal_year_id"
                                      @change="batch()"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="fiscal_year_id"
                                      v-model="formData.fiscal_year_id"
                                      :reduce="op => op.value"
                                      :options="fiscalYearList"
                                      @input="batch()"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1" v-if="$store.state.Auth.activeRoleId === 1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                      plain
                                      v-model="formData.org_id"
                                      :options="orgList"
                                      id="org_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="org_id"
                                      v-model="formData.org_id"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="office_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                      plain
                                      v-model="formData.office_type_id"
                                      :options="officeTypeList"
                                      id="office_type_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :disabled="fieldDisabled"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="office_type_id"
                                      v-model="formData.office_type_id"
                                      :reduce="op => op.value"
                                      :options="officeTypeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :disabled="fieldDisabled"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="office_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                      plain
                                      v-model="formData.office_id"
                                      :options="officeList"
                                      id="office_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :disabled="fieldDisabled"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="office_id"
                                      v-model="formData.office_id"
                                      :reduce="op => op.value"
                                      :options="officeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :disabled="fieldDisabled"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                  plain
                                  v-model="formData.training_type_id"
                                  :options="trainingTypeList"
                                  id="training_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="training_type_id"
                                      v-model="formData.training_type_id"
                                      :reduce="op => op.value"
                                      :options="trainingTypeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_category_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                  plain
                                  v-model="formData.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="training_category_id"
                                      v-model="formData.training_category_id"
                                      :reduce="op => op.value"
                                      :options="trainingCategoryList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_title_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                  plain
                                  v-model="formData.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  @change="batch()"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="training_title_id"
                                      v-model="formData.training_title_id"
                                      :reduce="op => op.value"
                                      :options="trainingTitleList"
                                      @input="batch()"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Batch NO" vid="batch_no" rules="required" v-if="formData.org_id !== 16">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="batch_no"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  v-model="formData.batch_no"
                                  disabled
                                  id="batch_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  </b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="circular_memo_no"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="circular_memo_no"
                                    v-model="formData.circular_memo_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                                <p class="text-danger pt-2 pb-0"><b>{{ $t('elearning_iabm.circular_memo_note')}}</b></p>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider v-if="$store.state.Auth.authUser.org_id === 16 || formData.org_id === 16 || $store.state.Auth.authUser.org_id === 19 || formData.org_id === 19" name="Other Trainee Type" vid="other_trainee_type_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="other_trainee_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('elearning_config.other_trainee_type') }} <span class="text-danger">*</span>
                                </template>
                                <v-select
                                  id="other_trainee_type_id"
                                  v-model="formData.other_trainee_type_id"
                                  multiple
                                  :reduce="op => op.value"
                                  :options="otherTraineeTypeList"
                                  label="text"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="District" vid="district_id" rules="required|min_value:1" v-if="$store.state.Auth.authUser.org_id === 16 || formData.org_id === 16">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="district_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.district_id"
                                      :options="districtList"
                                      id="district_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Upazila" vid="upazila_id" rules="required|min_value:1" v-if="$store.state.Auth.authUser.org_id === 16 || formData.org_id === 16">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="upazila_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('globalTrans.upazila')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.upazila_id"
                                      :options="upazilaList"
                                      id="upazila_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                             <ValidationProvider name="Total No of Participant" vid="total_no_of_participant">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="total_no_of_participant"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_iabm.total_no_of_participant')}}
                                  </template>
                                  <b-form-input
                                      id="total_no_of_participant"
                                      v-model="formData.total_no_of_participant"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Application Deadline" vid="application_deadline">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="application_deadline"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_iabm.application_deadline')}}
                                  </template>
                                  <b-form-input
                                      class="fromDate"
                                      v-model="formData.application_deadline"
                                      :placeholder="$t('globalTrans.select_date')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Training Start Date" vid="training_start_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_start_date"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_iabm.training_start_date')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      class="fromDate"
                                      v-model="formData.training_start_date"
                                      @change="daycount()"
                                      :placeholder="$t('globalTrans.select_date')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Training End Date" vid="training_end_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_end_date"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_iabm.training_end_date')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      class="fromDate"
                                      v-model="formData.training_end_date"
                                      @change="daycount()"
                                      :placeholder="$t('globalTrans.select_date')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="No. of Days" vid="no_of_days">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="no_of_days"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_iabm.no_of_days')}}
                                  </template>
                                  <b-form-input
                                      id="no_of_days"
                                      v-model="formData.no_of_days"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <!-- <span v-if="$store.state.Auth.authUser.org_id === 16">
                            <ValidationProvider name="Venue (En)"  vid="venue" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="venue"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.venue')}}
                                  </template>
                                  <b-form-textarea
                                      rows="2"
                                      id="venue"
                                      v-model="formData.venue"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            </span>
                            <span v-if="$store.state.Auth.authUser.org_id === 16">
                            <ValidationProvider name="Venue (Bn)"  vid="venue_bn" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="venue_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.venue_bn')}}
                                  </template>
                                  <b-form-textarea
                                      rows="2"
                                      id="venue_bn"
                                      v-model="formData.venue_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            </span> -->
                            <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="remarks"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.remarks_en')}}
                                  </template>
                                  <b-form-textarea
                                      rows="2"
                                      id="remarks"
                                      v-model="formData.remarks"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="remarks_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.remarks_bn')}}
                                  </template>
                                  <b-form-textarea
                                      rows="2"
                                      id="remarks_bn"
                                      v-model="formData.remarks_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Upload Guidelines" vid="upload_guidelines">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="upload_guidelines"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{ $t('elearning_iabm.upload_guidelines') }}
                                  </template>
                                  <b-form-file
                                    id="upload_guidelines"
                                    v-model="formData.upload_guidelines"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-file>
                                  <span v-if="formData.upload_guidelines">
                                    <a target = '_blank' :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/circular-publications/' + formData.upload_guidelines" download>{{$t('globalTrans.view_download')}}</a>
                                  </span>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Text Coordinator (En)" vid="text_coordinator_en" rules="" v-if="formData.org_id === 12">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="text_coordinator_en"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('elearning_iabm.tex_coordinator')}} {{$t('globalTrans.enn')}}
                                </template>
                                <vue-editor
                                    id="text_coordinator_en"
                                    v-model="formData.text_coordinator_en"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></vue-editor>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Text Coordinator (Bn)" vid="text_coordinator_bn" rules="" v-if="formData.org_id === 12">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="text_coordinator_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('elearning_iabm.tex_coordinator')}} {{$t('globalTrans.bnn')}}
                                </template>
                                <vue-editor
                                    id="text_coordinator_bn"
                                    v-model="formData.text_coordinator_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></vue-editor>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                            <div v-if="$store.state.Auth.authUser.org_id === 12 || formData.org_id === 12">
                              <b-overlay :show="officerLoading">
                                <template>
                                  <div style="font-size:14px; background-color: #66CC99; padding:6px" class="mt-4">
                                      <h5 class="text-white text-center"> {{ $t('elearning_config.training_coordinator') }}</h5>
                                  </div>
                                </template>
                                <b-row v-for="(info, index) in formData.coordinator_details" :key="index" class="mt-3">
                                  <!-- <pre>{{ info }}</pre> -->
                                  <b-col lg="3" md="3" sm="12" xs="12">
                                    <ValidationProvider name="Office" :vid="'office_id' + index" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="`office$(index)`"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('globalTrans.office') }}<span class="text-danger">*</span>
                                            </template>
                                              <b-form-select
                                              plain
                                              v-model="info.office_id"
                                              :options="info.officeList"
                                              @change="designationListFound(info)"
                                              :id="'info['+index+'][office_id]'"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="3" md="3" sm="12" xs="12">
                                    <ValidationProvider name="Designation" :vid="'designation_id' + index" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="`designation$(index)`"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('globalTrans.designation') }}<span class="text-danger">*</span>
                                            </template>
                                              <b-form-select
                                              plain
                                              v-model="info.designation_id"
                                              :options="info.designationList"
                                              @change="officerListFound(info)"
                                              :id="'info['+index+'][designation_id]'"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="3" md="3" sm="12" xs="12">
                                    <ValidationProvider name="Coordinator"  :vid="'coordinator_id' + index" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="`coordinator$(index)`"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>{{ $t('elearning_config.training_coordinator') }}<span class="text-danger">*</span>
                                            </template>
                                              <b-form-select
                                              plain
                                              v-model="info.coordinator_id"
                                              :options="officerListx.filter(item => item.designation_id === info.designation_id)"
                                              :id="'info['+index+'][coordinator_id]'"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col xl="3" lg="3" md="3" sm="12" style="margin-top:5%">
                                    <b-button v-show="index == formData.coordinator_details.length-1" variant=" iq-bg-success" size="sm" @click="addMat()"><i class="ri-add-line m-0"></i>{{$t('dae_config.add_more')}}</b-button>
                                    <b-button v-show="index || ( !index && formData.coordinator_details.length > 1)" variant=" iq-bg-danger" size="sm" @click="removeMat(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                  </b-col>
                                </b-row>
                              </b-overlay>
                            </div>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularPublicationStore, circularPublicationUpdate, circularPublicationBatch, designationWiseUser } from '../../api/routes'
import flatpickr from 'flatpickr'
import { VueEditor } from 'vue2-editor'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor
  },
  created () {
    // this.getOfficerX()
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      const listData = tmp.coordinator_details.map(item => {
        const customItem = {}
        customItem.officeList = this.$store.state.commonObj.officeList.filter(item => item.org_id === this.formData.org_id && item.office_type_id === 86)
        customItem.designationList = this.$store.state.commonObj.designationList.filter(item => item.org_id === this.formData.org_id)
        customItem.officerList = this.allOfficerList.filter(itm => itm.designation_id === parseInt(item.designation_id))
        return Object.assign({}, item, customItem)
      })
      tmp.coordinator_details = listData
       if (tmp.coordinator_details.length === 0 && tmp.org_id === 12) {
        tmp.coordinator_details = [
          {
            office_id: 0,
            designation_id: 0,
            coordinator_id: 0
          }
        ]
      }
      this.formData = tmp
    } else {
       this.formData = Object.assign({}, this.formData, {
        // org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.formData.org_id = this.$store.state.Auth.authUser.org_id
      }
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.formData.org_id = this.$store.state.Auth.authUser.org_id
      if (this.$store.state.Auth.authUser.is_org_admin !== 1) {
        this.formData.office_type_id = this.$store.state.Auth.authUser.office_type_id
        this.formData.office_id = this.$store.state.Auth.authUser.office_id
        this.fieldDisabled = true
      }
    }
    // this.batch()
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
        fieldDisabled: false,
      loading: false,
      officerLoading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: '',
        fiscal_year_id: null,
        office_type_id: null,
        office_id: null,
        org_id: null,
        training_category_id: null,
        training_type_id: null,
        training_title_id: null,
        other_trainee_type_id: null,
        district_id: 0,
        upazila_id: 0,
        batch_no: '',
        application_deadline: null,
        training_start_date: '',
        training_end_date: '',
        text_coordinator_en: '',
        text_coordinator_bn: '',
        upload_guidelines: [],
        venue: '',
        venue_bn: '',
        remarks: '',
        remarks_bn: '',
        status: 1,
        coordinator_details: [
          {
            office_id: 0,
            designation_id: 0,
            coordinator_id: 0,
            officeList: [],
            designationList: [],
            officerList: []
          }
        ]
      },
      allOfficerList: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      officeTypeList: [],
      officeList: [],
      upazilaList: [],
      trainingCategoryList: [],
      trainingTitleList: [],
      officerList: [],
      otherTraineeTypeList: [],
      officerListx: [],
      trainingTypeList: [],
      myFilter: (option, text, search) => {
                    const temp = search.toLowerCase()
                    return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                    option.text_bn.toLowerCase().indexOf(temp) > -1
                }
    }
  },
  computed: {
    designationList: function () {
        return this.$store.state.commonObj.designationList.filter(item => item.org_id === this.formData.org_id)
    },
    orgList: function () {
      if (this.$store.state.dataFilters.orgId === 12) {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
      } else {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      }
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    districtList: function () {
      return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    }
    // trainingTypeList: function () {
    //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    // }
  },
  watch: {
    'formData.org_id': function (newValue, oldValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
        this.trainingTypeList = this.getTypeList(newValue)
      if (newValue !== oldValue) {
        this.officeTypeList = this.getOfficeTypeList(newValue)
        this.trainingTypeList = this.getTypeList(newValue)
        this.otherTraineeTypeList = this.getOtherTraineeList(newValue)
        this.getOfficerX()
        const listData = this.formData.coordinator_details.map(item => {
          const customItem = {}
          // customItem.designationList = this.$store.state.commonObj.designationList.filter(item => item.org_id === this.formData.org_id && item.office_type_id === this.formData.office_type_id && item.office_id === this.formData.office_id)
          customItem.officeList = this.$store.state.commonObj.officeList.filter(item => item.org_id === this.formData.org_id && item.office_type_id === 86 && item.status === 0)
          // customItem.designationList = this.$store.state.commonObj.designationList.filter(item => item.org_id === this.formData.org_id)
          return Object.assign({}, item, customItem)
        })
        this.formData.coordinator_details = listData
      } else {
        this.trainingTypeList = []
        this.officeTypeList = []
        this.otherTraineeTypeList = []
      }
    },
    'formData.office_type_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.officeList = this.getOfficeList(newValue)
      } else {
        this.officeList = []
      }
    },
    // 'formData.org_id': function (newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     this.getOfficerList()
    //     const listData = this.formData.coordinator_details.map(item => {
    //       const customItem = {}
    //       customItem.designationList = this.$store.state.commonObj.designationList.filter(item => item.org_id === this.formData.org_id && item.office_type_id === this.formData.office_type_id && item.office_id === this.formData.office_id)
    //       return Object.assign({}, item, customItem)
    //     })
    //     this.formData.coordinator_details = listData
    //   }
    // },
    'formData.training_type_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      } else {
        this.trainingCategoryList = []
      }
    },
    'formData.training_category_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      } else {
        this.trainingTitleList = []
      }
    },
    'formData.district_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.upazilaList = this.getUpazilaList(newValue)
      } else {
        this.upazilaList = []
      }
    }
    // 'formData.training_title_id': function (newValue, oldValue) {
    //   if (newValue !== this.formData.training_title_id) {
    //     this.batch()
    //   }
    // },
    // 'formData.fiscal_year_id': function (newValue, oldValue) {
    //   if (newValue !== this.formData.fiscal_year_id) {
    //     this.batch()
    //   }
    // }
  },
  methods: {
    daycount () {
      if (this.formData.training_start_date && this.formData.training_end_date) {
        const startDate = new Date(this.formData.training_start_date)
        const endDate = new Date(this.formData.training_end_date)

        if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
          const timeDiff = endDate - startDate
          const dayDiff = timeDiff / (1000 * 3600 * 24)
          this.formData.no_of_days = dayDiff >= 0 ? (dayDiff + 1) : 0
        } else {
          this.formData.no_of_days = 0
        }
      }
    },
    addMat () {
      const tampbpSetup = {
            office_id: 0,
            designation_id: 0,
            coordinator_id: 0,
            officeList: this.$store.state.commonObj.officeList.filter(item => item.org_id === this.formData.org_id && item.office_type_id === 86 && item.status === 0)
            // designationList: this.$store.state.commonObj.designationList.filter(item => item.org_id === this.formData.org_id && item.office_type_id === this.formData.office_type_id && item.office_id === this.formData.office_id)
      }
      const key1 = parseInt(this.formData.coordinator_details.length - 1)
      const item = this.formData.coordinator_details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
          this.formData.coordinator_details.push(tampbpSetup)
      }
    },
    removeMat (key) {
      this.formData.coordinator_details.splice(key, 1)
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    designationListFound (info) {
      info.designationList = this.$store.state.commonObj.designationList.filter(item => item.office_id === parseInt(info.office_id))
      return info
    },
    officerListFound (info) {
      const officeList = this.allOfficerList.filter(item => item.designation_id === parseInt(info.designation_id))
      info.officerList = officeList.map(item => {
        return Object.assign({ value: item.user_id, text: this.$i18n.locale === 'bn' ? item.name_bn : item.name })
      })
      return info
    },
    getTypeList (orgId) {
      if (this.formData.org_id) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === orgId)
        }
        return type
      }
    },
    getOfficeTypeList (orgId) {
      if (this.formData.org_id) {
        const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
          return OfficeTypeList.filter(item => item.org_id === orgId)
        }
        return OfficeTypeList
      }
    },
    getOtherTraineeList (orgId) {
      if (orgId) {
        const OtherTraineeList = this.$store.state.TrainingElearning.commonObj.otherTraineeTypeList
        if (orgId) {
          return OtherTraineeList.filter(item => item.org_id === orgId)
        }
        return OtherTraineeList
      }
    },
    getOfficeList (officeTypeId) {
      if (this.formData.office_type_id) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
          return officeList.filter(item => item.office_type_id === officeTypeId)
        }
        return officeList
      }
    },
    getCategoryList (typeId) {
      if (this.formData.training_type_id) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      }
    },
    getTrainingTitleList (categoryId) {
      if (this.formData.training_category_id) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      }
    },
    getUpazilaList (districtId) {
      if (this.formData.district_id) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
        if (districtId) {
          return upazilaList.filter(item => item.district_id === districtId)
        }
        return upazilaList
      }
    },
    async createData () {
      this.loading = true
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      var formData = new FormData(document.getElementById('form'))
      Object.keys(this.formData).map(key => {
        if (key === 'other_trainee_type_id') {
          formData.append('other_trainee_type_id', JSON.stringify(this.formData.other_trainee_type_id))
        } else if (key === 'upload_guidelines') {
          formData.append('upload_guidelines', this.formData.upload_guidelines)
        } else if (key === 'coordinator_details') {
          formData.append('coordinator_details', JSON.stringify(this.formData.coordinator_details))
        } else {
          formData.append(key, this.formData[key])
        }
        // formData.append(key, this.formData[key])
      })
      if (this.formData.id) {
        formData.append('_method', 'POST')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${circularPublicationUpdate}/${this.id}`, formData, config)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, circularPublicationStore, formData, config)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    async batch () {
        if (this.formData.fiscal_year_id && this.formData.training_title_id) {
          this.loading = true
          if (this.formData.org_id === 16) {
            const params = {
              training_title_id: this.formData.training_title_id,
              fiscal_year_id: this.formData.fiscal_year_id,
              org_id: this.formData.org_id,
              office_id: this.formData.office_id,
              id: this.id
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationBatch, params)
            if (result.success) {
              this.loading = false
              this.formData.batch_no = result.data
            } else {
              this.formData.batch_no = ''
              this.loading = false
            }
          } else {
            const params = {
              training_title_id: this.formData.training_title_id,
              fiscal_year_id: this.formData.fiscal_year_id,
              org_id: this.formData.org_id,
              id: this.id
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationBatch, params)
            if (result.success) {
              this.loading = false
              this.formData.batch_no = result.data
            } else {
              this.formData.batch_no = ''
              this.loading = false
            }
          }
      }
    },
    async getOfficerX () {
        const params = {
          org_id: parseInt(this.formData.org_id),
          office_type_id: parseInt(this.formData.office_type_id),
          office_id: parseInt(this.formData.office_id)
        }
        await RestApi.getData(trainingElearningServiceBaseUrl, designationWiseUser, params)
        .then(response => {
          if (response.success) {
            this.allOfficerList = response.data
          }
      })
      const officeListxx = this.allOfficerList.map(item => {
        return Object.assign({ value: item.user_id, text: this.$i18n.locale === 'bn' ? item.name_bn : item.name, text_en: item.name, text_bn: item.name_bn, designation_id: item.designation_id })
      })
        this.officerListx = officeListxx
    },
    async getOfficerList () {
      this.officerLoading = true
        const params = {
          org_id: parseInt(this.formData.org_id),
          office_type_id: parseInt(this.formData.office_type_id),
          office_id: parseInt(this.formData.office_id)
        }
        await RestApi.getData(trainingElearningServiceBaseUrl, designationWiseUser, params)
        .then(response => {
          if (response.success) {
            this.allOfficerList = response.data
          }
          this.officerLoading = false
      })
    }
  }
}
</script>
