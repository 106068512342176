<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                    <b-col>
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            {{ }}
                        </list-report-head>
                    </b-col>
              </b-row>
              <b-row>
              <b-col lg="12" sm="12">
                  <b-table-simple class="tg mt-3" hover small caption-top borderless>
                  <tbody>
                      <tr>
                        <th style="width: 15%"  class="text-left" >{{ $t('elearning_tim.exam_type') }}</th>
                        <th class="text-center" style="width: 2%">:</th>
                        <td style="width: 33%" class="text-left">{{ examTypeName }}</td>
                        <th style="width: 15%" class="text-left" >{{ $t('elearning_config.fiscal_year') }}</th>
                        <th class="text-center" style="width: 2%">:</th>
                        <td style="width: 33%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                        <!-- <th style="width: 15%"  class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $t('elearning_config.organization') }}</th>
                        <th style="width: 2%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">:</th>
                        <td style="width: 33%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</td> -->
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
                        <th style="width: 2%" class="text-center">:</th>
                        <td style="width: 33%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
                        <th style="width: 2%" class="text-center">:</th>
                        <td style="width: 33%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
                        <th style="width: 2%" class="text-center">:</th>
                        <td style="width: 33%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
                        <th style="width: 15%"  class="text-left" >{{ $t('elearning_iabm.batch_no') }}</th>
                        <th class="text-center" style="width: 2%">:</th>
                        <td style="width: 33%" class="text-left">{{ getBatchNo(formData.batch_no) }}</td>
                      </tr>
                      <tr>
                        <!-- <th style="width: 15%"  class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                        <th class="text-center" style="width: 2%">:</th>
                        <td style="width: 33%" class="text-left">{{ formData.circular_memo_no }}</td> -->
                      </tr>
                      <tr>
                        <th style="width: 15%" class="text-left" >{{ $t('elearning_tim.evaluation_date') }}</th>
                        <th class="text-center" style="width: 2%">:</th>
                        <td style="width: 33%" class="text-left">{{ formData.exam_date | dateFormat }}</td>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_tim.total_marks') }}</th>
                        <th style="width: 2%" class="text-center">:</th>
                        <td style="width: 33%">{{ $n(formData.total_mark) }}</td>
                      </tr>
                      <tr>
                          <th style="width: 15%"  class="text-left">{{ $t('elearning_tim.evaluation_start_time') }}</th>
                          <th style="width: 2%" class="text-center">:</th>
                          <td style="width: 33%">{{ formData.exam_time_start | time12FormateTraining }}</td>
                          <th style="width: 15%"  class="text-left">{{ $t('elearning_tim.evaluation_end_time') }}</th>
                          <th style="width: 2%" class="text-center">:</th>
                          <td style="width: 33%">{{ formData.exam_time_end | time12FormateTraining }}</td>
                      </tr>
                  </tbody>
                  </b-table-simple>

                  <div class="question-area" v-for="(evaluationQuestion, questionIndex) in formData.evaluation_questions" :key="questionIndex">
                    <b-table-simple class="tg mt-3" hover small caption-top>
                        <tbody>
                        <tr>
                          <th style="width: 14%"  class="text-left" >{{ $t('elearning_tim.question') }} {{ '(' + $n(questionIndex + 1) + ')' }}</th>
                          <th class="text-center" style="width: 1%">:</th>
                          <td style="width: 62%" class="text-left">{{ $i18n.locale === 'en' ? evaluationQuestion.question : evaluationQuestion.question_bn }} {{ '(' + getQuestionType(evaluationQuestion.question_type) + ')' }}</td>
                          <th style="width: 14%"  class="text-right">{{ $t('elearning_tim.marks') }}</th>
                          <th style="width: 1%" class="text-center">:</th>
                          <td style="width: 8%" class="text-left">{{ $n(evaluationQuestion.marks) }}</td>
                        </tr>
                        <tr>
                          <th style="width: 14%"  class="text-left" v-if="evaluationQuestion.description">{{ $t('globalTrans.description') }}</th>
                          <th class="text-center" style="width: 1%" v-if="evaluationQuestion.description">:</th>
                          <td style="width: 62%" class="text-left" v-if="evaluationQuestion.description">{{ $i18n.locale === 'en' ? evaluationQuestion.description : evaluationQuestion.description_bn }}</td>
                          <th style="width: 14%"  class="text-right" v-if="evaluationQuestion.attachment">{{ $t('globalTrans.attachment') }}</th>
                          <th class="text-center" style="width: 1%" v-if="evaluationQuestion.attachment">:</th>
                          <td style="width: 8%" v-if="evaluationQuestion.attachment"><a v-if="evaluationQuestion.attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + evaluationQuestion.attachment" download><i class="fas fa-download"></i> {{$t('globalTrans.view_download')}}</a></td>
                        </tr>
                        </tbody>
                    </b-table-simple>
                    <!-- <table class="table" v-if="evaluationQuestion.evaluation_options.length">
                        <thead class="thead">
                        <tr>
                          <th class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                          <th class="text-center">{{ $t('elearning_tim.is_correct') }}</th>
                          <th class="text-center">{{ $t('elearning_tim.option') }}</th>
                        </tr>
                        </thead>
                        <tr v-for="(evaluationOption, optionIndex) in evaluationQuestion.evaluation_options" :key="(questionIndex+1)+(optionIndex+1)">
                          <td class="text-center m-2 p-0">{{ $i18n.locale === 'en' ? String.fromCharCode(97 + optionIndex) : String.fromCharCode(0x0995 + optionIndex) }}</td>
                          <td class="text-center m-2 p-0">{{ evaluationOption.is_correct ? $t('globalTrans.yes') : $t('globalTrans.no') }}</td>
                          <td class="text-center m-2 p-0">{{ $i18n.locale === 'en' ? evaluationOption.option_en : evaluationOption.option_bn }}</td>
                        </tr>
                    </table> -->
                    <!-- <template> -->
                    <b-row>
                      <b-col class="ml-5" sm="12" v-for="(evaluationOption, optionIndex) in evaluationQuestion.evaluation_options" :key="optionIndex">
                        {{ $i18n.locale === 'en' ? String.fromCharCode(97 + optionIndex) + '. ' : String.fromCharCode(0x0995 + optionIndex) + '. ' }}{{ currentLocale === 'en' ?  evaluationOption.option_en : evaluationOption.option_bn }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="ml-3" sm="12" v-for="(evaluationOption, optionIndex) in evaluationQuestion.evaluation_options" :key="optionIndex">
                        <slot v-if="evaluationOption.is_correct">
                          <b>{{ $t('user.answer') + ' : ' }}</b>
                          {{ ($i18n.locale === 'en' ? evaluationOption.option_en : evaluationOption.option_bn) }}
                        </slot>
                      </b-col>
                    </b-row>
                    <!-- </template> -->
                  </div>
              </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      <!-- <pre>{{formData}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { prePostEvaluationDetails } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
// import ExportPdf from './export_pdf_details'

export default {
    components: {
      ListReportHead
    },
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    allBatchList () {
      const batchList = [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
      return batchList
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    examTypeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'Pre Evaluation' : 'প্রি-ইভালুয়েশন', value: 1 },
        { text: this.currentLocale === 'en' ? 'Post Evaluation' : 'পোস্ট-ইভালুয়েশন', value: 2 }
      ]
    },
    examTypeName: function () {
      return this.examTypeList.find(item => item.value === this.formData.exam_type).text
    },
    questionTypeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'MCQ' : 'এমসিকিউ', value: 1 },
        { text: this.currentLocale === 'en' ? 'True Or False' : 'সত্য অথবা মিথ্যা', value: 2 },
        { text: this.currentLocale === 'en' ? 'Fill in the Blanks' : 'শূন্যস্থান পূরণ', value: 3 }
      ]
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getBatchNo (id) {
      const batchData = this.allBatchList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
          return batchData !== undefined ? batchData.text_bn : ''
      } else {
          return batchData !== undefined ? batchData.text_en : ''
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getQuestionType (questionType) {
      return this.questionTypeList.find(item => item.value === questionType).text
    },
    // pdfExport () {
    //   const reportTitle = this.$t('elearning_tim.pre_post_evaluation')
    //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this)
    // }
    async pdfExport () {
      this.load = true
      const obj = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id,
        circular_memo_no: this.formData.circular_memo_no,
        batch_no: this.formData.batch_no,
        fiscal_year_id: this.formData.fiscal_year_id,
        org_id: this.formData.org_id,
        exam_type: this.formData.exam_type
      }
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, obj)
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, prePostEvaluationDetails, params)
      var blob = new Blob([result], {
          type: 'application/pdf'
      })
      this.load = false
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
    },
    async pdfDownload () {
      this.load = true
      const obj = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id,
        circular_memo_no: this.formData.circular_memo_no,
        batch_no: this.formData.batch_no,
        fiscal_year_id: this.formData.fiscal_year_id,
        org_id: this.formData.org_id,
        exam_type: this.formData.exam_type
      }
      const params = Object.assign({ request_type: 'pdf', without_ans: 1, local: this.$i18n.locale }, obj)
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, prePostEvaluationDetails, params)
      var blob = new Blob([result], {
          type: 'application/pdf'
      })
      this.load = false
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
    }
    // async pdfDownload () {
    //   this.load = true
    //   const obj = {
    //       role: this.$store.state.Auth.activeRoleId,
    //       auth_org: this.$store.state.Auth.authUser.org_id,
    //       circular_memo_no: this.formData.circular_memo_no,
    //       batch_no: this.formData.batch_no,
    //       fiscal_year_id: this.formData.fiscal_year_id,
    //       org_id: this.formData.org_id,
    //       exam_type: this.formData.exam_type
    //   }
    //   const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, obj)
    //   try {
    //       const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, prePostEvaluationDetails, params)
    //       const blob = new Blob([result], { type: 'application/pdf' })
    //       const url = window.URL.createObjectURL(blob)

    //       // Create a temporary anchor element for download
    //       const a = document.createElement('a')
    //       a.href = url
    //       a.download = 'document.pdf' // Set the desired file name for the downloaded PDF
    //       document.body.appendChild(a) // Append the anchor to the document
    //       a.click() // Trigger the download
    //       document.body.removeChild(a) // Remove the anchor element
    //       window.URL.revokeObjectURL(url) // Revoke the temporary URL to free up memory
    //   } catch (error) {
    //   } finally {
    //       this.load = false // Reset the loading state
    //   }
    // }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
