import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import ReportHeading from '@/Utils/report-head-lic-o'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      {
        columns: reportHeadData.reportHeadColumn
      },
      { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
      { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
      { text: reportHeadData.address, style: 'address', alignment: 'center' },
      { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
      // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      pdfContent.push({ text: '', style: 'fertilizer' })
      const search = vm.search
      const allRowsHead = [
        [
          { text: vm.$t('org_pro.service_namel') + ' : ' + (search.service_id ? vm.getColumnName(Store.state.licenseRegistration.commonObj.serviceNamesList, search.service_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('globalTrans.type') + ' : ' + (search.application_type ? vm.getApplicationTypeName(search.application_type) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
        ],
        [
          { text: vm.$t('globalTrans.from_date') + ' : ' + (search.from_date ? dateFormat(search.from_date) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('globalTrans.to_date') + ' : ' + (search.to_date ? dateFormat(search.to_date) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
        ]
        // [
        //   { text: vm.$t('dealer_management.fertilizer_name') + ' : ' + (search.fertilizer_name_id ? vm.getColumnName(Store.state.licenseRegistration.commonObj.fertilizerName, search.fertilizer_name_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
        //   { text: vm.$t('grantDistribution.organization_name') + ' : ' + (search.organization_name ? search.organization_name : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
        // ],
        // [
        //   { text: vm.$t('globalTrans.mobile') + ' : ' + (search.mobile_no ? search.mobile_no : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
        //   {}
        // ]
      ]
      pdfContent.push({
        table: {
          widths: ['50%', '50%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })
      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.month'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('li_step.registration_type'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('li_step.application_form_submission'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('li_step.application_disposal'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('li_step.percentage'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.comments'), style: 'th', alignment: 'center', bold: true }
        ]
      ]
      if (vm.listData.length) {
        vm.listData.forEach((monthItem, monthItemIndex) => {
          monthItem.service_info.forEach((item, index) => {
            allRows.push([
              { text: vm.$n(item.serial, { useGrouping: false }), alignment: 'center', style: 'td' },
              { text: vm.getMonthName(monthItem.month) + '/' + vm.$n(monthItem.year, { useGrouping: false }), alignment: 'center', style: 'td' },
              { text: vm.getServiceName(item.service_id), alignment: 'center', style: 'td' },
              { text: vm.$n(item.total_application), alignment: 'center', style: 'td' },
              { text: vm.$n(item.total_approved_application), alignment: 'center', style: 'td' },
              { text: vm.$n(item.percentage, { useGrouping: false }), alignment: 'center', style: 'td' },
              { text: '', alignment: 'center', style: 'td' }
            ])
          })
          allRows.push([
            { text: vm.$t('globalTrans.total'), alignment: 'center', style: 'td', colSpan: 3 },
            { text: '', alignment: 'center', style: 'td' },
            { text: '', alignment: 'center', style: 'td' },
            { text: vm.$n(monthItem.total_application), alignment: 'center', style: 'td' },
            { text: vm.$n(monthItem.total_approved_application), alignment: 'center', style: 'td' },
            { text: vm.$n(monthItem.percentage, { useGrouping: false }), alignment: 'center', style: 'td' },
            { text: '', alignment: 'center', style: 'td' }
          ])
        })
        allRows.push(
          [
            { text: vm.$t('globalTrans.total'), style: 'th', alignment: 'center', bold: true, colSpan: 3 },
            { text: '' },
            { text: '' },
            { text: vm.$n(vm.grandTotalInfo.grand_total_application), style: 'th', alignment: 'center', bold: true },
            { text: vm.$n(vm.grandTotalInfo.grand_total_approved_application), style: 'th', alignment: 'center', bold: true },
            { text: vm.$n(vm.grandTotalInfo.total_percentage), style: 'th', alignment: 'center', bold: true },
            {}
          ]
        )
      } else {
        pdfContent.push([
          { text: vm.$t('globalTrans.noDataFound'), style: 'th', alignment: 'center', bold: true, colSpan: 7 },
          { text: '' },
          { text: '' },
          { text: '' },
          { text: '' },
          { text: '' },
          {}
        ])
      }
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['7%', '12%', '15%', '15%', '10%', '10%', '*'],
          // widths: '*',
          body: allRows
        }
      })
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 10 : 9,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 10 : 9,
          margin: [3, 3, 3, 3]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 9 : 7,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 12,
          margin: [0, 0, 0, 4]
        },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 0]
        },
        org: {
          fontSize: 13,
          bold: true,
          margin: [0, -25, 10, 5]
        },
        hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
        },
        address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        },
        header1: {
          fontSize: 15,
          margin: [5, 5, 5, 5]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
