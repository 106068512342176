<template>
  <b-container fluid>
    <b-overlay :show="loading">
        <slot v-if="ActionData.applicationData[0]">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <button @click="printLicense(ActionData.applicationData[0].service_id)" class="float-right btn btn-success btn-sm mb-2"><i class="ri-printer-line mr-0"></i>
                        {{ $t('globalTrans.print') }}
                    </button>
                </div>
                <br/>
                <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'ব্যবসায়িক তথ্য' : 'Business Information' }}</h5><!----></div><!----><!---->
                <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                    <table border="0" style="width: 100%">
                    <tr>
                        <td style="width:50%">{{ $i18n.locale === 'bn' ? $t('প্রতিষ্ঠানের নাম') : 'Company Name' }} :
                        <slot>
                            {{ $i18n.locale === 'bn' ? ActionData.applicationData[0].company_na_8560 : ActionData.applicationData[0].company_na_5170 }}
                        </slot>
                        </td>
                        <td style="width:50%">{{$t('org_pro_division.division')}} :
                        <slot>
                            {{ getColumnName($store.state.commonObj.divisionList, ActionData.applicationData[0].division_4819) }}
                        </slot>
                        <!-- <slot v-else>
                            {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                        </slot> -->
                        </td>
                    </tr>
                    <tr>
                        <td>{{$t('org_pro_district.district')}} :
                        <slot>
                            {{ getColumnName($store.state.commonObj.districtList, ActionData.applicationData[0].district_8954) }}
                        </slot>
                        <!-- <slot v-else>
                            {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_22) }}
                        </slot> -->
                        </td>
                        <td>{{$t('org_pro_upazilla.upazilla')}} :
                        <slot>
                            {{ getColumnName($store.state.commonObj.upazilaList, ActionData.applicationData[0].district_8954) }}
                        </slot>
                        <!-- <slot v-else>
                            {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                        </slot> -->
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $i18n.locale === 'bn' ? $t('ব্যবসায়িক ঠিকানা') : 'Business Address'}} :
                        <slot>
                            {{ $i18n.locale === 'bn' ? ActionData.applicationData[0].company_ad_3969 : ActionData.applicationData[0].company_ad_5825 }}
                        </slot>
                        </td>
                    </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'অফিস/ শোরুমের তথ্য' : 'Office/ Showroom Information' }}</h5><!----></div><!----><!---->
                <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                    <table border="0" style="width: 100%">
                        <tr>
                            <td style="width:50%">{{$t('org_pro_division.division')}} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.divisionList, ActionData.applicationData[0].division_3413) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                            <td style="width:50%">{{$t('org_pro_district.district')}} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.districtList, ActionData.applicationData[0].district_1955) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('org_pro_upazilla.upazilla')}} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.upazilaList, ActionData.applicationData[0].upazilla_3970) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? $t('অফিসের ঠিকানা') : 'Office Address'}} :
                            <slot>
                                {{ $i18n.locale === 'bn' ? ActionData.applicationData[0].office_add_4365 : ActionData.applicationData[0].office_add_2677 }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                            </slot> -->
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'মোবাইল নম্বর' : 'Mobile No' }} :
                            <slot>
                            {{ ActionData.applicationData[0].mobile_no__1339 | mobileNumber }}
                            </slot>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'বিক্রয় কেন্দ্রের তথ্য' : 'Sales Center Info' }}</h5><!----></div><!----><!---->
                <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                    <table border="0" style="width: 100%">
                        <tr>
                            <td style="width:50%">{{$t('org_pro_division.division')}} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.divisionList, ActionData.applicationData[0].division_5256) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                            <td style="width:50%">{{$t('org_pro_district.district')}} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.districtList, ActionData.applicationData[0].district_6787) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('org_pro_upazilla.upazilla')}} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.upazilaList, ActionData.applicationData[0].upazilla_6924) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? $t('বিক্রয় কেন্দ্রের ঠিকানা') : 'Sales Center Address'}} :
                            <slot>
                                {{ $i18n.locale === 'bn' ? ActionData.applicationData[0].sales_cent_7110 : ActionData.applicationData[0].sales_cent_9545 }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                            </slot> -->
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'বিক্রয় কেন্দ্রের দৈর্ঘ্য(ফুট)' : 'Sales Center Length(Feet)' }} :
                            <slot>
                                {{ ActionData.applicationData[0].sales_cent_9545 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'বিক্রয় কেন্দ্রের প্রস্থ(ফুট)' : 'Sales Center Width(Feet)' }} :
                            <slot>
                                {{ ActionData.applicationData[0].sales_cent_1551 }}
                            </slot>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'বিক্রয় কেন্দ্রের উচ্চতা(ফুট)' : 'Sales Center Height(Feet)' }} :
                            <slot>
                            {{ ActionData.applicationData[0].sales_cent_1135 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'মোবাইল নম্বর' : 'Mobile No' }} :
                            <slot>
                            {{ ActionData.applicationData[0].mobile_no__9258 | mobileNumber }}
                            </slot>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'বিক্রয় কেন্দ্রের সাইনবোর্ডের দৈর্ঘ্য(ফুট)' : 'Sales Center Signboard Length(Feet)' }} :
                            <slot>
                            {{ ActionData.applicationData[0].lengthfeet_4399 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'বিক্রয় কেন্দ্রের সাইনবোর্ডের প্রস্থ(ফুট)' : 'Sales Center Signboard Width(Feet)' }} :
                            <slot>
                            {{ ActionData.applicationData[0].width_feet_3022 }}
                            </slot>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'প্যাকিং হাউজের তথ্যঃ' : 'Packing House Information' }}</h5><!----></div><!----><!---->
                <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                    <table border="0" style="width: 100%">
                        <tr>
                            <td style="width:50%">{{$t('org_pro_division.division')}} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.divisionList, ActionData.applicationData[0].division) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                            <td style="width:50%">{{$t('org_pro_district.district')}} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.districtList, ActionData.applicationData[0].district_4422) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('org_pro_upazilla.upazilla')}} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.upazilaList, ActionData.applicationData[0].upazilla_4319) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? $t('প্যাকিং হাউজের ঠিকানা') : 'Packing House Address'}} :
                            <slot>
                                {{ $i18n.locale === 'bn' ? ActionData.applicationData[0].packing_ho_2030 : ActionData.applicationData[0].packing_ho_4916 }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                            </slot> -->
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'প্যাকিং হাউজের দৈর্ঘ্য(ফুট)' : 'Packing House Length(Feet)' }} :
                            <slot>
                                {{  ActionData.applicationData[0].packing_ho_6730 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'প্যাকিং হাউজের প্রস্থ(ফুট)' : 'Packing House Width(Feet)' }} :
                            <slot>
                                {{  ActionData.applicationData[0].packing_ho_1078 }}
                            </slot>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'প্যাকিং হাউজের উচ্চতা(ফুট)' : 'Packing House Height(Feet)' }} :
                            <slot>
                                {{  ActionData.applicationData[0].packing_ho_8081 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'মোবাইল নম্বর' : 'Mobile No' }} :
                            <slot>
                                {{  ActionData.applicationData[0].mobile_no__6114 | mobileNumber }}
                            </slot>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'প্যাকিং হাউজের সাইনবোর্ডের দৈর্ঘ্য(ফুট)' : 'Packing House Signboard Length(Feet)' }} :
                            <slot>
                                {{  ActionData.applicationData[0].packing_ho_9541 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'প্যাকিং হাউজের সাইনবোর্ডের প্রস্থ(ফুট)' : 'Packing House Signboard Width(Feet)' }} :
                            <slot>
                                {{  ActionData.applicationData[0].packing_ho_5538 }}
                            </slot>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'গুদামের তথ্যঃ' : 'Warehouse Information' }}</h5><!----></div><!----><!---->
                <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                    <table border="0" style="width: 100%">
                        <tr>
                        <td style="width:50%">{{ $i18n.locale === 'bn' ? $t('গুদামের নাম') : 'Warehouse Name' }} :
                        <slot>
                            {{ $i18n.locale === 'bn' ? ActionData.applicationData[0].warehouse__9601 : ActionData.applicationData[0].warehouse__5143 }}
                        </slot>
                        </td>
                    </tr>
                        <tr>
                            <td style="width:50%">{{$t('org_pro_division.division')}} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.divisionList, ActionData.applicationData[0].division_4848) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, ActionData.applicationData[0].district_23) }}
                            </slot> -->
                            </td>
                            <td style="width:50%">{{$t('org_pro_district.district')}} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.districtList, ActionData.applicationData[0].district_8924) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('org_pro_upazilla.upazilla')}} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.upazilaList, ActionData.applicationData[0].upazilla_6136) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? $t('গুদামের ঠিকানা') : 'Warehouse Address'}} :
                            <slot>
                                {{ $i18n.locale === 'bn' ? ActionData.applicationData[0].warehouse__7054 : ActionData.applicationData[0].warehouse__8712 }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                            </slot> -->
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'মোবাইল নম্বর' : 'Mobile No' }} :
                            <slot>
                                {{ ActionData.applicationData[0].mobile_no__7931 | mobileNumber }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'গুদামের ধরণ' : 'Warehouse Type' }} :
                            <slot>
                                {{ ActionData.applicationData[0].warehouse__3985 }}
                            </slot>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'গুদামের দৈর্ঘ্য(ফুট)' : 'Warehouse Length(Feet)' }} :
                            <slot>
                                {{ ActionData.applicationData[0].warehouse__7909 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'গুদামের প্রস্থ(ফুট)' : 'Warehouse Width(Feet)' }} :
                            <slot>
                                {{ ActionData.applicationData[0].warehouse__4868 }}
                            </slot>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'গুদামের উচ্চতা(ফুট)' : 'Warehouse Height(Feet)' }} :
                            <slot>
                                {{ ActionData.applicationData[0].warehouse__6241 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'গুদামের মালিকানা' : 'Warehouse Proprietary' }} :
                            <slot>
                                {{ ActionData.applicationData[0].warehouse__2410 }}
                            </slot>
                            </td>
                        </tr>

                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'গুদামের ধারণক্ষমতা(মেট্রিক টন)' : 'Warehouse Capacity' }} :
                            <slot>
                                {{ ActionData.applicationData[0].warehouse__8079 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'আবাসিক/শিক্ষা প্রতিষ্ঠান/খাদ্যদ্রব্য প্রস্তুতকারী স্থাপনা থেকে গুদামের দূরত্ব' : 'Distance of Warehouse From Residential/Educational Institution/Food Preparation Facility' }} :
                            <slot>
                                {{ ActionData.applicationData[0]._________7666 }}
                            </slot>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'যোগাযোগ ব্যবস্থা' : 'Annual production capacity (in case of factory)' }} :
                            <slot>
                                {{ ActionData.applicationData[0].communicat_7649 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'গুদামে সংরক্ষিত সারের নাম(যদি থাকে)' : 'Name of Fertilizer Stored in Warehouse (If Any)' }} :
                            <slot>
                                {{ ActionData.applicationData[0].name_of_fe_3533 }}
                            </slot>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'সংরক্ষিত সারের মজুদের পরিমাণ(যদি থাকে)' : 'Amount of Stored Fertilizer Stock (If Any)' }} :
                            <slot>
                                {{ ActionData.applicationData[0].amount_of__6396 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'গুদামে মজুদ অন্য দ্রব্যের নাম' : 'Name of Other Goods Stored in the Warehouse' }} :
                            <slot>
                                {{ ActionData.applicationData[0].name_of_ot_8003 }}
                            </slot>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'দ্রব্যের পরিমাণ' : 'Quantity of Goods' }} :
                            <slot>
                                {{ ActionData.applicationData[0].quantity_o_7658 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'বাৎসরিক উৎপাদন ক্ষমতা(কারখানার ক্ষেত্রে)' : 'Annual production capacity (in case of factory)' }} :
                            <slot>
                                {{ ActionData.applicationData[0].annual_pro_1361 }}
                            </slot>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 pl-10 pr-10">
                <b-table-simple borderless striped hover small caption-top responsive>
                <b-tr>
                    <b-td style="width:40%">{{ $i18n.locale === 'bn' ? 'আমদানি/উৎপাদনের সাথে আগমনীবার্তা দাখিল' : 'Incoming message submitted with import / production' }}: </b-td>
                    <b-td>
                        {{ ActionData.applicationData[0].incoming_m_8445 }}
                    </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td style="width:40%">{{ $i18n.locale === 'bn' ? 'আমদানীকারক/উৎপাদনকারী কর্তৃক অন্য প্রতিষ্ঠানের সার মজুদ রাখিলে তাহার বিবরণ' : 'Details of importer / producer keeping stock of fertilizer of other organization' }}: </b-td>
                        <b-td>
                            {{ ActionData.applicationData[0].details_of_2416 }}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td style="width:40%">{{ $i18n.locale === 'bn' ? 'কর্মীদের স্বাস্থ্য সুরক্ষা ও নিরাপত্তামূলক ব্যবস্থা কি কি বিদ্যমান' : 'What are the existing health protection and safety measures for employees?' }}: </b-td>
                        <b-td>
                            {{ ActionData.applicationData[0].what_are_t_6409 }}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td style="width:40%">{{ $i18n.locale === 'bn' ? 'পরিদর্শনকারী কর্মকর্তার মতামত' : 'Opinion of the inspecting officer' }}: </b-td>
                        <b-td>
                            {{ ActionData.applicationData[0].opinion_of_4651 }}
                        </b-td>
                    </b-tr>
                </b-table-simple>
            </div>
        </slot>
        <slot v-else>
            <div class="col-sm-12 col-md-12 col-lg-12 pl-10 pr-10">
                <h5 v-if="ActionData.inspaction" class="text-center">
                    {{$t('globalTrans.inspaction_done_for_another_bin_no')}},
                    <b>{{ $t('globalTrans.application_id') }} : {{ ActionData.inspaction }} </b>
                </h5>
            </div>
        </slot>
    </b-overlay>
  </b-container>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'

export default {
  props: ['ActionData', 'isAdmin', 'forward'],
  data () {
    return {
      loading: false,
      badcNew: [],
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl
    }
  },
  created () {
  },
  methods: {
    async printLicense (serviceId) {
        const id = 9
        const stepId = 66
        const params = {
            id: id,
            service_id: parseInt(serviceId),
            step_id: parseInt(stepId),
            applicationID: parseInt(this.ActionData.applicationData[0].application_id),
            org_id: 2,
            type: 'pdf',
            local: this.$i18n.locale,
            admin: true,
            onlyInspection: true
        }
        this.loading = true
        try {
            const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, '/step/step-from-data', params)
            var blob = new Blob([result], { type: 'application/pdf' })
            var url = window.URL.createObjectURL(blob)
            var a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = 'license.pdf'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)

            this.loading = false
        } catch (error) {
            this.loading = false
        }
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    }
  }
}
</script>
