<template>
  <b-container fluid>
    <b-overlay :show="unitLoad">
        <div>
          <b-row>
              <b-col md="12" class="table-responsive" v-if="isLoad">
                <b-overlay :show="loading">
                  <table class="table" border="1">
                    <tr>
                      <th style="width: 30%;" class="text-center">{{ $t('globalTrans.name') }}</th>
                      <th style="width: 10%">{{ $t('globalTrans.attachment') }}</th>
                    </tr>
                    <tr>
                      <td>{{ $t('li_step.dae_dist_1') }} </td>
                      <td>
                        <a target="_blank" v-if="getFile(badcStaticData[1].image_73) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].image_73)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('li_step.dae_dist_2') }} </td>
                      <td>
                        <a target="_blank" v-if="getFile(badcStaticData[1].documents__5997) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].documents__5997)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('li_step.dae_dist_3') }} </td>
                      <td>
                        <a target="_blank" v-if="getFile(badcStaticData[1].agreement__79) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].agreement__79)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('li_step.dae_dist_4') }} </td>
                      <td>
                        <a target="_blank" v-if="getFile(badcStaticData[1].photocopy__1997) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].photocopy__1997)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('li_step.dae_dist_5') }} </td>
                      <td>
                        <a target="_blank" v-if="getFile(badcStaticData[1].national_i_7591) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].national_i_7591)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('li_step.dae_dist_6') }} </td>
                      <td>
                        <a target="_blank" v-if="getFile(badcStaticData[1].uptodate_t_8033) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].uptodate_t_8033)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('li_step.dae_dist_7') }} </td>
                      <td>
                        <a target="_blank" v-if="getFile(badcStaticData[1].vat_regist_3373) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].vat_regist_3373)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('li_step.dae_dist_8') }} </td>
                      <td>
                          <a target="_blank" v-if="getFile(badcStaticData[1].updated_in_8030) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].updated_in_8030)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                      </td>
                    </tr>
                    <tr>
                        <td>{{ $t('li_step.dae_dist_9') }} </td>
                        <td>
                            <a target="_blank" v-if="getFile(badcStaticData[1].updated_bf_7107) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].updated_bf_7107)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('li_step.dae_dist_10') }} </td>
                        <td>
                            <a target="_blank" v-if="getFile(badcStaticData[1].uptodate_b_9860) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].uptodate_b_9860)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('li_step.dae_dist_11') }} </td>
                        <td>
                            <a target="_blank" v-if="getFile(badcStaticData[1].undertakin_5815) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].undertakin_5815)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('li_step.dae_dist_12') }} </td>
                        <td>
                            <a target="_blank" v-if="getFile(badcStaticData[1].office_war_6709) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].office_war_6709)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('li_step.dae_dist_13') }} </td>
                        <td>
                            <a target="_blank" v-if="getFile(badcStaticData[1]._copies_of_7219) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1]._copies_of_7219)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('li_step.dae_dist_14') }} </td>
                        <td>
                            <a target="_blank" v-if="getFile(badcStaticData[1]._year_perf_4609) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1]._year_perf_4609)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('li_step.dae_dist_15') }} </td>
                        <td>
                            <a target="_blank" v-if="getFile(badcStaticData[1].old_regist_1199) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].old_regist_1199)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('li_step.dae_dist_16') }} </td>
                        <td>
                            <a target="_blank" v-if="getFile(badcStaticData[1].packet_pre_7587) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].packet_pre_7587)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('li_step.dae_dist_17') }} </td>
                        <td>
                            <a target="_blank" v-if="getFile(badcStaticData[1].others_4859) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(badcStaticData[1].others_4859)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                        </td>
                    </tr>
                  </table>
                </b-overlay>
              </b-col>
          </b-row>
        </div>
    </b-overlay>
  </b-container>
</template>

<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'

export default {
  props: ['tabData', 'badcStaticData', 'history', 'users', 'stepData', 'pDatas', 'officeData'],

  data () {
    return {
        lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
        unitLoad: false,
        isLoad: false,
        datas: {},
        generalInfoShow: true,
        attachmentShow: false,
        labelDesignShow: false,
        reportLoading: false,
        paymentRecipt: false,
        reportList: [],
        importData: [
          { id: 1, file: 'image_73', yes_no: 1, remarks: null },
          { id: 2, file: 'documents__5997', yes_no: 1, remarks: null },
          { id: 3, file: 'agreement__79', yes_no: 0, remarks: null },
          { id: 4, file: 'photocopy__1997', yes_no: 0, remarks: null },
          { id: 5, file: 'national_i_7591', yes_no: 0, remarks: null },
          { id: 6, file: 'uptodate_t_8033', yes_no: 0, remarks: null },
          { id: 7, file: 'vat_regist_3373', yes_no: 0, remarks: null },
          { id: 8, file: 'updated_in_8030', yes_no: 0, remarks: null },
          { id: 9, file: 'updated_bf_7107', yes_no: 0, remarks: null },
          { id: 10, file: 'uptodate_b_9860', yes_no: 0, remarks: null },
          { id: 11, file: 'undertakin_5815', yes_no: 0, remarks: null },
          { id: 12, file: 'office_war_6709', yes_no: 0, remarks: null },
          { id: 13, file: '_copies_of_7219', yes_no: 0, remarks: null },
          { id: 14, file: '_year_perf_4609', yes_no: 0, remarks: null },
          { id: 15, file: 'old_regist_1199', yes_no: 0, remarks: null },
          { id: 16, file: 'packet_pre_7587', yes_no: 0, remarks: null },
          { id: 17, file: 'others_4859', yes_no: 0, remarks: null }
        ],
        daeList: []
    }
  },
  created () {
    this.daeList = this.import
    this.loadData()
  },
  computed: {
    local: function () {
        return this.$i18n.locale
    },
    ingridentList: function () {
      return this.$store.state.licenseRegistration.commonObj.activeIngredientList.filter(item => item.status === 1)
    },
    labList: function () {
       return this.$store.state.licenseRegistration.commonObj.labList.filter(item => item.status === 1)
    }
  },
  methods: {
    getCountryManufactureName (id) {
        const obj = this.$store.state.licenseRegistration.commonObj.countryList.find(el => el.value === parseInt(id))
        if (typeof obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
    },
    getCompanyName (id) {
        const obj = this.$store.state.licenseRegistration.commonObj.foreignEnlishmentList.find(el => el.value === parseInt(id))
        if (typeof obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
    },
    checkFileFunction (field) {
      return field
    },
    getFile (value) {
      if (value === '') {
         return false
      }
      if (value) {
        const fileData = JSON.parse(value)
        if (fileData[0]) {
          return fileData[0]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    loadData () {
        const search = {
            service_id: this.badcStaticData[0].service_id,
            application_id: this.badcStaticData[0].application_id
        }
        const params = Object.assign({}, search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(licenseRegistrationServiceBaseUrl, '/step/dae-attachment/search', params).then(response => {
            if (response.success) {
                this.datas = response.data
                this.isLoad = true
            } else {
                this.isLoad = true
            }
        })
    },
    tabChanged () {
        this.attachmentShow = false
        this.labelDesignShow = false
        this.generalInfoShow = false
    },
    tabChange (val) {
        if (val === 0) {
            this.attachmentShow = false
            this.labelDesignShow = false
            this.generalInfoShow = true
        }
        if (val === 1) {
            this.labelDesignShow = false
            this.generalInfoShow = false
            this.attachmentShow = true
        }
        if (val === 2) {
            this.generalInfoShow = false
            this.attachmentShow = false
            this.labelDesignShow = true
        }
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    async getreportData () {
        this.generalInfoShow = false
        this.attachmentShow = false
        this.labelDesignShow = false
        this.reportLoading = true
        const applicationID = parseInt(this.badcStaticData[0].application_id)
        await RestApi.getData(licenseRegistrationServiceBaseUrl, `${'/configuration/dae-lab-assign/lab-assign'}/${applicationID}`).then(response => {
            if (response.success) {
                if (response.data.length > 0) {
                    this.reportList = response.data[0].lab_details
                }
            }
            this.reportLoading = false
        })
    },
    getLabName (labId) {
      const lab = this.labList.find(item => item.value === labId)
      if (this.$i18n.locale === 'en') {
        return lab.text_en
      } else {
        return lab.text_bn
      }
    },
    getIngredientName (ingredientId) {
      const ingredient = this.ingridentList.find(item => item.value === ingredientId)
      if (this.$i18n.locale === 'en') {
        return ingredient.text_en
      } else {
        return ingredient.text_bn
      }
    },
    getUserName (userId) {
      const user = this.users.find(user => user.value === userId)
      return user !== undefined ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
    },
    // printLicense () {
    //     const search = {
    //         id: 9,
    //         service_id: parseInt(this.badcStaticData[0].service_id),
    //         step_id: parseInt(this.stepData.step_id),
    //         applicationID: parseInt(this.badcStaticData[0].application_id),
    //         org_id: parseInt(this.badcStaticData[0].org_id),
    //         type: 'pdf',
    //         local: this.$i18n.locale
    //     }
    //     const params = Object.assign({}, search)
    //     this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
    //     RestApi.getData(licenseRegistrationServiceBaseUrl, '/step/step-from-data', params).then(response => {
    //         if (response.success) {
    //             this.datas = response.data
    //             this.isLoad = true
    //         } else {
    //             this.isLoad = true
    //         }
    //     })
    // },
    async printLicense () {
      const params = {
            id: 9,
            service_id: parseInt(this.badcStaticData[0].service_id),
            step_id: parseInt(this.stepData.step_id),
            applicationID: parseInt(this.badcStaticData[0].application_id),
            org_id: parseInt(this.badcStaticData[0].org_id),
            type: 'pdf',
            local: this.$i18n.locale
        }
      this.unitLoad = true

      try {
        const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, '/step/step-from-data', params)
        var blob = new Blob([result], { type: 'application/pdf' })
        var url = window.URL.createObjectURL(blob)

        // Create an anchor element for direct download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'distributor-license.pdf' // Set the desired filename for the download

        // Trigger a click event on the anchor element to initiate the download
        document.body.appendChild(a)
        a.click()

        // Clean up the anchor element and URL object
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)

        this.unitLoad = false
      } catch (error) {
        this.unitLoad = false
      }
    }
  }
}

</script>
