// Trainer Selection api
export const traineeSelectionBaseUrl = '/tim/trainee-selections/'
export const traineeSelectionList = traineeSelectionBaseUrl + 'list'
export const traineeSelectionStore = traineeSelectionBaseUrl + 'store'
export const traineeIdCards = '/tim/trainee-id-cards'
export const idCardPDFList = '/tim/trainee-id-card/' + 'id-card-pdf-list'
export const idCardWordList = '/tim/trainee-id-card/' + 'export-word'

// Attendance api
export const attendanceBaseUrl = '/tim/attendances/'
export const attendanceList = attendanceBaseUrl + 'list'
export const attendanceStore = attendanceBaseUrl + 'store'
export const attendanceUpdate = attendanceBaseUrl + 'update'
export const attendanceShow = attendanceBaseUrl + 'show'
export const attendanceToggleStatus = attendanceBaseUrl + 'toggle-status'
export const attendancePersonalInfoList = attendanceBaseUrl + 'personal-info-list'
export const attendanceAllList = attendanceBaseUrl + 'all-application'
export const circularWiseAttendanceList = attendanceBaseUrl + 'circular-wise_attendance'
export const wordExportAttendence = attendanceBaseUrl + 'word-export-attendance'
export const pdfExportAttendence = attendanceBaseUrl + 'pdf-export-attendance'

// Bill Payment api
export const billPaymentBaseUrl = '/tim/bill-payment/'
export const billPaymentList = billPaymentBaseUrl + 'list'
export const billPaymentHonorarumList = billPaymentBaseUrl + 'honorarum-list'
export const billPaymentHonorarumDetails = billPaymentBaseUrl + 'honorarum-list-details'
export const materialDetails = billPaymentBaseUrl + 'material-details'
export const trainingMaterialMasterRollWord = billPaymentBaseUrl + 'training-material-master-roll-word'
export const billPaymentHonorarumIndividualDetails = billPaymentBaseUrl + 'honorarum-individual-details'
export const incometaxCertificatePdf = billPaymentBaseUrl + 'incomeTax-certificate-pdf'
export const incometaxCertificateWord = billPaymentBaseUrl + 'incomeTax-certificate-word'
export const billPaymentHonorarumDetailsIndividual = billPaymentBaseUrl + 'honorarum-details-individual'
export const billPaymentHonorarumMaterials = '/config/training-title/material-list'
export const billPaymentPaymentSearch = billPaymentBaseUrl + 'payment-search'
export const billPaymentToggleStatus = billPaymentBaseUrl + 'toggle-status'
export const billPaymentStore = billPaymentBaseUrl + 'store'
export const billPaymentShow = billPaymentBaseUrl + 'show'
export const billPaymentFile = billPaymentBaseUrl + 'attachment'
export const billPaymentFinalSave = billPaymentBaseUrl + 'final-save'
export const billGeneratePaymentTranerTrinee = '/training-e-learning-service-payment/payment-tranee-or-tranier'
export const billGeneratePaymentUpdate = '/training-e-learning-service-payment/'
export const circularPublication = '/tpm/training-calendar/circular-publication'
export const honoraruimPdf = billPaymentBaseUrl + 'honorarium-pdf'
export const wordExportHonorarium = billPaymentBaseUrl + 'export-word'
export const participantAllowanceWord = billPaymentBaseUrl + 'participant-allowance-word'

// Evaluation api
export const evaluationBaseUrl = '/tim/evaluations/'
export const evaluationList = evaluationBaseUrl + 'list'
export const evaluationStore = evaluationBaseUrl + 'store'
export const evaluationUpdate = evaluationBaseUrl + 'update'
export const evaluationShow = evaluationBaseUrl + 'show'
export const evaluationToggleStatus = evaluationBaseUrl + 'toggle-status'
export const evaluationPublish = evaluationBaseUrl + 'publish'
export const preEvaluationData = evaluationBaseUrl + 'pre-data'
export const previousPreEvaluationData = evaluationBaseUrl + 'previous-pre-data'
export const evaluationUserList = evaluationBaseUrl + 'user-data'
export const prePostEvaluationDetails = evaluationBaseUrl + 'pre-post-eva-details'

// Evaluation api
export const courseEvaluationBaseUrl = '/tim/course-evaluations/'
export const courseEvaluationList = courseEvaluationBaseUrl + 'list'
export const courseEvaluationStore = courseEvaluationBaseUrl + 'store'
export const courseEvaluationUpdate = courseEvaluationBaseUrl + 'update'
export const courseEvaluationShow = courseEvaluationBaseUrl + 'show'
export const courseEvaluationApprove = courseEvaluationBaseUrl + 'approve'

// Disciplinary Action api
export const disciplinaryBaseUrl = '/tim/disciplinary-action/'
export const disciplinaryActionList = disciplinaryBaseUrl + 'list'
export const disciplinaryActionStore = disciplinaryBaseUrl + 'store'
export const disciplinaryActionUpdate = disciplinaryBaseUrl + 'update'
export const disciplinaryActionShow = disciplinaryBaseUrl + 'show'
export const disciplinaryActionToggleStatus = disciplinaryBaseUrl + 'toggle-status'
export const trainingApplicationBaseUrl = '/iabm/training-applications/'
export const trainingApplicationPersonalInfo = trainingApplicationBaseUrl + 'personal-info'
export const trainingApplicationCircularPublicationInfo = trainingApplicationBaseUrl + 'circular-publication-info'

// Trainee Evaluation api
export const traineeEvaluationBaseUrl = '/tim/trainee-evaluations/'
export const traineeEvaluationList = traineeEvaluationBaseUrl + 'list'
export const traineeEvaluationStore = traineeEvaluationBaseUrl + 'store'
export const traineeEvaluationUpdate = traineeEvaluationBaseUrl + 'update'
export const traineeEvaluationShow = traineeEvaluationBaseUrl + 'show'
export const traineeEvaluationToggleStatus = traineeEvaluationBaseUrl + 'toggle-status'
export const traineeEvaluationMarkInfo = traineeEvaluationBaseUrl + 'evaluation-mark-info'

export const trainingApplicationAllList = trainingApplicationBaseUrl + 'application-list'
export const trainingApplicationListBatchWise = trainingApplicationBaseUrl + 'application-list-batch-wise'
export const approvedTraineeList = trainingApplicationBaseUrl + 'approved-trainee-list'
export const rejectedTraineeList = trainingApplicationBaseUrl + 'rejected-trainee-list'
export const allApprovedTrainee = trainingApplicationBaseUrl + 'all-approved-trainee'
export const allApprovedTraineeWord = trainingApplicationBaseUrl + 'all-approved-trainee-word'
export const allRejectedTrainee = trainingApplicationBaseUrl + 'all-rejected-trainee'
export const trainingApplicationListPaginate = '/iabm/booking/application'
export const personalInfoList = '/iabm/personal-infos/application-list'
export const applicationRecomendList = '/iabm/personal-infos/application-recomend-list'
export const countRegisteredParticipant = '/iabm/personal-infos/count-registered-participant'

// Trainer Sorting api
export const trainerSortingBaseUrl = '/tpm/trainer-sorting/'
export const trainerSortingList = trainerSortingBaseUrl + 'list'
export const trainerSortingStore = trainerSortingBaseUrl + 'store'
export const trainerSortingRejectStore = trainerSortingBaseUrl + 'reject-store'
export const nominatedStore = trainerSortingBaseUrl + 'nominated-store'
export const nominatedRecomendStore = trainerSortingBaseUrl + 'nominated-recomend-store'
export const trainingScheduleBaseUrl = '/tpm/training-schedule/'
export const trainingModuleWiseTrainer = trainingScheduleBaseUrl + 'module-wise-trainer'

export const trainerEvaluationBaseUrl = '/tim/trainer-evaluations/'
export const trainerEvaluationList = trainerEvaluationBaseUrl + 'list'
export const trainerEvaluationDetails = trainerEvaluationBaseUrl + 'details'
export const trainerEvaluationData = trainerEvaluationBaseUrl + 'trainer-evaluation-data'
export const trainerEvaluationDataWord = trainerEvaluationBaseUrl + 'trainer-evaluation-data-word'
export const trainerWiseEvaluationList = '/tim/trainer-wise-evaluations/list'
export const evaluationTraineeData = trainerEvaluationBaseUrl + 'trainee-data'

export const trainingCalendarBaseUrl = '/tpm/training-calendar/'
export const circularPublicationList = trainingCalendarBaseUrl + 'circular-publication-list'

// Other Selection api
export const otherTrainingApplicationsBaseUrl = '/iabm/other-training-applications/'
export const otherTrainingApplicationsList = otherTrainingApplicationsBaseUrl + 'list'
export const otherTrainingApplicationsStore = otherTrainingApplicationsBaseUrl + 'store'

// Other Selection api
export const manualCourseEvaluationBaseUrl = '/tim/manual-course-evaluation/'
export const manualStoreCourseEvaluation = manualCourseEvaluationBaseUrl + 'store'
export const manualStoreCourseEvaluationList = manualCourseEvaluationBaseUrl + 'list'
export const manualStoreCourseEvaluationShow = manualCourseEvaluationBaseUrl + 'show'
export const manualCourseEvaluation = manualCourseEvaluationBaseUrl + 'manual-course-evaluation'

// Attendance api
export const certificateBaseUrl = '/tim/certificate/'
export const certificateList = certificateBaseUrl + 'list'
export const certificateIndividualList = certificateBaseUrl + 'certificate'
export const certificatePDFList = certificateBaseUrl + 'certificate-pdf-list'
export const certificateIndividualShow = certificateBaseUrl + 'certificateShow'
export const certificateDetails = certificateBaseUrl + 'show'
export const certificateApplicant = certificateBaseUrl + 'applicant-list'
export const certificateStore = certificateBaseUrl + 'store'
export const certificateDetailsStore = certificateBaseUrl + 'store-description'
export const certificateSignatureStore = certificateBaseUrl + 'certificate-signature'
export const certificateSignatureView = certificateBaseUrl + 'certificate-signature-view'
export const certificateDetailsUpdate = certificateBaseUrl + 'update-description'
export const certificateDetail = certificateBaseUrl + 'show-details'
export const certificateReleasePublish = certificateBaseUrl + 'publish'
export const wordExport = certificateBaseUrl + 'export-word'
export const releaseOrderDownload = certificateBaseUrl + 'release-order-dowanload'

export const reportHeadingListNew = '/config/report-head/listnew'

// Expertise Trainer Api
export const trainerTraineeInfoBaseUrl = '/tim/excpertise-trainers/'
export const orgTrainerData = trainerTraineeInfoBaseUrl + 'org-trainer-list'
export const expertiseTrainerStore = trainerTraineeInfoBaseUrl + 'store'
export const expertiseTrainerUpdate = trainerTraineeInfoBaseUrl + 'update'
export const expertiseTrainerList = trainerTraineeInfoBaseUrl + 'list'
export const expertiseTrainerShow = trainerTraineeInfoBaseUrl + 'show'

// Filter Circular List
export const circularPublicationBaseUrl = '/iabm/circular-publications/'
export const circularList = circularPublicationBaseUrl + 'circular-publication-list'
export const circularDetails = circularPublicationBaseUrl + 'circular-data'

// All trainer List
export const trainerBaseUrl = '/iabm/personal-infos/'
export const allTrainerData = trainerBaseUrl + 'all-trainer-list'

export const billPaymentBaseUrlOther = '/tim/other-bill-payment/'
export const billPaymentHonorarumListOther = billPaymentBaseUrlOther + 'honorarum-list'
export const billPaymentHonorarumDetailsOther = billPaymentBaseUrlOther + 'honorarum-list-details'
export const billPaymentHonorarumIndividualDetailsOther = billPaymentBaseUrlOther + 'honorarum-individual-details'
export const billPaymentPaymentSearchOther = billPaymentBaseUrlOther + 'payment-search'

// Training Course Evaluation
export const traineeCourseEvaluation = '/tim/trainee-course-evaluation/'
export const traineeCourseEvaluationList = traineeCourseEvaluation + 'list'
export const traineeCourseWiseEvaluationList = traineeCourseEvaluation + 'course-wise-list'
export const circularData = traineeCourseEvaluation + 'circular-data'
export const traineeCourseEvaluationDetails = traineeCourseEvaluation + 'details'

// Other Training Schedule
export const otherTrainingScheduleBaseUrl = '/tpm/other-training-schedule/'
export const scheduleDetails = otherTrainingScheduleBaseUrl + 'schedule-details'
export const scheduleDetailsList = otherTrainingScheduleBaseUrl + 'schedule-details-list'

// Personal Info api
export const personalInfoBaseUrl = '/iabm/personal-infos/'
export const personalDataList = personalInfoBaseUrl + 'personal-info-list'
