// hat Manage
export const hatManageList = '/cotton/ginner-grower/hat-manages/list'
export const hatManageStore = '/cotton/ginner-grower/hat-manages/store'
export const hatManageUpdate = '/cotton/ginner-grower/hat-manages/update'
export const hatManageStatus = '/cotton/ginner-grower/hat-manages/toggle-status'
export const hatManageDestroy = '/cotton/ginner-grower/hat-manages/destroy'
// Manage Schedule
export const ManageScheuleList = '/cotton/ginner-grower/manage-schedule/list'
export const ManageScheuleStore = '/cotton/ginner-grower/manage-schedule/store'
export const ManageScheuleUpdate = '/cotton/ginner-grower/manage-schedule/update'
export const ManageScheuleApprove = '/cotton/ginner-grower/manage-schedule/approve'
export const ManageScheuleReject = '/cotton/ginner-grower/manage-schedule/reject'
export const ManageScheuleStatus = '/cotton/ginner-grower/manage-schedule/toggle-status'
export const ManageScheuleDestroy = '/cotton/ginner-grower/manage-schedule/destroy'
// Approve Schedule
export const ApproveScheuleList = '/cotton/ginner-grower/manage-schedule/approved-list'

// ginner grower profile
const ggProfile = '/cotton/ginner-grower/profile'
export const ggProfileList = ggProfile + '/list'
export const ggProfileListAll = ggProfile + '/list-all'
export const ggProfileStore = ggProfile + '/store'
export const ggProfileUpdate = ggProfile + '/update'
export const ggProfileShow = ggProfile + '/show'
export const ggProfileToggle = ggProfile + '/toggle-status'
export const ggProfileDelete = ggProfile + '/destroy'
export const growerList = ggProfile + '/grower-list'
export const ginnerList = ggProfile + '/ginner-list'

// Production achievement
const productionAchievement = '/cotton/ginner-grower/grower-production-achievements'
export const paList = productionAchievement + '/list'
export const paStore = productionAchievement + '/store'
export const paUpdate = productionAchievement + '/update'
export const paClose = productionAchievement + '/close'
export const paShow = productionAchievement + '/show'

// sell Entry
export const sellEntryList = '/cotton/ginner-grower/grower-sell-entry/list'
export const sellEntryStore = '/cotton/ginner-grower/grower-sell-entry/store'
export const applicationList = '/cotton/ginner-grower/grower-sell-entry/applicantList'
export const approvedScheduleDateList = '/cotton/ginner-grower/grower-sell-entry/approved-schedule-date-list'
export const sellEntryStockQtyApi = '/cotton/ginner-grower/grower-sell-entry/cotton-stock-quantity'
export const seCottonVarietyIdsOfStockQtyApi = '/cotton/ginner-grower/grower-sell-entry/cotton-variety-ids-of-cotton-stock-quantity'
export const seCottonIdsOfStockQtyApi = '/cotton/ginner-grower/grower-sell-entry/cotton-ids-of-cotton-stock-quantity'
