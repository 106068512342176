<template>
    <b-container fluid>
        <b-overlay :show="unitLoad">
            <div>
            <!-- 3rd tab design -->
            <!-- <template v-if="labelDesignShow"> -->
                    <!-- fertilizer information part  -->
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'পরিবেশক সারের নাম' : 'Name of Distributor Fertilizer' }}</h5><!----></div><!----><!---->
                    <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                        <table border="0" style="width: 100%">
                          <tr>
                            <td style="width:50%">{{$t('li_step.brand_name')}} :
                            <slot>
                                {{ badcStaticData[2].brand_name_6692 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{$t('dam-config.chemical_name')}} :
                            <slot>
                                {{ badcStaticData[2].product_na_4729 }}
                            </slot>
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $i18n.locale === 'bn' ? 'পুষ্টি উপাদানের নাম' : 'Nutrient Name' }} :
                            <slot>
                                {{ badcStaticData[2].product_na_9746 }}
                            </slot>
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? 'পুষ্টি উপাদানের শতকরা হার' : 'Percentage of Nutrients' }} : {{ badcStaticData[2].percentage_7457 }}</td>
                          </tr>
                          <tr>
                            <td>{{ $i18n.locale === 'bn' ? 'সারের নিট ওজন' : 'Net Weight of Fertilizer' }} :
                            <slot>
                                {{ badcStaticData[2].net_weight_8399 }}
                            </slot>
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? 'ব্যাচ নং/লট নং' : 'Batch No./Lot No.' }} : {{ badcStaticData[2].batch_no__6438 }}</td>
                          </tr>
                          <tr>
                            <td>{{ $i18n.locale === 'bn' ? 'উৎপাদনের তারিখ' : 'Date of production' }} :
                            <slot>
                                {{ badcStaticData[2].date_of_pr_3646 | dateFormat }}
                            </slot>
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? 'সর্বোচ্চ খুচরা মূল্য' : 'Maximum retail price' }} : {{ badcStaticData[2].maximum_re_2053 }}</td>
                          </tr>
                          <tr>
                            <td>{{ $i18n.locale === 'bn' ? 'প্রস্তুতকারী দেশ' : 'Country of Manufacture' }} :
                                <slot>
                                  {{ getColumnNameCountry($store.state.licenseRegistration.commonObj.countryList, badcStaticData[2].country_of_9847, 'other') }}
                                </slot>
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? 'কোম্পানীর নাম' : 'Company Name' }} : {{ getColumnName($store.state.licenseRegistration.commonObj.foreignEnlishmentList, badcStaticData[2].company_name) }}</td>
                          </tr>
                        </table>
                    </div>
                </div>

                <!-- register fertilizer information part  -->
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'পরিবেশক তথ্য' : 'Distributor Info' }}</h5><!----></div><!----><!---->
                    <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                        <table border="0" style="width: 100%">
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'আমদানীকারকের নাম' : 'Importer Name' }} :
                            <slot>
                                {{ badcStaticData[2].distributo_4802 }}
                            </slot>
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'আমদানীকারকের মোবাইল নম্বর' : 'Importer Mobile No.' }} :
                            <slot>
                                {{ badcStaticData[2].distributo_9965 | mobileNumber }}
                            </slot>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'নিবন্ধন নম্বর' : 'Registration No.' }} :
                            <slot>
                                {{ badcStaticData[2].registrati_9838 }}
                            </slot>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $i18n.locale === 'bn' ? 'বিভাগ' : 'Division' }} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.divisionList, badcStaticData[2].division_7746) }}
                            </slot>
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? 'জেলা' : 'District' }} :  {{ getColumnName($store.state.commonObj.districtList, badcStaticData[2].district_1499) }}</td>
                        </tr>
                        <tr>
                            <td>{{ $i18n.locale === 'bn' ? 'উপজেলা' : 'Upazila' }} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.upazilaList, badcStaticData[2].upazila_5826) }}
                            </slot>
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? 'আমদানীকারকের ঠিকানা' : 'Importer Address' }} : {{  $i18n.locale === 'bn' ? badcStaticData[2].distributo_8774 : badcStaticData[2]['distributor_address_(en)'] }}</td>
                        </tr>
                        </table>
                    </div>
                </div>
            <!-- </template> -->
            </div>
        </b-overlay>
    </b-container>
</template>

<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'

export default {
  props: ['tabData', 'badcStaticData', 'history', 'users', 'stepData', 'pDatas', 'officeData'],

  data () {
    return {
        unitLoad: false,
        isLoad: false,
        datas: {},
        generalInfoShow: true,
        attachmentShow: false,
        labelDesignShow: false,
        reportLoading: false,
        paymentRecipt: false,
        reportList: [],
        import: [
            { id: 1, file: 'image_73', yes_no: 1, remarks: null },
            { id: 2, file: 'rent_agree_5997', yes_no: 1, remarks: null },
            { id: 3, file: 'nid_79', yes_no: 0, remarks: null },
            { id: 4, file: 'updated_tr_5857', yes_no: 0, remarks: null },
            { id: 5, file: 'tin_certif_1997', yes_no: 0, remarks: null },
            { id: 6, file: 'vat_regist_7591', yes_no: 0, remarks: null },
            { id: 7, file: 'updated_in_80', yes_no: 0, remarks: null },
            { id: 8, file: 'updated_ir_8033', yes_no: 0, remarks: null },
            { id: 9, file: 'updated_bf_3787', yes_no: 0, remarks: null },
            { id: 10, file: 'uptodate_b_2747', yes_no: 0, remarks: null },
            { id: 11, file: 'undertakin_8030', yes_no: 0, remarks: null },
            { id: 12, file: 'address_an_7107', yes_no: 0, remarks: null },
            { id: 13, file: 'photocopy__4458', yes_no: 0, remarks: null },
            { id: 14, file: 'authorizat_7806', yes_no: 0, remarks: null },
            { id: 15, file: 'paclobutra_7492', yes_no: 0, remarks: null },
            { id: 16, file: 'approved_s_9860', yes_no: 0, remarks: null },
            { id: 17, file: 'small_and__8570', yes_no: 0, remarks: null },
            { id: 18, file: '_year_perf_2280', yes_no: 0, remarks: null },
            { id: 19, file: 'old_regist_6668', yes_no: 0, remarks: null }
        ],
        daeList: []
    }
  },
  created () {
    this.daeList = this.import
    this.loadData()
  },
  computed: {
    local: function () {
        return this.$i18n.locale
    },
    ingridentList: function () {
      return this.$store.state.licenseRegistration.commonObj.activeIngredientList.filter(item => item.status === 1)
    },
    labList: function () {
       return this.$store.state.licenseRegistration.commonObj.labList.filter(item => item.status === 1)
    }
  },
  methods: {
    getCountryManufactureName (id) {
        const obj = this.$store.state.licenseRegistration.commonObj.countryList.find(el => el.value === parseInt(id))
        if (typeof obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
    },
    getCompanyName (id) {
        const obj = this.$store.state.licenseRegistration.commonObj.foreignEnlishmentList.find(el => el.value === parseInt(id))
        if (typeof obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
    },
    checkFileFunction (field) {
      return field
    },
    getFile (value) {
      if (value === '') {
         return false
      }
      if (value) {
        const fileData = JSON.parse(value)
        if (fileData[0]) {
          return fileData[0]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    loadData () {
        const search = {
            service_id: this.badcStaticData[0].service_id,
            application_id: this.badcStaticData[0].application_id
        }
        const params = Object.assign({}, search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(licenseRegistrationServiceBaseUrl, '/step/dae-attachment/search', params).then(response => {
            if (response.success) {
                this.datas = response.data
                this.isLoad = true
            } else {
                this.isLoad = true
            }
        })
    },
    tabChanged () {
        this.attachmentShow = false
        this.labelDesignShow = false
        this.generalInfoShow = false
    },
    tabChange (val) {
        if (val === 0) {
            this.attachmentShow = false
            this.labelDesignShow = false
            this.generalInfoShow = true
        }
        if (val === 1) {
            this.labelDesignShow = false
            this.generalInfoShow = false
            this.attachmentShow = true
        }
        if (val === 2) {
            this.generalInfoShow = false
            this.attachmentShow = false
            this.labelDesignShow = true
        }
    },
    getColumnName (list, groupId, other = null) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (other) {
          return obj.text
        }
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getColumnNameCountry (list, groupId, other = null) {
      const obj = list.find(item => item.id === groupId)
      if (typeof obj !== 'undefined') {
        if (other) {
          return obj.text
        }
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    async getreportData () {
        this.generalInfoShow = false
        this.attachmentShow = false
        this.labelDesignShow = false
        this.reportLoading = true
        const applicationID = parseInt(this.badcStaticData[0].application_id)
        await RestApi.getData(licenseRegistrationServiceBaseUrl, `${'/configuration/dae-lab-assign/lab-assign'}/${applicationID}`).then(response => {
            if (response.success) {
                if (response.data.length > 0) {
                    this.reportList = response.data[0].lab_details
                }
            }
            this.reportLoading = false
        })
    },
    getLabName (labId) {
      const lab = this.labList.find(item => item.value === labId)
      if (this.$i18n.locale === 'en') {
        return lab.text_en
      } else {
        return lab.text_bn
      }
    },
    getIngredientName (ingredientId) {
      const ingredient = this.ingridentList.find(item => item.value === ingredientId)
      if (this.$i18n.locale === 'en') {
        return ingredient.text_en
      } else {
        return ingredient.text_bn
      }
    },
    getUserName (userId) {
      const user = this.users.find(user => user.value === userId)
      return user !== undefined ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
    },
    // printLicense () {
    //     const search = {
    //         id: 9,
    //         service_id: parseInt(this.badcStaticData[0].service_id),
    //         step_id: parseInt(this.stepData.step_id),
    //         applicationID: parseInt(this.badcStaticData[0].application_id),
    //         org_id: parseInt(this.badcStaticData[0].org_id),
    //         type: 'pdf',
    //         local: this.$i18n.locale
    //     }
    //     const params = Object.assign({}, search)
    //     this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
    //     RestApi.getData(licenseRegistrationServiceBaseUrl, '/step/step-from-data', params).then(response => {
    //         if (response.success) {
    //             this.datas = response.data
    //             this.isLoad = true
    //         } else {
    //             this.isLoad = true
    //         }
    //     })
    // },
    async printLicense () {
      const params = {
            id: 9,
            service_id: parseInt(this.badcStaticData[0].service_id),
            step_id: parseInt(this.stepData.step_id),
            applicationID: parseInt(this.badcStaticData[0].application_id),
            org_id: parseInt(this.badcStaticData[0].org_id),
            type: 'pdf',
            local: this.$i18n.locale
        }
      this.unitLoad = true

      try {
        const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, '/step/step-from-data', params)
        var blob = new Blob([result], { type: 'application/pdf' })
        var url = window.URL.createObjectURL(blob)

        // Create an anchor element for direct download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'distributor-license.pdf' // Set the desired filename for the download

        // Trigger a click event on the anchor element to initiate the download
        document.body.appendChild(a)
        a.click()

        // Clean up the anchor element and URL object
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)

        this.unitLoad = false
      } catch (error) {
        this.unitLoad = false
      }
    }
  }
}

</script>
